import React, { useEffect, useRef, useState } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DECIMAL,
  PER_PAGE_RECORD_LIMIT,
} from "../../../../Constant/userConstant";
import { getArts } from "../../../../Features/drop/dropSlice";
import { checkFileType, getNumberWithComma } from "../../../../Helper/common";
import Notfound from "../../../Common/NotFound/Notfound";
import CommonCard from "../../../Common/Ui/CommonCard/CommonCard";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import styles from "../../../Private/CuratedDropDigitalArt/CuratedDropDigitalArt.module.scss";
import LoadingOverlay from "../../../VideoGif/LoadingOverlay";
import style from "./Activedigitalart.module.scss";
import moment from "moment";

const Activedigitalart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [art, setArt] = useState([]);
  const elementToScroll = useRef(null);

  useEffect(() => {
    getUserCreatedArts();
  }, [ArtistId, page]);

  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(Number(searchParams.get("page")));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page")]);

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const getUserCreatedArts = async () => {
    try {
      let params = {
        id: ArtistId,
        size: PER_PAGE_RECORD_LIMIT.ART_CREATED_LIMIT,
        page: searchParams.get("page") > 1 ? searchParams.get("page") : page,
      };
      const response = await dispatch(getArts(params));
      setArt(response.data.data.arts);
      setTotalPages(response.data.data.totalPages);
      scrollToElement();
    } catch (err) {}
  };

  const naviagteToArtDetail = async (e, item) => {
    navigate(`/digital-fine-arts/${item.id}`);
  };

  const getArtTypeHandler = (item) => {
    let artIdn = item?.art_type_id ?? item?.art_type;

    // let artId =
    //   marketplaceFilter?.status?.[0] === 1 || marketplaceFilter?.status?.[0] === 4 ? artIdn : item?.art_type;
    switch (artIdn) {
      case 1:
        return "DYNAMIC";
      // case 2:
      //   return "GENERATIVE";
      // case 3:
      //   return "";
      // case 4:
      //   return "RESPONSIVE";
      default:
        return "";
    }
  };

  const getArtTypeName = (item) => {
    switch (item.drop_type) {
      case 1: {
        let priceLabel =
          item.market == "PRIMARY" ? "Min. Eligible Bid" : "Floor Price";
        let editionLabel = item.market == "PRIMARY" ? "" : "Edition of";
        return {
          dropType: "Auction",
          label: priceLabel,
          editionLabel,
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
      }
      case 2: {
        let priceLabel =
          item.market == "PRIMARY" ? "Min. Eligible Bid" : "Floor Price";
        return {
          dropType: "Ranked Auction",
          label: priceLabel,
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
      }
      case 3: {
        return {
          dropType: "First Come First Buy",
          label: "Floor Price",
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
      }
      case 4: {
        let editionLabel =
          item.market == "PRIMARY" ? "Editions Released:" : "Edition of";
        return {
          dropType: "Open Edition",
          label: "Floor Price",
          editionLabel,
          editionNo:
            item?.sold_no_of_editions > 0 ? item.sold_no_of_editions : "--",
        };
      }
      case 5: {
        let priceLabel =
          item.market == "PRIMARY" ? "Entry Price" : "Floor Price";
        return {
          dropType: "Raffle",
          label: priceLabel,
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
      }
      default:
        return {
          dropType: "",
          label: "Floor Price",
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
    }
  };

  const getRemainingTime = (endTime) => {
    const currentTime = Date.now();
    const remainingTime = endTime - currentTime;

    if (remainingTime <= 0) {
      return "";
    }

    const duration = moment.duration(remainingTime, "milliseconds");

    if (duration.asDays() >= 1) {
      return `${Math.floor(duration.asDays())} Days`;
    } else if (duration.asHours() >= 1) {
      return `${Math.floor(duration.asHours())} Hrs`;
    } else if (duration.asMinutes() >= 1) {
      return `${Math.floor(duration.asMinutes())} Min`;
    } else {
      return `${Math.floor(duration.asSeconds())} Sec`;
    }
  };

  const getImageSectionGif = (item) => {
    let type = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );

    let artMetaData = getArtTypeName(item);

    if (type == "MP4" || type == "OGG") {
      return (
        <HoverVideoPlayer
          loop={true}
          restartOnPaused
          unloadVideoOnPaused={true}
          disableRemotePlayback={false}
          paused={true}
          // muted={false}
          muted={true}
          // onClick={() => onclickHandler(item)}
          onClick={(e) => naviagteToArtDetail(e, item)}
          className={`video_hover_sec ${styles.image_sec}`}
          videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          pausedOverlay={
            <CommonCard
              // key={index}
              imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
              title={item.art_name}
              subtitle={`@${item?.art_user?.username}`}
              // bottomTitle={`Price: ₹ ${getNumberWithComma(
              //   Number(item.price) / DECIMAL
              // )}`}
              // bottomSubTitle={
              //   item.drop_type != 4
              //     ? `${item.no_of_editions - item.sold_no_of_editions} / ${
              //         item.no_of_editions
              //       }`
              //     : ""
              // }
              isMarketPlace={true}
              item={item}
              onClick={(e) => naviagteToArtDetail(e, item)}
              tagTitile={getArtTypeHandler(item)}
              livedata={item.market}
              endData={
                item.drop_type != 3 ? getRemainingTime(item.end_time) : ""
              }
              artTitle={getArtTypeName(item)}
              // getArtTypeName={getArtTypeName(item?.drop_type, item?.market)}
              label={artMetaData.label}
              price={item?.floor_price}
              editionLabel={artMetaData.editionLabel}
              editionNo={artMetaData.editionNo}
            />
          }
          loadingOverlay={<LoadingOverlay />}
        />
      );
    } else {
      return (
        <CommonCard
          imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
          featureUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          title={item.art_name}
          subtitle={`@${item?.art_user?.username}`}
          // bottomTitle={`Price: ₹ ${getNumberWithComma(
          //   Number(item.price) / DECIMAL
          // )}`}
          // bottomSubTitle={
          //   item.drop_type != 4
          //     ? `${item.no_of_editions - item.sold_no_of_editions} / ${
          //         item.no_of_editions
          //       }`
          //     : ""
          // }
          item={item}
          isMarketPlace={true}
          onClick={(e) => naviagteToArtDetail(e, item)}
          tagTitile={getArtTypeHandler(item)}
          livedata={item.market}
          endData={item.drop_type != 3 ? getRemainingTime(item.end_time) : ""}
          artTitle={getArtTypeName(item)}
          // getArtTypeName={getArtTypeName(item?.drop_type, item?.market)}
          label={artMetaData.label}
          price={item?.floor_price}
          editionLabel={artMetaData.editionLabel}
          editionNo={artMetaData.editionNo}
        />
      );
    }
  };

  return (
    <>
      <section className={style.active_digitalart} ref={elementToScroll}>
        <div className={style.card_colloection}>
          <div className={style.cardRow}>
            {art.map((item, index) => (
              <div key={index} className={`cursor-pointer ${style.cardBox}`}>
                {getImageSectionGif(item)}
              </div>
            ))}
            {art.length === 0 && <Notfound text={"No digital art found."} />}
          </div>
        </div>

        {totalPages > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPages}
            setActivePage={setPage}
            maxVisiblePages={5}
            setSearchParams={setSearchParams}
          />
        )}
      </section>
    </>
  );
};

export default Activedigitalart;
