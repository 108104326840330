import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import style from "./CashoutHistory.module.scss";
import CancelledReject from "./Component/CancelledReject";
import Completed from "./Component/Completed";
import Inprocess from "./Component/Inprocess";
import { useDispatch, useSelector } from "react-redux";
import {
  getCashoutHistory,
  setWalletSubActiveTab,
} from "../../../../../Features/user/userSlice";
import ViewCashOutModal from "../CashOutModal/ViewCashOutModal";
import { WALLET_TAB } from "../../../../../Constant/userConstant";
const limitOption = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 },
];

const CashoutHistory = () => {
  const dispatch = useDispatch();
  const cashoutHistoryActiveTab = useSelector(
    (state) => state?.users.walletActiveSubTab
  );
  const [cashoutHistory, setCashoutHistory] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(limitOption[0]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewDetail, setViewDetail] = useState({});

  useEffect(() => {
    getCashOutHistoryHandler();
  }, [page, cashoutHistoryActiveTab, limit]);

  let cashoutFilter = {
    inProcess: "PENDING",
    completed: "APPROVED",
    cancelledReject: "REJECTED",
  };

  let cashoutStatus = {
    PENDING: "PENDING",
    REJECTED: "REJECTED",
    APPROVED: "APPROVED",
    CANCELLATION_REQUESTED: "CANCELLATION REQUESTED",
    CANCELLED: "CANCELLED",
  };

  const getCashOutHistoryHandler = async () => {
    let params = {
      size: limit.value,
      page: page,
      filter: cashoutFilter[cashoutHistoryActiveTab],
    };
    try {
      const response = await dispatch(getCashoutHistory(params));
      setCashoutHistory(response?.data?.data);
    } catch (error) {}
  };

  return (
    <>
      <section className={style.cashoutHistory}>
        <Tabs
          defaultActiveKey="inProcess"
          id="uncontrolled-tab-example"
          className="tablists"
          activeKey={cashoutHistoryActiveTab}
          onSelect={(tab) => {
            dispatch(setWalletSubActiveTab(tab));
            setPage(1);
            setLimit(limitOption[0]);
          }}
        >
          <Tab eventKey={WALLET_TAB?.SUBTAB?.INPROCRESS} title="In Process">
            {cashoutHistoryActiveTab === WALLET_TAB?.SUBTAB?.INPROCRESS && (
              <Inprocess
                cashoutHistory={cashoutHistory}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                limitOption={limitOption}
                setViewDetail={setViewDetail}
                viewDetail={viewDetail}
                getCashOutHistoryHandler={getCashOutHistoryHandler}
                setShowViewModal={setShowViewModal}
                cashoutStatus={cashoutStatus}
              />
            )}
          </Tab>
          <Tab eventKey={WALLET_TAB?.SUBTAB?.COMPLETED} title="Completed">
            {cashoutHistoryActiveTab === WALLET_TAB?.SUBTAB?.COMPLETED && (
              <Completed
                cashoutHistory={cashoutHistory}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                limitOption={limitOption}
                setViewDetail={setViewDetail}
                viewDetail={viewDetail}
                setShowViewModal={setShowViewModal}
                cashoutStatus={cashoutStatus}
              />
            )}
          </Tab>
          <Tab
            eventKey={WALLET_TAB?.SUBTAB?.CANCELLEDREJECT}
            title="Cancelled / Rejected"
          >
            {cashoutHistoryActiveTab ===
              WALLET_TAB?.SUBTAB?.CANCELLEDREJECT && (
              <CancelledReject
                cashoutHistory={cashoutHistory}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                limitOption={limitOption}
                setViewDetail={setViewDetail}
                viewDetail={viewDetail}
                setShowViewModal={setShowViewModal}
              />
            )}
          </Tab>
        </Tabs>
        {showViewModal && (
          <ViewCashOutModal
            show={showViewModal}
            handleClose={() => setShowViewModal(false)}
            viewDetail={viewDetail}
          />
        )}
      </section>
    </>
  );
};

export default CashoutHistory;
