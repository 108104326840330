import React, { useEffect, useRef, useState } from "react";
import { Nav, Tab, Tabs } from "react-bootstrap";
import { useDebounce } from "../../../../../customHooks/useDebounce";
import Purchased from "./Purchased";
import Sold from "./Sold";
import { useDispatch, useSelector } from "react-redux";
import { getAllPurchasedSoldList } from "../../../../../Features/drop/dropSlice";
import {
  COMPARISION_CONSTANT_NAMES,
  PER_PAGE_RECORD_LIMIT,
} from "../../../../../Constant/userConstant";
import {
  setPagePurchase,
  setTabTypeTransaction,
} from "../../../../../Features/user/userSlice";
import style from "../index_dashboard.module.scss";
import { SearchIcon } from "../../../../../Assets/svgIcons/SVGicon";

const Transactions = ({ ArtistId }) => {
  const limitOption = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "30", value: 30 },
    { label: "40", value: 40 },
  ];
  const dispatch = useDispatch();
  const elementToScroll = useRef(null);
  const [activeTab, setActiveTab] = useState("buy");
  const [purchasedSoldList, setPurchasedSoldList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItem, setTotalItem] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(limitOption[0]);
  const debouncedSearchTerm = useDebounce(search, 500);
  const pagePurchase = useSelector((state) => state.users.pagePurchase);
  const tabTypeTransaction = useSelector(
    (state) => state.users.tabTypeTransaction
  );

  const onTabChange = (e) => {
    // setActiveTab(e);
    dispatch(setTabTypeTransaction(e));
    setSearch("");
    // setPage(1);
    dispatch(setPagePurchase(1));
  };

  useEffect(() => {
    if (
      debouncedSearchTerm.length == COMPARISION_CONSTANT_NAMES.ZERO ||
      debouncedSearchTerm.length >= COMPARISION_CONSTANT_NAMES.THREE
    ) {
      getPuchaseSoldHisotry();
    }
  }, [
    tabTypeTransaction,
    debouncedSearchTerm,
    pagePurchase,
    ArtistId,
    limit?.value,
  ]);

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const getPuchaseSoldHisotry = async () => {
    let data = {
      type: tabTypeTransaction,
      page: search !== "" ? 1 : pagePurchase,
      size: limit?.value,
      // size: 1,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getAllPurchasedSoldList(data));
      let record = response.data.data;
      if (response) {
        setTotalPage(record.totalPages);
        setTotalItem(record?.totalItems);
        setPurchasedSoldList(record.nft_order_list);
      }
      scrollToElement();
    } catch (err) {}
  };

  return (
    <>
      <section className={style.trasnsaction_tab} ref={elementToScroll}>
        <Tab.Container
          defaultActiveKey="purchased"
          id="uncontrolled-tab-example"
          className="tablists"
          activeKey={tabTypeTransaction}
          onSelect={onTabChange}
        >
          <div className="tabTop">
            <Nav variant="pills" className="tablists">
              <Nav.Item>
                <Nav.Link eventKey="buy">Purchased</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="sell">Sold</Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="search_input">
              <div className="search_input_inner">
                <button type="submit" className="search_input_icon">
                  <SearchIcon />
                </button>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Art"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="buy">
              {tabTypeTransaction == "buy" && (
                <Purchased
                  data={purchasedSoldList}
                  totalPage={totalPage}
                  page={pagePurchase}
                  // setPage={setPage}
                  setSearch={setSearch}
                  limit={limit}
                  setLimit={setLimit}
                  limitOption={limitOption}
                  totalItems={totalItem}
                />
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="sell">
              {tabTypeTransaction == "sell" && (
                <Sold
                  data={purchasedSoldList}
                  totalPage={totalPage}
                  page={pagePurchase}
                  setSearch={setSearch}
                  limit={limit}
                  setLimit={setLimit}
                  limitOption={limitOption}
                  totalItems={totalItem}
                />
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </section>
    </>
  );
};

export default Transactions;
