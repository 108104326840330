import React, { useMemo, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import style from "./Kyc.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import countryList from "react-select-country-list";
import pdfImage from "../../../../Assets/images/pdf.png";
import { inputFieldValidation } from "../../../../Constant/formikConstant";
import { useDispatch } from "react-redux";
import { uploadDoc, uploadKyc } from "../../../../Features/user/userSlice";
import { useDropzone } from "react-dropzone";

const Kyc = ({ docOption, kycDetail, getKycDetailHandler }) => {
  //redux
  const dispatch = useDispatch();
  //   constant
  const countryOptions = useMemo(() => countryList().getData(), []);
  const otherCountryDocOptions = [
    { value: 1, label: "Local Passport" },
    { value: 2, label: "Driving License" },
    { value: 5, label: "Others" },
  ];

  //state
  const [docTypeValue, setDocTypeValue] = useState(null);
  const [frontPreview, setFrontPreview] = useState("");
  const [backpreview, setBackPreview] = useState("");
  const [isFormShow, setIsFormShow] = useState(false);
  const elementToScroll = useRef(null);

  //formik
  const initialValues = {
    document: "",
    country: "",
    frontImage: "",
    backImage: "",
    docName: "",
    addressLine1: "",
    city: "",
    pinCode: "",
    state: "",
  };

  const validationSchemaDoc1 = Yup.object().shape({
    document: Yup.object().required(
      inputFieldValidation.commonMessage.required("Document")
    ),
    country: Yup.object().required(
      inputFieldValidation.commonMessage.required("Country")
    ),
    state: Yup.string()
      .required(inputFieldValidation.commonMessage.required("State"))
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      ),
    addressLine1: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      )
      .min(
        inputFieldValidation.address.minLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .max(
        inputFieldValidation.address.maxLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .required(inputFieldValidation.commonMessage.required("Address")),
    city: Yup.string()
      .required(inputFieldValidation.commonMessage.required("City"))
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      ),
    pinCode: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Pin code"))
      .min(5, `*Must be between 5-10 characters`)
      .max(10, `*Must be between 5-10 characters`)
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "*Only alphanumeric characters and spaces are allowed"
      ),
    frontImage: Yup.mixed().required(
      inputFieldValidation.commonMessage.required("Front image")
    ),
  });

  const validationSchemaDoc2 = Yup.object().shape({
    backImage: Yup.mixed().required(
      inputFieldValidation.commonMessage.required("Back image")
    ),
  });

  const validationSchemaDoc5 = Yup.object().shape({
    docName: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Document name"))
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      ),
    backImage: Yup.mixed(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: (values) => {
      let schema = validationSchemaDoc1;
      //if doctypeValue is 1,2,4 which is "Passport", "DL", "Aadhar Card" validationSchemaDoc2 is also applied with validationSchemaDoc1
      if (docTypeValue == 1 || docTypeValue == 2 || docTypeValue == 4) {
        schema = schema.concat(validationSchemaDoc2);
      }
      //if doctypeValue is 5 which is "others" validationSchemaDoc5 is also applied validationSchemaDoc1
      if (docTypeValue == 5) {
        schema = schema.concat(validationSchemaDoc5);
      }
      return schema;
    },
    onSubmit: (values) => {
      kycHandler(values);
    },
  });

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleFrontDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    let fileSize = file.size / 1024 / 1024;

    const isvalidExt = imageExtensionValidatorWithPdf(file.name);
    if (isvalidExt) {
      if (fileSize <= 5) {
        let response = fileUploadHandler(file, "FRONT");
        if (response && isvalidExt) {
          if (acceptedFiles && acceptedFiles.length > 0) {
            formik.setFieldValue("frontImage", file);
            setFrontPreview(URL.createObjectURL(file));
          }
        }
      } else if (fileSize > 5) {
        await formik.setFieldTouched("frontImage", true);
        await formik.setFieldError(
          "frontImage",
          "*File size should be less than or equal to 5MB"
        );
        return null;
      }
    } else {
      await formik.setFieldTouched("frontImage", true);
      await formik.setFieldError(
        "frontImage",
        "*File must be of type jpeg, jpg ,png and pdf"
      );
    }
  };

  const imageExtensionValidatorWithPdf = (file) => {
    const ext = [".jpg", ".jpeg", ".png", ".JPG", ".pdf"];
    return ext.some((el) => file.endsWith(el));
  };

  const handleBackDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    let fileSize = file.size / 1024 / 1024;
    const isvalidExt = imageExtensionValidatorWithPdf(file.name);
    if (isvalidExt) {
      if (fileSize <= 5) {
        let response = fileUploadHandler(file, "BACK");
        if (response) {
          if (acceptedFiles && acceptedFiles.length > 0) {
            formik.setFieldValue("backImage", file);
            setBackPreview(URL.createObjectURL(file));
          }
        }
      } else if (fileSize > 5) {
        await formik.setFieldTouched("backImage", true);
        await formik.setFieldError(
          "backImage",
          "*File size Should be less than or equal to 5MB"
        );
        return null;
      }
    } else {
      await formik.setFieldTouched("backImage", true);
      await formik.setFieldError(
        "backImage",
        "*File must be of type jpeg, jpg ,png and pdf"
      );
    }
  };

  const {
    getRootProps: getFrontRootProps,
    getInputProps: getFrontInputProps,
    isDragActive,
  } = useDropzone({
    onDrop: handleFrontDrop,
    accept: ["image/*", "application/pdf"],
    multiple: false,
  });

  const {
    getRootProps: getBackRootProps,
    getInputProps: getBackInputProps,
    isDragActive: isDragBackActive,
  } = useDropzone({
    onDrop: handleBackDrop,
    accept: ["image/*", "application/pdf"],
    multiple: false,
  });

  const kycHandler = async (values) => {
    let data = {
      country: values.country.label,
      address: values.addressLine1,
      state: values.state,
      city: values.city,
      postal_code: values.pinCode,
    };
    if (values.doc_name) {
      data.doc_name = values.docName;
    }
    try {
      let response = await dispatch(uploadKyc(data));
      if (response) {
        setIsFormShow(false);
        getKycDetailHandler();
      }

      scrollToElement();
    } catch (err) { }
  };

  const docTypeOnChange = (value) => {
    setDocTypeValue(value);
  };

  const filePreview = (file) => {
    if (!file) {
      return null;
    }
    if (file.type.startsWith("image/")) {
      return <img src={URL.createObjectURL(file)} alt="Preview" />;
    }
    if (file.type === "application/pdf") {
      return <img src={pdfImage} className="pdf_fileup" alt="Preview" />;
    }
    return null;
  };

  const fileUploadHandler = async (file, type) => {
    let formData = new FormData();
    formData.append("kyc_doc", file);
    formData.append("side", type);
    formData.append("kyc_id", kycDetail?.kyc_id);
    formData.append("doc_type", formik?.values?.document.value);
    if (docTypeValue === 5) {
      formData.append("doc_name", formik?.values?.docName);
    }
    try {
      let response = await dispatch(uploadDoc(formData));
      if (response) {
        return true;
      }
    } catch (err) {
      return false;
    }
  };

  const handleDownload = (file) => {
    // URL of the PDF file
    const fileUrl = file;

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.download = "decline_kyc.pdf"; // Set the desired file name for download
    link.click();
  };

  const getReasonAttachmentPreview = (file) => {
    if (!file || !file?.content_type) {
      return "No attachment found";
    }
    if (file.content_type.startsWith("image/")) {
      return <img src={file?.reason_attachment} alt="Preview" />;
    }
    if (file.content_type === "application/pdf") {
      return (
        <img
          src={pdfImage}
          alt="Preview"
          style={{ cursor: "pointer" }}
          onClick={() => handleDownload(file?.reason_attachment)}
        />
      );

      // return <iframe src={file?.reason_attachment} title="Preview" />;
    }
    return null;
  };

  // resumbitting kyc function
  const resubmitKycHandler = () => {
    setIsFormShow(true);
  };
  return (
    <>
      <section className={style.kyc_section} ref={elementToScroll}>
        <Tabs
          defaultActiveKey="validateprofile"
          id="uncontrolled-tab-example"
          className="tablists"
        >
          <Tab eventKey="validateprofile" title="Verify Your Identity">
            <div className={style.kyc_section_tabvalid}>
              {(kycDetail?.status == "NOT SUBMITTED" || isFormShow) &&
                kycDetail.count_left > 0 && (
                  <div className={style.form_kyc}>
                    <form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={6} md={6} sm={6}>
                          <FormikControls
                            className="SelectKyc"
                            control="select"
                            label="Government ID Country"
                            name="country"
                            formik={formik}
                            placeholder="Select country"
                            options={countryOptions}
                            // onBlur={alert(1)}
                            // defaultValue={selectedCountryOption}
                            value={formik.values.country}
                            onChange={(value) => {
                              formik.setFieldValue("frontImage", "");
                              formik.setFieldValue("backImage", "");
                              setFrontPreview("");
                              setBackPreview("");
                              formik.setFieldValue("document", "");
                              formik.setFieldValue("country", value);
                              setDocTypeValue(null);
                              formik.setFieldValue("docName", "");
                            }}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <FormikControls
                            variant='border_input'
                            type="input"
                            className="input_border"
                            name="state"
                            formik={formik}
                            control="input"
                            placeholder="Enter State"
                            label="Enter State"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            defaultValue={formik.values.state}
                            value={formik.values.state}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <FormikControls
                            variant="input_spaces border_input"
                            className="input_border"
                            control="input"
                            type="input"
                            name="addressLine1"
                            placeholder="Address"
                            label="Address"
                            formik={formik}
                            onBlur={formik.handleBlur}
                            value={formik.values.addressLine1}
                            onChange={formik.handleChange}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <FormikControls
                            variant="input_spaces border_input"
                            className="input_border"
                            control="input"
                            type="input"
                            name="city"
                            placeholder="City"
                            label="Enter City"
                            formik={formik}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                            onChange={formik.handleChange}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <FormikControls
                            variant="input_spaces border_input"
                            className="input_border"
                            control="input"
                            type="input"
                            name="pinCode"
                            placeholder="Pincode"
                            label="Pincode"
                            formik={formik}
                            maxLength={10}
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              //regex for only digit
                              // if (/^\d*$/.test(e.target.value)) {
                              formik.setFieldValue("pinCode", e.target.value);
                              // }
                            }}
                            value={formik.values.pinCode}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <FormikControls
                            className="SelectKyc"
                            control="select"
                            label="Select document type"
                            name="document"
                            formik={formik}
                            onBlur={formik.handleBlur}
                            placeholder="Select doc type"
                            options={
                              formik.values.country === "" ||
                                formik.values.country.label === "India"
                                ? docOption
                                : otherCountryDocOptions
                            }
                            // defaultValue={formik.values.document}
                            value={formik.values.document}
                            onChange={(value) => {
                              // setSelectedDocOption(value);
                              if (value) {
                                docTypeOnChange(value.value);
                                formik.setFieldValue("frontImage", "");
                                formik.setFieldValue("backImage", "");
                                setFrontPreview("");
                                setBackPreview("");
                                formik.setFieldValue("document", value);
                              }
                            }}
                            isDisabled={formik.values.country ? false : true}
                          />
                        </Col>
                        <Col lg={12}>
                          {docTypeValue === 5 && (
                            <div className="">
                              <FormikControls
                                variant="input_spaces border_input mb-2"
                                className="input_border"
                                control="input"
                                label="Name of the document"
                                type="text"
                                name="docName"
                                formik={formik}
                                placeholder="Enter the document name"
                                onBlur={formik.handleBlur}
                                value={formik.values.docName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "docName",
                                    e.target.value
                                  );
                                  if (e.target.value == "") {
                                    formik.setFieldValue("frontImage", "");
                                    formik.setFieldValue("backImage", "");
                                    setFrontPreview("");
                                    setBackPreview("");
                                  }
                                }}
                              />
                            </div>
                          )}
                          {formik.values.document ? (
                            <>
                              {(docTypeValue === 2 ||
                                docTypeValue == 3 ||
                                (docTypeValue == 5 && formik.values.docName) ||
                                docTypeValue == 1 ||
                                docTypeValue == 4) && (
                                  <div className={style.upload_image_sec}>
                                    <label className="labelUp">Front Side</label>
                                    <div className={style.file_up}>
                                      <div
                                        {...getFrontRootProps()}
                                        className={`dropzone ${isDragActive ? "active" : ""
                                          }`}
                                      >
                                        <input
                                          {...getFrontInputProps()}
                                          name="frontImage"
                                          isDisabled={true}
                                        />
                                        {frontPreview ? (
                                          <>
                                            {(kycDetail.status ==
                                              "NOT SUBMITTED" ||
                                              kycDetail.status == "REJECTED") &&
                                              filePreview(
                                                formik.values.frontImage,
                                                "frontImage"
                                              )}
                                          </>
                                        ) : (
                                          <p>
                                            {/* Drag and drop an image, PDF file here,
                                            or click to select a file */}

                                            Drag file here to uplaod or <span>choose file</span>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    {formik?.touched?.frontImage && (
                                      <p className="error_message">
                                        {formik?.errors?.frontImage}
                                      </p>
                                    )}
                                  </div>
                                )}

                              {(docTypeValue === 2 ||
                                (docTypeValue == 5 && formik.values.docName) ||
                                docTypeValue == 1 ||
                                docTypeValue == 4) && (
                                  <>
                                    <div className={style.upload_image_sec}>
                                      <label className="labelUp">Back Side</label>
                                      <div className={style.file_up}>
                                        <div
                                          {...getBackRootProps()}
                                          className={`dropzone ${isDragBackActive ? "active" : ""
                                            }`}
                                        >
                                          <input
                                            {...getBackInputProps()}
                                            name="backImage"
                                          />
                                          {backpreview ? (
                                            <>
                                              {(kycDetail.status ==
                                                "NOT SUBMITTED" ||
                                                kycDetail.status == "REJECTED") &&
                                                filePreview(
                                                  formik.values.backImage,
                                                  "backImage"
                                                )}
                                            </>
                                          ) : (
                                            <p>
                                              {/* Drag and drop an image, PDF file here,
                                              or click to select a file */}

                                              Drag file here to uplaod or <span>choose file</span>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      {formik?.touched?.backImage && (
                                        <p className="error_message">
                                          {formik?.errors?.backImage}
                                        </p>
                                      )}
                                    </div>
                                  </>
                                )}
                            </>
                          ) : (
                            <div className={`my-3 ${style.fileUpload}`}>
                              <label className="labelUp">Front Side</label>
                              <div className={style.file_up}>
                                <input
                                  type="file"
                                  name="frontImage"
                                  disabled={true}
                                />
                                <p>
                                  {/* Drag and drop an image, PDF file here,
                                  or click to select a file */}

                                  Drag file here to uplaod or <span>choose file</span>
                                </p>
                              </div>
                              {formik?.touched?.frontImage && (
                                <p className="error_message">
                                  {formik?.errors?.frontImage}
                                </p>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <CommonBtn
                        role="btn"
                        className={`black-btn mt-4 ${style.btnspace}`}
                        title={"Submit"}
                        type="submit"
                      />
                    </form>
                  </div>
                )}
              {kycDetail.status === "REJECTED" && !isFormShow && (
                <div className={style.decline_msg}>
                  <Row>
                    <Col lg={6}>
                      <h6>Your KYC is Rejected</h6>
                      <p>
                        <strong>Reason: </strong>
                        {kycDetail?.reason_for_decline
                          ? kycDetail?.reason_for_decline
                          : ""}
                      </p>
                    </Col>
                    <Col lg={6}>
                      <p>
                        <strong>Attachment: </strong>

                        <div className={style.image_attach}>
                          {getReasonAttachmentPreview(kycDetail)}
                        </div>
                      </p>
                    </Col>
                    {kycDetail.count_left > 0 && (
                      <Col lg={12}>
                        <CommonBtn
                          role="btn"
                          className={`black-btn mt-3 ${style.btnspace}`}
                          title="Re-Submit"
                          onClick={() => {
                            resubmitKycHandler();
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              )}
              {kycDetail.status == "PENDING" && (
                <p className={style.kyctexting}>
                  Please wait your KYC is under process.
                </p>
              )}

              {kycDetail.status == "APPROVED" && (
                <p className={style.kyctexting}>
                  Congratulations! Your KYC is approved now.
                </p>
              )}
            </div>

            {/* {kycDetail.count_left == 0 && kycDetail.status == "REJECTED" && (
              <div
                className={`${style.kyc_section_tabvalid} ${style.kyctexting}`}
              >
                Your KYC is Declined.
              </div>
            )} */}
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default Kyc;
