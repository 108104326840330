import React, { createRef, useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SearchIcon from "../../../Assets/icons/search-icon.png";
import Logo from "../../../Assets/images/logo.png";
import { LoginIcon, SignUpIcon } from "../../../Assets/svgIcons/SVGicon";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import {
  changePasswordAction,
  getNotificationList,
  getUserProfile,
  logoutAction,
  openPopUp,
  saveSignUpCredential,
  setActiveTab,
  setDashboardActiveSubTab,
  setDashboardActiveTab,
  setScreenOrientation,
  setWalletActiveTab,
  setWalletSubActiveTab,
} from "../../../Features/user/userSlice";
import Forget from "../../Public/Forget";
import Login from "../../Public/Login/Login";
import TwoFaAuthentication from "../../Public/Login/TwoFaAuthentication";
import Reset from "../../Public/Reset";
import CommonBtn from "../Ui/CommonBtn/CommonBtn";
import CommonModal from "../Ui/CommonModal/CommonModal";
import FormikControls from "../Ui/Formik/FormikControls";
import SearchModal from "../Ui/SearchModal/SearchModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import { getIp } from "../../../Features/auth/authSlice";
import {
  ORIENTATION,
  tabName,
  USER_TYPE,
  WALLET_TAB,
} from "../../../Constant/userConstant";
import LogoModal from "../../../Assets/icons/Fav.png";
import Bell from "../../../Assets/icons/bell.png";
import style from "./NewHeader.module.scss";
import NotificationBox from "../Header/NotificationBox/NotificationBox";
import {
  EnableDisableAnonymousUserFunction,
  getAnonymousUserFunction,
  saveAnonymousDetail,
} from "../../../Features/Anonymous/anonymousUserSlice";
import personIcon from "../../../Assets/images/person_icon.jpg";
import { sliceChar } from "../../../Helper/common";
import { saveUserType } from "../../../Features/persist/persistSlice";
import socket from "../../../Socket/socket";
import AddressQrModal from "../../Private/Userprofile/Artowned/components/AddressQrModal";
import { resetMarketplaceFilter } from "../../../Features/marketplace/marketplaceSlice";

const Header = () => {
  // redux
  const dispatch = useDispatch();
  const ip = useSelector((state) => state?.persist?.ip);
  const { username } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const loginCase = useSelector((state) => state?.users?.loginCase);
  const anonymouseFormData = useSelector(
    (state) => state?.anonymousUser?.anonymouseFormData
  );
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const profileData = useSelector((state) => state?.users?.profileData);
  const profileDataOther = useSelector(
    (state) => state?.users?.profileDataOther
  );
  const userType = useSelector((state) => state?.persist?.userType);
  const accessToken = useSelector((state) => state.persist.accessToken);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);

  //state
  const [searchModal, setSearchModal] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [data, setData] = useState({});
  const [email, setEmail] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showQrAddress, setShowQrAddress] = useState(false);
  const [importData, setImportData] = useState({});
  const [unreadCount, setUnreadCount] = useState(0);

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isUserLoggedin) {
      getUserDetail();
    }
  }, [isUserLoggedin]);

  const getUserDetail = async () => {
    try {
      await dispatch(getUserProfile());
    } catch (err) {}
  };

  useEffect(() => {
    socket.emit("socket_notification_room", { apiAccessToken: accessToken });
  }, []);

  socket.on("USER_NOTIFICATION", (data) => {
    console.log(data);
    let notificationListClone = [...notificationList];
    //pushing recent notification at the top of the list
    notificationListClone.unshift(data);
    if (notificationListClone.lenght > 10) {
      // removing element if length is greater than 10 because only top 10 notification should be shown
      notificationListClone.pop();
    }
    setNotificationList(notificationListClone);
    //if any new notification pop in socket than set the flag true which indication dot on bell icon
    setUnreadCount(unreadCount + 1);
  });

  useEffect(() => {
    if (isUserLoggedin) {
      getNotificationHandler();
    }
  }, [isUserLoggedin, ArtistId]);

  const getNotificationHandler = async () => {
    let params = {
      size: 10,
      type: 1, //BOX NOTIFICATION
    };
    try {
      const response = await dispatch(getNotificationList(params));
      setUnreadCount(response?.data?.unreadCount);
      setNotificationList(response.data.data);
    } catch (error) {}
  };

  const toggleBtn = createRef(); // reference of toggle btn
  //function
  const handleSidebar = () => {
    // function for handling sidebar
    if (document.body.aazaza < 992) {
      toggleBtn.current.click(); // click toggleBtn only if screenwidth is less than 991
    }
  };
  const handleSidebarMarketplace = () => {
    // function for handling sidebar
    if (document.body.clientWidth < 992) {
      toggleBtn.current.click(); // click toggleBtn only if screenwidth is less than 991
    }
    dispatch(resetMarketplaceFilter());
  };

  const caseClick = (e) => {
    dispatch(openPopUp(e));
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  //Yup and formik
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(
      inputFieldValidation.commonMessage.required("Old password")
    ),
    newPassword: Yup.string()
      .required(inputFieldValidation.commonMessage.required("New password"))
      .max(16, "Password cannot be more than 16 characters")
      .matches(
        inputFieldValidation.password.regex.expresion,
        inputFieldValidation.password.regex.errorMessage
      )
      .test(
        "not-same-as-currentPassword",
        "*New password must be different from old password",
        function (value) {
          const oldPassword = this.parent.oldPassword || ""; // Access currentPassword value
          return value !== oldPassword; // Check if newPassword is different from currentPassword
        }
      ),
    confirmNewPassword: Yup.string()
      .required(
        inputFieldValidation.commonMessage.required("Confirm new password")
      )
      .oneOf([Yup.ref("newPassword"), null], "Password must match"),
  });
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data = {
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
        ipAddress: ip,
        deviceType: 0,
        confirmPassword: values.confirmNewPassword,
      };
      await dispatch(changePasswordAction(data)).then((response) => {});
      navigate("/home");
      await dispatch(logoutAction());
      handleClose();
    },
  });

  const logoutHandler = async (e) => {
    await dispatch(logoutAction());
  };

  useEffect(() => {
    dispatch(getIp())
      .then((res) => {})
      .catch((err) => {});
  }, []);

  useEffect(() => {
    handleOrientationChange();
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const handleOrientationChange = () => {
    dispatch(
      setScreenOrientation(
        window.matchMedia("(orientation: landscape)").matches
          ? ORIENTATION.LANDSCAPE
          : ORIENTATION.PORTRAIT
      )
    );
  };

  const handleClose = () => {
    dispatch(openPopUp(""));
    dispatch(saveSignUpCredential({ email: "", password: "" }));
    formik.resetForm();
  };

  const callbackDataPass = (data) => {
    setData(data);
  };

  useEffect(() => {
    if (
      !Object.keys(anonymouseFormData).length &&
      (userType === USER_TYPE.ARTIST || userType === USER_TYPE.ANONYMOUS)
    ) {
      getAnonymousDetail();
    }
  }, []);

  const getAnonymousDetail = async () => {
    try {
      let response = await dispatch(getAnonymousUserFunction());
      dispatch(saveAnonymousDetail(response?.data?.data));
    } catch (err) {}
  };

  const anonymousOrUserToggle = async (isAnonymousUser) => {
    let data = {
      anonymous_user: isAnonymousUser,
    };
    try {
      let response = await dispatch(EnableDisableAnonymousUserFunction(data));
      await getUserDetail();
      if (profileDataOther.username !== username) {
        navigate(`/profile/${response.data.data.username}`);
      }
      if (location.pathname.includes("/checkout/art")) {
        //Checking when real user toggle to anonymous or anonymous toggle to real user than navigate it to back page while user is on checkout page
        navigate(-1);
      }
    } catch (err) {}
  };

  const handleProfileClick = (isAnonymousUser) => {
    if (isAnonymousUser == USER_TYPE.ARTIST) {
      if (!Object.keys(anonymouseFormData).length) {
        //extra parameter sended when there is anonymous user is created
        navigate(RoutesUrls.User_account_setting, {
          state: { tab: "addeditanonymous" },
        });
      } else {
        anonymousOrUserToggle(1);
        dispatch(saveUserType(USER_TYPE.ANONYMOUS));
      }
    } else if (isAnonymousUser == USER_TYPE.ANONYMOUS) {
      anonymousOrUserToggle(2);
      dispatch(saveUserType(USER_TYPE.ARTIST));
    }
  };

  const getActiveUserImage = (isAnonymousUser) => {
    //2 for artist
    //3 for ano user
    if (isAnonymousUser == USER_TYPE.ARTIST) {
      if (anonymouseFormData?.image_path) {
        return `${process.env.REACT_APP_IMG_BASE_URL}${anonymouseFormData?.image_path}`;
      } else {
        return personIcon;
      }
    } else if (isAnonymousUser == USER_TYPE.ANONYMOUS) {
      if (profileData?.profile_image_path) {
        return `${process.env.REACT_APP_IMG_BASE_URL}${profileData?.profile_image_path}`;
      } else {
        return personIcon;
      }
    }
  };
  return (
    <>
      <div
        onClick={handleSidebar}
        className={`${style.headerBackdrop} d-lg-none ${
          backdrop ? style.active : ""
        }`}
      />
      <div className={style.header}>
        <Navbar className={style.header_navbar} expand="lg">
          <Container>
            <div className={style.header_left}>
              <Link className={style.header_logo} to="/home">
                <img src={Logo} alt="Logo" />
              </Link>
              <h6 className={style.infoArt}>
                {" "}
                A curated marketplace for Digital Fine Art (DFA){" "}
              </h6>
            </div>
            <div className={style.header_right}>
              <div className={style.header_search}>
                <Link
                  to="#"
                  onClick={() => {
                    setSearchModal(true);
                  }}
                >
                  <img src={SearchIcon} alt="SearchIcon" />
                </Link>
              </div>
              <Navbar.Collapse
                id="basic-navbar-nav"
                className={style.header_navbarCollapse}
              >
                <Nav className={`ms-auto ${style.header_nav}`}>
                  <Link
                    to={RoutesUrls.About_dfa}
                    onClick={handleSidebar}
                    className={style.nav_link}
                  >
                    What is DFA?
                  </Link>
                  <NavDropdown
                    title="Buy"
                    className={style.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    <Link
                      className={`dropdown-item ${style.header_dropDownItem}`}
                      to={RoutesUrls.Marketplace}
                      onClick={handleSidebarMarketplace}
                    >
                      Digital Fine Art
                    </Link>
                    <Link
                      className={`dropdown-item ${style.header_dropDownItem}`}
                      to={"#"}
                      onClick={() => {
                        handleSidebar();
                        window.open("https://naxcanvas.com/", "__blank");
                      }}
                    >
                      Art Frames
                    </Link>
                  </NavDropdown>
                  <Link
                    to={RoutesUrls.Howflaunt}
                    onClick={handleSidebar}
                    className={style.nav_link}
                  >
                    How to Display
                  </Link>
                  <NavDropdown
                    title="About"
                    className={style.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    {/* <Link
                      className={`dropdown-item ${style.header_dropDownItem}`}
                      to={RoutesUrls.About_dfa}
                      onClick={handleSidebarMarketplace}
                    >
                      What are DFAs?
                    </Link> */}
                    <Link
                      className={`dropdown-item ${style.header_dropDownItem}`}
                      to={RoutesUrls.About_newArtX}
                      onClick={handleSidebar}
                    >
                      About NewArtx
                    </Link>
                    {/* <Link
                      className={`dropdown-item ${style.header_dropDownItem}`}
                      to={RoutesUrls.Howflaunt}
                      onClick={handleSidebar}
                    >
                      How to flaunt
                    </Link> */}
                    <Link
                      className={`dropdown-item ${style.header_dropDownItem}`}
                      to={RoutesUrls.Faq}
                      onClick={handleSidebar}
                    >
                      FAQs
                    </Link>
                    <Link
                      className={`dropdown-item ${style.header_dropDownItem}`}
                      to={RoutesUrls.Contact_us}
                      onClick={handleSidebar}
                    >
                      Contact Us
                    </Link>
                    <Link
                      className={`dropdown-item ${style.header_dropDownItem}`}
                      to={RoutesUrls.Create}
                      onClick={handleSidebar}
                    >
                      Apply to create
                    </Link>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
              {!isUserLoggedin ? (
                <div className={style.header_action}>
                  <>
                    <Link
                      className={`nav-link ${style.header_navlink}`}
                      to={RoutesUrls.Login}
                      // onClick={() => {
                      //   caseClick("login");
                      // }}
                    >
                      <span>Log In</span>
                      <LoginIcon />
                    </Link>
                    <Link
                      className={`nav-link basBgClr ${style.header_navlink}`}
                      to={RoutesUrls.Signup}
                    >
                      <span>Sign Up</span>
                      <SignUpIcon />
                    </Link>
                  </>
                </div>
              ) : (
                <div className={` ${style.login_action}`}>
                  {/* before login */}
                  <Dropdown
                    className={style.header_notification_dropDown}
                    show={isOpen}
                    onClick={() => setIsOpen(!isOpen)}
                    ref={dropdownRef}
                  >
                    <Dropdown.Toggle
                      className={unreadCount != 0 && "btnNotification"}
                    >
                      <img src={Bell} alt="Bell Icon" />
                      {unreadCount != 0 && (
                        <span className="numberMail">
                          {unreadCount > 100 ? `99+` : unreadCount}
                        </span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <NotificationBox
                        notificationList={notificationList}
                        getNotificationHandler={getNotificationHandler}
                        setIsOpen={setIsOpen}
                        setImportData={setImportData}
                        setShowQrAddress={setShowQrAddress}
                        setNotificationList={setNotificationList}
                        setUnreadCount={setUnreadCount}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                  <span className={style.login_action_divider}></span>
                  <Dropdown className={style.header_userDropdown}>
                    <Dropdown.Toggle>
                      <img
                        src={
                          profileData?.image_path
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${profileData?.image_path}`
                            : personIcon
                        }
                        alt="Bell Icon"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className={style.header_userDropdown__userIcon}>
                        <button
                          className={`${style.btnProfileone} `}
                          onClick={() => {
                            navigate(`/profile/${profileData?.username}`);
                          }}
                        >
                          {/* LEFT*/}
                          <img
                            src={
                              profileData?.image_path
                                ? `${process.env.REACT_APP_IMG_BASE_URL}${profileData?.image_path}`
                                : personIcon
                            }
                            alt="Bell Icon"
                          />
                        </button>

                        <h6 title={profileData?.username}>
                          {sliceChar(
                            profileData?.username,
                            userType == USER_TYPE.ARTIST ||
                              userType == USER_TYPE.ANONYMOUS
                              ? 8
                              : 12
                          )}
                        </h6>
                        {(userType == USER_TYPE.ARTIST ||
                          userType == USER_TYPE.ANONYMOUS) && (
                          <button
                            className={`${style.user_changes} 
                          }`}
                            onClick={() => {
                              handleProfileClick(profileData?.user_type);
                            }}
                          >
                            {/* RIGHT  */}
                            <img
                              src={getActiveUserImage(profileData?.user_type)}
                              alt="Bell Icon"
                            />
                          </button>
                        )}
                      </div>

                      <div className={style.drop_links}>
                        <Dropdown.Item>
                          <Link to={`/profile/${profileData?.username}`}>
                            Profile
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link
                            to={RoutesUrls.User_dashboard}
                            onClick={() => {
                              dispatch(setDashboardActiveTab("offers"));
                              dispatch(
                                setDashboardActiveSubTab(tabName.OFFER_RECIEVED)
                              );
                            }}
                          >
                            Dashboard
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link
                            to={RoutesUrls.Wallet}
                            onClick={() => {
                              dispatch(
                                setWalletActiveTab(WALLET_TAB.MAINTAB.WALLET)
                              );
                              dispatch(
                                setWalletSubActiveTab(
                                  WALLET_TAB.SUBTAB.INPROCRESS
                                )
                              );
                            }}
                          >
                            Wallet
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link
                            to={RoutesUrls.User_account_setting}
                            onClick={() =>
                              dispatch(setActiveTab("editprofile"))
                            }
                          >
                            Account Settings
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to={RoutesUrls.Link_devices}>
                            Display Devices
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to={RoutesUrls.Create}>Apply To Create</Link>
                        </Dropdown.Item>
                        {/* <Dropdown.Item>
                          <Link
                            to="/home"
                            onClick={() => {
                              caseClick("change-password");
                            }}
                          >
                            Change Password
                          </Link>
                        </Dropdown.Item> */}
                        <Dropdown.Item>
                          <Link
                            to="/home"
                            onClick={(e) => {
                              logoutHandler(e);
                            }}
                          >
                            Sign Out
                          </Link>
                        </Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              <Navbar.Toggle
                className={style.header_toggleBtn}
                aria-controls="basic-navbar-nav"
                onClick={() => setBackdrop(!backdrop)}
                ref={toggleBtn}
              >
                <span className="menu-line"></span>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
              </Navbar.Toggle>
            </div>
          </Container>
        </Navbar>
      </div>

      {/* Header popups */}
      {(() => {
        switch (loginCase) {
          case "thankYou":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={
                  <>
                    Thanks For <br /> Signing up.
                  </>
                }
                centered={true}
              >
                <img src={LogoModal} alt="Logo" />
                <p className="stayTuned">
                  Stay tuned. <br />
                  We'll let you know about all our upcoming drops and news
                </p>
              </CommonModal>
            );
          case "forgot":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Forgot Password ?</>}
                centered={true}
                subTitle="No worries, we'll send you reset notifications."
              >
                <Forget
                  callbackDataPass={callbackDataPass}
                  handleClose={handleClose}
                  ip={ip}
                  setEmail={setEmail}
                />
              </CommonModal>
            );
          case "reset-password":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Set New Password</>}
                centered={true}
              >
                <Reset
                  handleClose={handleClose}
                  ip={ip}
                  data={data}
                  email={email}
                />
              </CommonModal>
            );
          // case "change-password":
          //   return (
          //     <CommonModal
          //       show={true}
          //       onHide={handleClose}
          //       modalTitle={<>Change Password</>}
          //       centered={true}
          //       formik={formik}
          //     >
          //       <form onSubmit={formik.handleSubmit}>
          //         <Row>
          //           <Col xs={12}>
          //             <FormikControls
          //               variant={style.spaceField}
          //               rightIcon={style.iconblack}
          //               className="fieldBlack"
          //               control="password"
          //               name="oldPassword"
          //               placeholder="Old Password"
          //               formik={formik}
          //               onChange={formik.handleChange}
          //               value={formik.values.oldPassword}
          //               onBlur={formik.handleBlur}
          //             />
          //           </Col>
          //           <Col xs={12}>
          //             <FormikControls
          //               variant={style.spaceField}
          //               rightIcon={style.iconblack}
          //               className="fieldBlack"
          //               control="password"
          //               name="newPassword"
          //               placeholder="New Password"
          //               formik={formik}
          //               onChange={formik.handleChange}
          //               value={formik.values.newPassword}
          //               onBlur={formik.handleBlur}
          //               maxLength={16}
          //             />
          //           </Col>
          //           <Col xs={12}>
          //             <FormikControls
          //               variant={style.spaceField}
          //               rightIcon={style.iconblack}
          //               className="fieldBlack"
          //               control="password"
          //               name="confirmNewPassword"
          //               placeholder="Confirm New Password"
          //               formik={formik}
          //               onChange={formik.handleChange}
          //               value={formik.values.confirmNewPassword}
          //               onBlur={formik.handleBlur}
          //             />
          //           </Col>
          //           <Col xs={12}>
          //             <CommonBtn
          //               type={"submit"}
          //               role="btn"
          //               className="btn-yellow-black "
          //               title="Save"
          //             />
          //           </Col>
          //         </Row>
          //       </form>
          //     </CommonModal>
          //   );
          default:
            return null;
        }
      })()}

      {searchModal && (
        <SearchModal
          show={searchModal}
          onHide={() => {
            setSearchModal(false);
            setSearchTerm("");
          }}
          setSearchModal={setSearchModal}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}

      {showQrAddress && Object.keys(importData).length > 0 && (
        <AddressQrModal
          show={showQrAddress}
          onHide={() => setShowQrAddress(false)}
          importData={importData}
        />
      )}
    </>
  );
};

export default Header;
