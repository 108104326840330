import React from 'react'
import CommonModal from '../CommonModal/CommonModal';
import { useFormik } from 'formik';
import * as Yup from "yup";
import FormikControls from '../Formik/FormikControls';
import CommonBtn from '../CommonBtn/CommonBtn';
import styles from './QuickBuyModal.module.scss';

const QuickBuyModal = (props) => {
    const { show, onHide, artImg, creatorFees, processingFees, texas, totalAmount, artistName, edition, totalEditions } = props;
    const initialValue = {
        edition: "",
    };
    const validationSchema = Yup.object({
        edition: Yup.string().required("Required")
    });
    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: validationSchema,
        onSubmit: () => {

        }
    })
    return (
        <CommonModal className={styles.quickModal} show={show} onHide={onHide}>
            <div className='d-flex flex-md-row flex-column-reverse'>
                <div className={styles.quickModalLeft}>
                    <div className={styles.quickModalLeftInner}>
                        <img src={artImg} alt="" />
                    </div>
                    <span className={styles.bottomLine} />
                </div>
                <div className={styles.quickModalRight}>
                    <h3>Art Name</h3>
                    <div className={styles.artistDetails}>
                        <h4>{artistName}</h4>
                        <div className={styles.artistDetailsEdition}>
                            <p>EDITION</p>
                            <span>{edition} / {totalEditions}</span>
                        </div>
                    </div>
                    <div className={styles.editionDetails}>
                        <form onSubmit={formik.handleSubmit}>
                            <FormikControls
                                control="input"
                                type="text"
                                name="edition"
                                placeholder="Edition Details"
                                formik={formik}
                                variant='mb-0'
                            />
                        </form>
                    </div>
                    <div className={styles.amountDetails}>
                        <h5>Total Amount : </h5>
                        <ul>
                            <li>
                                <p>Creator Fees: </p>
                                <p>{creatorFees}</p>
                            </li>
                            <li>
                                <p>Processing Fees : </p>
                                <p>{processingFees}</p>
                            </li>
                            <li>
                                <p>Taxes@XX% : </p>
                                <p>{texas}</p>
                            </li>
                            <li>
                                <h6>Total Amount</h6>
                                <h6>{totalAmount}</h6>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.buy_btn_box}>
                        <CommonBtn
                            role='btn'
                            title="BUY NOW"
                            className={`${styles.buy_btn} btn-yellow`}
                        />
                    </div>
                    <span className={styles.bottomLine} />
                </div>
            </div>
        </CommonModal>
    )
}

export default QuickBuyModal
