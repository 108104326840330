import React, { useEffect, useState } from "react";
import CommonModal from "../CommonModal/CommonModal";
import { Col, Row } from "react-bootstrap";
import FormikControls from "../Formik/FormikControls";
import CommonBtn from "../CommonBtn/CommonBtn";
import style from "./ViewGstDetailsModal.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { parsePhoneNumber } from "react-phone-number-input";
import { inputFieldValidation } from "../../../../Constant/formikConstant";
import { indianStates } from "../../../../Constant/State";
import { citiesInIndia } from "../../../../Constant/City";

const ViewGstDetailsModal = ({
  show,
  handleClose,
  gstViewRecord,
  updateGstHandler,
}) => {
  const [phoneCountryCode, setPhoneNumberCountryCode] = useState("");
  const [cityOption, setCityOption] = useState([]);
  const initialValue = {
    state: gstViewRecord?.state
      ? indianStates.filter((item) => item.value === gstViewRecord?.state)[0]
      : "",
    gst: gstViewRecord?.gst_number ? gstViewRecord?.gst_number : "",
    legalBusinessName: gstViewRecord?.business_name
      ? gstViewRecord?.business_name
      : "",
    addressLine1: gstViewRecord?.address_line_1
      ? gstViewRecord?.address_line_1
      : "",
    addressLine2: gstViewRecord?.address_line_2
      ? gstViewRecord?.address_line_2
      : "",
    // city: gstViewRecord?.city
    //   ? citiesInIndia[gstViewRecord?.state].filter(
    //       (item) => item.value === gstViewRecord?.city
    //     )[0]
    //   : "",
    city: gstViewRecord?.city ? gstViewRecord?.city : "",
    pinCode: gstViewRecord?.pincode ? gstViewRecord?.pincode : "",
    phoneNumber: gstViewRecord?.mobile
      ? `${gstViewRecord?.country_code}${gstViewRecord?.mobile}`
      : "",
    email: gstViewRecord?.email ? gstViewRecord?.email : "",
  };

  const gstSchema = Yup.object().shape({
    state: Yup.object().required(
      inputFieldValidation.commonMessage.required("State")
    ),
    gst: Yup.string()
      // .required(inputFieldValidation.commonMessage.required("GST number"))
      .matches(/^\S+$/, "*Space is not allowed")
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "*Only alphanumeric characters and spaces are allowed"
      )
      .min(
        inputFieldValidation.gst.minLength,
        `*Must be of ${inputFieldValidation.gst.minLength} digit`
      )
      .max(
        inputFieldValidation.gst.maxLength,
        `*Must be of ${inputFieldValidation.gst.minLength} digit`
      ),

    legalBusinessName: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Business name"))
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "*Only alphanumeric characters and spaces are allowed"
      )
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Business name should not start or end with a space"
      )
      .min(
        inputFieldValidation.businessName.minLength,
        `*Must be between ${inputFieldValidation.businessName.minLength}-${inputFieldValidation.businessName.maxLength} characters`
      )
      .max(
        inputFieldValidation.businessName.maxLength,
        `*Must be between ${inputFieldValidation.businessName.minLength}-${inputFieldValidation.businessName.maxLength} characters`
      ),
    addressLine1: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Address"))
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      )
      .min(
        inputFieldValidation.address.minLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .max(
        inputFieldValidation.address.maxLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      ),
    addressLine2: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Address"))
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      )
      .min(
        inputFieldValidation.address.minLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .max(
        inputFieldValidation.address.maxLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      ),
    city: Yup.string().required(
      inputFieldValidation.commonMessage.required("City")
    ),
    pinCode: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Pin code"))
      .matches(/^[1-9]\d{5}$/, "*Invalid PIN code"),
    phoneNumber: Yup.string()
      .phone(phoneCountryCode, true, "*Invalid phone number")
      .required(inputFieldValidation.commonMessage.required("Phone number")),
    email: Yup.string()
      .matches(
        inputFieldValidation.email.regexForSingup.expresionForSingup,
        inputFieldValidation.email.regexForSingup.errorMessageForSingup
      ),
    // .required(inputFieldValidation.commonMessage.required("Email")),
  });

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: gstSchema,
    onSubmit: (values) => {
      updateGstHandler(values);
    },
  });

  useEffect(() => {
    statewiseCity(gstViewRecord.state);
  }, []);

  const statewiseCity = (state) => {
    setCityOption(citiesInIndia[state]);
  };

  return (
    <>
      <CommonModal
        modalTitle="GST Details"
        show={show}
        onHide={handleClose}
        className={style.view_modal}
        centered
      >
        <form onSubmit={formik.handleSubmit} className={style.form}>
          <Row>
            <Col sm={6}>
              <FormikControls
                options={indianStates}
                type="select"
                className={style.select}
                name="state"
                formik={formik}
                control="select"
                label="Select State"
                onChange={(value) => {
                  formik.setFieldValue("state", value);
                  formik.setFieldValue("city", "");
                  statewiseCity(value.value);
                }}
                defaultValue={formik.values.state}
                value={formik.values.state}
              />
            </Col>
            <Col sm={6}>
              <FormikControls
                variant="input_spaces"
                className="input_border"
                control="input"
                type="input"
                name="gst"
                placeholder="Enter GST"
                maxLength={15}
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (/^[a-zA-Z0-9\s]*$/.test(e.target.value)) {
                    formik.setFieldValue("gst", e.target.value.toUpperCase());
                  }
                }}
                value={formik.values.gst}
                label="Enter GST"
              />
            </Col>
            <Col sm={6}>
              <FormikControls
                variant="input_spaces"
                className="input_border"
                control="input"
                type="input"
                name="legalBusinessName"
                placeholder="Legal Business Name"
                label="Legal Business Name"
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values.legalBusinessName}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col sm={6}>
              <FormikControls
                variant="input_spaces"
                className="input_border"
                control="input"
                type="input"
                name="addressLine1"
                placeholder="Address Line 1"
                label="Address Line 1"
                formik={formik}
                onBlur={formik.handleBlur}
                value={formik.values.addressLine1}
                onChange={formik.handleChange}
              />
            </Col>
            <Col sm={6}>
              <FormikControls
                variant="input_spaces"
                className="input_border"
                control="input"
                type="input"
                name="addressLine2"
                placeholder="Address Line 2"
                label="Address Line 2"
                formik={formik}
                onBlur={formik.handleBlur}
                value={formik.values.addressLine2}
                onChange={formik.handleChange}
              />
            </Col>

            <Col sm={6}>
              <FormikControls
                variant="input_spaces"
                className="input_border"
                control="input"
                type="input"
                name="city"
                placeholder="City"
                label="Enter City"
                formik={formik}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                onChange={formik.handleChange}
                disabled={!formik.values.state.value}
              />
            </Col>

            {/* <Col sm={6}>
              <FormikControls
                options={cityOption}
                type="select"
                className={style.select}
                name="city"
                formik={formik}
                control="select"
                label="Select City"
                onChange={(value) => formik.setFieldValue("city", value)}
                value={formik.values.city}
              />
            </Col> */}
            <Col sm={6}>
              <FormikControls
                variant="input_spaces"
                className="input_border"
                control="input"
                type="input"
                name="pinCode"
                placeholder="Pincode"
                label="Pincode"
                formik={formik}
                maxLength={6}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  //regex for only digit
                  if (/^\d*$/.test(e.target.value)) {
                    formik.setFieldValue("pinCode", e.target.value);
                  }
                }}
                value={formik.values.pinCode}
              />
            </Col>
            <Col sm={6}>
              <FormikControls
                control="phone"
                classinput="phone_border input_phone"
                type="input"
                name="phoneNumber"
                placeholder="Phone Number"
                label="Phone Number"
                variant="input_number"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={async (value) => {
                  await formik.setFieldTouched("phoneNumber", true);
                  if (value) {
                    setPhoneNumberCountryCode(parsePhoneNumber(value)?.country);
                  }
                  formik.setFieldValue("phoneNumber", value);
                }}
                value={formik.values.phoneNumber}
              />
            </Col>
            <Col sm={6}>
              <FormikControls
                variant="input_spaces"
                className="input_border"
                control="input"
                type="input"
                name="email"
                placeholder="Email Address"
                label="Email Address"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </Col>
          </Row>
          <CommonBtn role="btn" title="Save Changes" />
        </form>
      </CommonModal>
    </>
  );
};

export default ViewGstDetailsModal;
