import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LeftarrowIcon,
  RightarrowwhiteIcon,
} from "../../../../../Assets/svgIcons/SVGicon";
import {
  DECIMAL,
  offerType,
  OFFER_STATUS,
} from "../../../../../Constant/userConstant";
import {
  createCounterOffer,
  updateOfferStatus,
} from "../../../../../Features/offerSlice/offerSlice";
import { createCounterGlobalOffer } from "../../../../../Features/user/userSlice";
import { getNumberWithComma, sliceChar } from "../../../../../Helper/common";
import Notfound from "../../../../Common/NotFound/Notfound";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import CustomPagination from "../../../../Common/Ui/Pagination/CustomPagination";
import AcceptOffermodal from "../../../Newdesigns/AcceptOffermodal/AcceptOffermodal";
import Declinemodal from "../../../Newdesigns/Declinemodal/Declinemodal";
import MakeCounterOfferModal from "../../../Newdesigns/MakeCounterOfferModal/MakeCounterOfferModal";
import MakeGlobalOffermodal from "../../../Newdesigns/MakeGlobalOffermodal/MakeGlobalOffermodal";
import OfferreceivedGlobal from "./OfferReceived/OfferreceivedGlobal";
import OfferReceivedIndivital from "./OfferReceived/OfferReceivedIndivital";
import style from "./Offersreceived.module.scss";

const Offersreceived = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let {
    totalPage,
    page,
    data,
    setSearch,
    getUserOfferRecievedList,
    setRecieveOfferList,
    onChangeActiveDeactiveOffer,
    getOffersById,
    setPage,
    setTotalMade,
    getUserOfferMadeList,
  } = props;
  const [showing, setShowing] = useState(false);
  const [makeOfferDetails, setMakeOfferDetails] = useState({});
  const [offer, setOffer] = useState({});
  const [sortBy, setSortBy] = useState("desc");
  const [isGlobalOfferModal, SetIsGlobalOfferModal] = useState(false);
  const [saveActionDetails, setSaveActionDetails] = useState({});
  const [showacceptoffer, setShowacceptoffer] = useState(false);
  const [showdecline, setShowdecline] = useState(false);
  const [showCounterOfferModal, setShowCounterOfferModal] = useState(false);

  const sortedData = (artOfferData, artIndex) =>
    [...artOfferData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (data[artIndex].sortBy === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

  const goToUserProfile = (username) => {
    navigate(`/profile/${username}`);
  };
  const updateStatusOpenModal = (update_status, item, index, art) => {
    let data = {
      status: update_status,
      id: item.id,
      index: index,
      item: item,
      art: art,
    };
    //return
    setSaveActionDetails(data);
    if (update_status == OFFER_STATUS.accepted) {
      setShowacceptoffer(true);
    } else if (update_status == OFFER_STATUS.declined) {
      setShowdecline(true);
    } else if (update_status == OFFER_STATUS.coutered) {
      setShowCounterOfferModal(true);
    }
  };

  const makeMyOffer = (data, offer) => {
    setOffer(offer);
    setMakeOfferDetails(data);
    setShowing(true);
  };

  const setParticularArtType = (index, offer_type, art) => {
    let records = [...data];
    records[index].offer_type = offer_type;
    setRecieveOfferList(records);
    let params = {
      index: index,
      art_id: art.id,
      edition_no: art?.art_owner_editions[art.selectedEditionIndex]?.edition_no, // we need adition_no outside where art details is coming
      offer_type: offer_type,
      page: records[index].page,
      selectedEditionIndex: records[index].selectedEditionIndex,
    };
    getOffersById(params);
  };

  const changeNextEdition = (art, type, artIndex) => {
    let records = [...data];
    let singleArt = { ...art };
    let allEditions = art.art_owner_editions;
    let finalData = {
      index: artIndex,
      art_id: singleArt.id,
      offer_type: singleArt.offer_type,
      page: singleArt.page,
    };
    if (allEditions.length > 0) {
      if (
        type == "inc" &&
        records[artIndex].selectedEditionIndex + 1 < allEditions.length
      ) {
        records[artIndex].selectedEditionIndex =
          singleArt.selectedEditionIndex + 1; //main record

        finalData.selectedEditionIndex = singleArt.selectedEditionIndex + 1;
        finalData.edition_no =
          singleArt?.art_owner_editions[
            singleArt.selectedEditionIndex + 1
          ]?.edition_no;
        setRecieveOfferList(records);
        getOffersById(finalData);
      } else {
        if (singleArt.selectedEditionIndex >= 1 && type == "dec") {
          records[artIndex].selectedEditionIndex =
            singleArt.selectedEditionIndex - 1;
          finalData.selectedEditionIndex = singleArt.selectedEditionIndex - 1;
          finalData.edition_no =
            singleArt?.art_owner_editions[
              singleArt.selectedEditionIndex - 1
            ]?.edition_no;
          setRecieveOfferList(records);
          getOffersById(finalData);
        }
      }
    }
  };

  const onChangeOfferPageNo = (page, index, art) => {
    let records = [...data];
    records[index].page = page;
    setRecieveOfferList(records);
    let params = {
      index: index,
      art_id: art.id,
      edition_no: art?.art_offer[0]?.edition_no,
      offer_type: records[index].offer_type,
      page: page,
      selectedEditionIndex: art.selectedEditionIndex,
    };
    getOffersById(params);
  };

  const onMakeOfferRequest = async (data) => {
    let counterData = {
      offer_price: parseFloat(data.offer_price),
      id: data.id,
    };
    let finalData = {
      art_id: data.makeOfferDetails.art.id,
      edition_no: data.makeOfferDetails.item.edition_no,
      offer_type: data.makeOfferDetails.item.offer_type,
      page: data.makeOfferDetails.art.page,
      index: data.makeOfferDetails.index,
      selectedEditionIndex: data.makeOfferDetails.art.selectedEditionIndex,
    };
    try {
      let response = await dispatch(createCounterOffer(counterData));
      if (response) {
        setShowCounterOfferModal(false);
        getOffersById(finalData);
        // call made offer api to get lastest records
        getUserOfferMadeList();
      }
    } catch (err) {
      setShowCounterOfferModal(false);
    }
  };

  const getUpdateOfferStatus = async (item) => {
    let updateStatusData = {
      status: item.status,
      id: item.id,
    };
    if (item.status == OFFER_STATUS.declined) {
      updateStatusData.declined_reason = item.declined_reason;
    }
    let finalData = {
      art_id: item.makeOfferDetails.art.id,
      offer_type: item.makeOfferDetails.item.offer_type,
      page: item.makeOfferDetails.art.page,
      index: item.makeOfferDetails.index,
    };

    try {
      let response = await dispatch(updateOfferStatus(updateStatusData));
      if (response) {
        let allEditions = response.data.data.art_owner_editions;
        allEditions.sort((a, b) => a.edition_no - b.edition_no);
        let isRecordLeft = response.data.data.art_owner_editions.length;

        if (isRecordLeft) {
          finalData.edition_no = allEditions[0].edition_no;
          finalData.selectedEditionIndex =
            allEditions.length == 1
              ? 0
              : item.makeOfferDetails.art.selectedEditionIndex;
          getOffersById(finalData);
        } else {
          // remove particular art from main data when there is no offer left in particular edition
          let orignalArts = [...data];
          orignalArts.splice(item.makeOfferDetails.index, 1);
          setRecieveOfferList(orignalArts);
          setTotalMade(0);
        }
        setShowacceptoffer(false);
        setShowdecline(false);
      }
    } catch (err) {}
  };

  const onMakeGlobalOffer = async (data) => {
    let updateStatusData = {
      art_id: makeOfferDetails?.id,
      offer_price: data.offer_price,
    };
    try {
      let response = await dispatch(createCounterGlobalOffer(updateStatusData));
      if (response) {
        SetIsGlobalOfferModal(false);
        getUserOfferRecievedList();
      }
    } catch (err) {
      SetIsGlobalOfferModal(false);
    }
  };

  const openGlobalOfferModal = (artDetails, index) => {
    let art = artDetails;
    art.index = index;
    setMakeOfferDetails(art);
    SetIsGlobalOfferModal(true);
  };
  const navigateToArt = (id) => {
    navigate(`/digital-fine-arts/${id}`);
  };

  const sortByArtEdition = (index, sortBy) => {
    let records = [...data];
    if (sortBy === "desc" && records[index].sortBy != sortBy) {
      records[index].art_offer = [...records[index].art_offer].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    } else if (sortBy === "asc" && records[index].sortBy != sortBy) {
      records[index].art_offer = [...records[index].art_offer].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA - dateB;
      });
    }
    records[index].sortBy = sortBy;
    setRecieveOfferList(records);
  };

  return (
    <>
      <section className={style.offers_received}>
        {/* <>
          <div className="search_input mb-5">
            <div className="search_input_inner">
              <button type="submit" className="search_input_icon">
                <SearchIcon />
              </button>

              <input
                type="search"
                className="form-control"
                placeholder="Search Art"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </> */}
        <div className={style.main_containt}>
          {data.map((art, index) => (
            <Row key={index} id={index} className={style.offier_recive_row}>
              <Col xl={3} lg={4} className={style.colSpaceLeft}>
                <div className={style.artimgselect}>
                  <img
                    className="cursor-pointer"
                    src={`${process.env.REACT_APP_IMG_BASE_URL}${art?.thumbnail_image}`}
                    alt="artist_nft"
                    onClick={() => navigateToArt(art.id)}
                  />
                  <div className={style.text_fields}>
                    <h5 title={art.art_name}>{sliceChar(art.art_name, 15)}</h5>
                    <p
                      className="cursor-pointer"
                      onClick={() => goToUserProfile(art.art_user?.username)}
                    >
                      @{sliceChar(art?.art_user?.username, 15)}
                    </p>
                    <ul className={style.listart}>
                      <li>
                        <label>Views</label>
                        <h6>{art.view_count}</h6>
                      </li>
                      <li>
                        <label>Likes</label>
                        <h6>{art?.like_count}</h6>
                      </li>
                    </ul>
                    <ul className={style.price_list}>
                      <li>
                        <label>Purchase Price</label>
                        <h6>
                          ₹{" "}
                          {getNumberWithComma(
                            (
                              Number(art?.art_owners[0]?.purchase_price) /
                              DECIMAL
                            ).toFixed(2)
                          )}
                        </h6>
                      </li>
                      <li>
                        <label>Last Sale Price</label>
                        <h6>
                          ₹{" "}
                          {getNumberWithComma(
                            (Number(art?.last_sell_price) / DECIMAL).toFixed(2)
                          )}
                        </h6>
                      </li>
                    </ul>
                    <CommonBtn
                      role="btn"
                      className={`black-btn ${style.btneditsize}`}
                      title="Make Global Counter Offer"
                      onClick={() => openGlobalOfferModal(art, index)}
                    />
                  </div>
                </div>
              </Col>
              <Col xl={9} lg={8} className={style.colSpaceRight}>
                <div className={style.edition_head}>
                  <div className={style.edition_select}>
                    <label>Select Edition</label>
                    <div className={style.edition_number}>
                      {art?.art_owner_editions.length > 0 && (
                        <button
                          type="button"
                          onClick={() => changeNextEdition(art, "dec", index)}
                        >
                          <LeftarrowIcon />
                        </button>
                      )}
                      {art?.art_owner_editions.length > 0 && (
                        <p>
                          {
                            art?.art_owner_editions[art?.selectedEditionIndex]
                              .edition_no
                          }
                        </p>
                      )}
                      {art?.art_owner_editions.length > 0 && (
                        <button
                          type="button"
                          onClick={() => changeNextEdition(art, "inc", index)}
                        >
                          <RightarrowwhiteIcon />
                        </button>
                      )}
                    </div>
                  </div>
                  <CommonBtn
                    role="btn"
                    className={`bordred-black me-4 ${
                      art?.offer_type == offerType.SINGLE ? "activeBtn" : ""
                    } ${style.btneditsize}`}
                    title="Individual Offer"
                    onClick={() =>
                      setParticularArtType(index, offerType.SINGLE, art)
                    }
                  />
                  <CommonBtn
                    role="btn"
                    className={`bordred-black ${
                      art?.offer_type == offerType.GLOBAL ? "activeBtn" : ""
                    } ${style.btneditsize}`}
                    title="Global Offer"
                    onClick={() =>
                      setParticularArtType(index, offerType.GLOBAL, art)
                    }
                  />
                </div>
                {art?.offer_type == offerType.SINGLE && (
                  <OfferReceivedIndivital
                    art={art}
                    sortedData={sortedData}
                    updateStatusOpenModal={updateStatusOpenModal}
                    makeMyOffer={makeMyOffer}
                    onChangeActiveDeactiveOffer={onChangeActiveDeactiveOffer}
                    artIndex={index}
                    setActivePage={onChangeOfferPageNo}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    setRecieveOfferList={setRecieveOfferList}
                    sortByArtEdition={sortByArtEdition}
                  />
                )}
                {art?.offer_type == offerType.GLOBAL && (
                  <OfferreceivedGlobal
                    art={art}
                    sortedData={sortedData}
                    onChangeActiveDeactiveOffer={onChangeActiveDeactiveOffer}
                    updateStatusOpenModal={updateStatusOpenModal}
                    makeMyOffer={makeMyOffer}
                    artIndex={index}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortByArtEdition={sortByArtEdition}
                  />
                )}
              </Col>
            </Row>
          ))}
          {data.length == 0 && <Notfound />}
        </div>
        {totalPage > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPage}
            setActivePage={setPage}
            maxVisiblePages={5}
          />
        )}
      </section>

      {isGlobalOfferModal && (
        <MakeGlobalOffermodal
          show={isGlobalOfferModal}
          onHide={() => SetIsGlobalOfferModal(false)}
          onMakeGlobalOffer={onMakeGlobalOffer}
          makeOfferDetails={makeOfferDetails}
        />
      )}

      {showacceptoffer && (
        <AcceptOffermodal
          show={showacceptoffer}
          onHide={() => setShowacceptoffer(false)}
          makeOfferDetails={saveActionDetails}
          getUpdateOfferStatus={getUpdateOfferStatus}
          isHighestAccept={false}
        />
      )}

      {showdecline && (
        <Declinemodal
          show={showdecline}
          onHide={() => setShowdecline(false)}
          makeOfferDetails={saveActionDetails}
          getUpdateOfferStatus={getUpdateOfferStatus}
        />
      )}

      {showCounterOfferModal && (
        <MakeCounterOfferModal
          show={showCounterOfferModal}
          onHide={() => setShowCounterOfferModal(false)}
          makeOfferDetails={saveActionDetails}
          onMakeOfferRequest={onMakeOfferRequest}
          getUserOfferRecievedList={getUserOfferRecievedList}
        />
      )}
    </>
  );
};

export default Offersreceived;
