import React from "react";
import { DocIcon } from "../../../../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../../../Common/Ui/CommonModal/CommonModal";
import "./CashoutIndex.scss";

const RejectModal = ({ show, handleClose, viewDetail }) => {
  return (
    <>
      <CommonModal
        centered
        className="reject_modal"
        show={show}
        onHide={handleClose}
        modalTitle="Reason For Rejection"
      >
        <div className="main_content">
          <p>{viewDetail?.reason}</p>
          {viewDetail?.attachment && (
            <a
              className="iconUp"
              href={`${process.env.REACT_APP_IMG_BASE_URL}${viewDetail?.attachment}`}
              target="__blank"
            >
              <DocIcon /> View
            </a>
          )}
        </div>
      </CommonModal>
    </>
  );
};

export default RejectModal;
