import React from "react";
import CommonModal from "../../../../Common/Ui/CommonModal/CommonModal";
import "./SuccessfullyModal.scss";

const SuccessfullyModal = ({ show, handleClose }) => {
  return (
    <>
      <CommonModal
        centered
        show={show}
        onHide={handleClose}
        className="successfullyModal"
        // modalTitle='Successfully'
      >
        <div className="InnerSec">
          <h3>Your cash out request is successfully submitted.</h3>
          <h5>
            Your amount will be reflect in your account within 2-3 working days.
          </h5>
        </div>
      </CommonModal>
    </>
  );
};

export default SuccessfullyModal;
