import React from "react";
import Form from "react-bootstrap/Form";
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Date.scss';
import '../Input/Input.scss';

const DatePicker = (props) => {
  const { label, name, value, onChange, placeholder, className, ...rest } = props;

  return (
    <Form.Group className={`common_date common_input ${className}`} controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="common_input_inner">
        <DateView
          id={name}
          className="form-control"
          {...rest}
          selected={value}
          onChange={(val) => onChange(name, val)}
          placeholderText={placeholder}
        />
      </div>
    </Form.Group>
  );
};

export default DatePicker;