import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import style from "./Password.module.scss";
import FormikControls from "../../../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import { Col, Row } from "react-bootstrap";
import { inputFieldValidation } from "../../../../../Constant/formikConstant";
import {
  changePasswordAction,
  logoutAction,
} from "../../../../../Features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Password = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ip = useSelector((state) => state?.persist?.ip);

  const initialValue = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required(
      inputFieldValidation.commonMessage.required("Current password")
    ),
    newPassword: Yup.string()
      .required(inputFieldValidation.commonMessage.required("New password"))
      .matches(
        inputFieldValidation.password.regex.expresion,
        inputFieldValidation.password.regex.errorMessage
      )
      .test(
        "not-same-as-currentPassword",
        "*New password must be different from current password",
        function (value) {
          const currentPassword = this.parent.currentPassword || ""; // Access currentPassword value
          return value !== currentPassword; // Check if newPassword is different from currentPassword
        }
      ),
    confirmNewPassword: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Confirm password"))
      .oneOf([Yup.ref("newPassword"), null], "*Must match with password"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      changePasswordHandler(values);
    },
  });

  const changePasswordHandler = async (values) => {
    try {
      let data = {
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmNewPassword,
        ipAddress: ip,
        deviceType: 0,
      };
      const respone = await dispatch(changePasswordAction(data));
      await dispatch(logoutAction());
      //   navigate("/home");
    } catch (error) {}
  };

  const getStatus = (type) => {
    switch (type) {
      case "Length":
        if (formik.values.newPassword.length >= 6) {
          return true;
        }
        return false;
      case "OneLower":
        return /[a-z]/.test(formik.values.newPassword);
      case "OneUpper":
        return /[A-Z]/.test(formik.values.newPassword);
      case "OneNumber":
        return /[0-9]/.test(formik.values.newPassword);
      case "OneSpecialChar":
        return /[!"#$%&'()*+,\/\-.:;<=>?@[\\\]^_`{|}~]/.test(
          formik.values.newPassword
        );
      default:
        break;
    }
  };

  return (
    <>
      <section className={style.password_sec}>
        <h5 className={style.password_heading}>Choose new password</h5>
        <form onSubmit={formik.handleSubmit}>
          {/* {forget ?
                    <> */}
          <Row>
            <Col lg={6} md={6} sm={6}>
              <div className={style.input_fields_form}>
                <FormikControls
                  variant="border_input black-eye-icon"
                  control="password"
                  label="Current Password"
                  name="currentPassword"
                  formik={formik}
                  placeholder="Enter Current Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentPassword}
                />
                <FormikControls
                  variant="border_input black-eye-icon"
                  control="password"
                  label="New Password"
                  name="newPassword"
                  formik={formik}
                  placeholder="Enter New Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                />
                <FormikControls
                  variant="border_input black-eye-icon"
                  control="password"
                  label="Confirm Password"
                  name="confirmNewPassword"
                  formik={formik}
                  placeholder="Enter Confirm Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmNewPassword}
                />
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <ul className={style.listingpass}>
                <li className={getStatus("Length") ? style.active : ""}>
                  minimum 6 characters
                </li>
                <li className={getStatus("OneLower") ? style.active : ""}>
                  one lowercase character
                </li>
                <li className={getStatus("OneUpper") ? style.active : ""}>
                  one uppercase character
                </li>
                <li className={getStatus("OneNumber") ? style.active : ""}>
                  one number
                </li>
                <li className={getStatus("OneSpecialChar") ? style.active : ""}>
                  one special character
                </li>
              </ul>
            </Col>
          </Row>
          <CommonBtn
            role="btn"
            className={`bordred-black ${style.btnsize}`}
            title="Submit"
            type="submit"
          />
          {/* </>
                    :
                    <>
                        <div className={style.heading_text}>
                            <h3>Forgot your password?</h3>
                            <p>No worries, we’ll email you reset instruction.</p>
                        </div>
                        <Row>
                            <Col lg={6}>
                                <div className={style.input_fields_form}>
                                    <FormikControls
                                        variant="border_input"
                                        control="password"
                                        label='Your email address'
                                        name="email"
                                        formik={formik}
                                        placeholder='Enter email address'
                                    />
                                </div>
                            </Col>
                        </Row>
                        <CommonBtn
                            role="btn"
                            className={`black-btn ${style.btnsize}`}
                            title='Send instruction'
                        />
                    </>
                } */}
        </form>
      </section>
    </>
  );
};

export default Password;
