import React, { useState } from "react";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./Modals.module.scss";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import { commonUploadArtValidation } from "../../../../Helper/common";
import { fileUpload } from "../../../../Helper/FileUpload";
import { uploadArtDeatails } from "../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import { toasts } from "../../../Common/Toast/Toast";
import { inputFieldValidation } from "../../../../Constant/formikConstant";
import { FileUploadIcon } from "../../../../Assets/svgIcons/SVGicon";

const UploadArtModal = ({
  show,
  onHide,
  getUploadedArtList,
  uploadArtData,
}) => {
  const [uploadFile, setUploadFile] = useState("");
  const dispatch = useDispatch();

  const initialValue = {
    uploadArt: "",
    name: "",
  };
  const validationSchema = Yup.object({
    uploadArt: commonUploadArtValidation,
    name: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Name should not start or end with a space"
      )
      .max(40, "*Name must be less than 40 characters")
      .required(inputFieldValidation.commonMessage.required("*Name")),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let fileSize = values?.uploadArt.size / 1024 / 1024;
      if (
        fileSize + Number(uploadArtData?.userStorage?.memory_consumed) <=
        Number(uploadArtData?.userStorage?.memory_allocated)
      ) {
        saveArtHandler(values);
      } else {
        toasts.info("Not enough storage.");
        handleOnHide();
      }
    },
  });

  const saveArtHandler = async (values) => {
    const renamedFile = fileUpload?.changeFileNameOnUpload(values?.uploadArt);
    let formData = new FormData();
    formData.append("media_file", renamedFile);
    formData.append("media_name", values.name);

    try {
      let response = await dispatch(uploadArtDeatails(formData));
      if (response.data.status == 200) {
        handleOnHide();
        getUploadedArtList();
      }
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  const handleOnHide = () => {
    formik.resetForm();
    setUploadFile("");
    onHide();
  };

  return (
    <>
      <CommonModal
        className={styles.uploadModal}
        centered
        show={show}
        onHide={handleOnHide}
      >
        <div className={styles.uploadArtInner}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.uploadIput}>
              <label>Upload</label>
              <div className={styles.uploadIput_inner}>
                <input
                  type="file"
                  name="uploadArt"
                  placeholder="upload art"
                  onChange={(e) => {
                    fileUpload.imageUploadBySize(
                      e,
                      formik,
                      setUploadFile,
                      "uploadArt",
                      true
                    );
                  }}
                  onBlur={formik.handleBlur}
                />
                <p>
                  <FileUploadIcon />
                  {formik.values?.uploadArt?.name ? (
                    <>
                      <span title={formik.values?.uploadArt?.name}>
                        {formik.values?.uploadArt?.name}
                      </span>
                    </>
                  ) : (
                    "Upload Art"
                  )}
                </p>
              </div>

              {formik?.touched?.uploadArt && (
                <p className="error_newmsg">{formik?.errors?.uploadArt}</p>
              )}
            </div>

            <FormikControls
              variant="inputArt"
              className="input_border"
              control="input"
              type="input"
              name="name"
              placeholder="Enter Art Name"
              maxLength={40}
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik?.handleChange}
              value={formik.values.name}
              label="Enter Art Name"
            />

            <CommonBtn
              className={` ${styles.submitBtn}`}
              role="btn"
              title="Submit"
              type="submit"
            />
          </form>
        </div>
      </CommonModal>
    </>
  );
};

export default UploadArtModal;
