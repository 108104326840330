import React from "react";
import { Container } from "react-bootstrap";
import { ArrowRight } from "../../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import NAXCanvasLogo from "../../../../Assets/images/NAXCanvasLogo.png";
import NAXCanvasBG from "../../../../Assets/images/NAXCanvasBG.png";
import style from "./NaxCanvas.module.scss";

const NaxCanvas = ({ videoRef, onClick }) => {
  return (
    <>
      <section className={style.naxCanvas}>
        <Container>
          <div className={style.naxCanvas_innerSec}>
            <div className={style.headLogo}>
              <img src={NAXCanvasLogo} alt="logoIcon" />
              <p>Flaunt your Digital Fine Art</p>
            </div>
            <div className={style.mainContaint}>
              <div className={style.videoData}>
                <video
                  ref={videoRef}
                  autoPlay={true}
                  muted
                  loop
                  onClick={onClick}
                  controls={false}
                  width="100%"
                  height="auto"
                >
                  <source
                    src={`${process.env.REACT_APP_IMG_BASE_URL}banner/CanvasNaxvideo.mp4`}
                    // src={HomepageNAXCanvas}
                  />
                </video>
              </div>
              <div className={style.textSec}>
                <p>
                  Flaunting your digital fine art has never been this seamless
                  and elegant.
                </p>
                <p>
                  With NewArtX, you can curate, showcase, and immerse yourself
                  in the world of digital art effortlessly.
                </p>
                <p>
                  Elevate your artistic expression and share your passion with
                  the world through the NewArtX app and the stunning NAXCanvas.
                </p>

                <CommonBtn
                  type="button"
                  role="btn"
                  title="Learn More"
                  icon={<ArrowRight />}
                  className={` ${style.btn_create}`}
                  onClick={() =>
                    window.open("https://naxcanvas.com/", "_blank")
                  }
                />
              </div>
            </div>
          </div>
        </Container>
        <div className={style.canvesBgimg}>
          <img src={NAXCanvasBG} alt="Img-Bg" />
        </div>
      </section>
    </>
  );
};

export default NaxCanvas;
