import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import Auhtorization from "./Component/Auhtorization";
import Password from "./Component/Password";
import style from "./Security.module.scss";

const Security = ({
  GET2FA,
  GetOTP,
  statusEnableDisable,
  resendOtp,
  setResendOtp,
  getOtpButton,
  setGetOtpButton,
  key,
  setStatusEnableDisable,
  setShowInput,
  showInput,
  is_sms_auth_enabled,
  isTimerRunning,
  getOTPSuccess,
  timer,
}) => {
  return (
    <>
      <section className={style.edit_profile}>
        <Tabs
          defaultActiveKey="2Factor"
          id="uncontrolled-tab-example"
          className="tablists"
        >
          <Tab eventKey="2Factor" title="2-Factor Authorization">
            <Auhtorization
              GET2FA={GET2FA}
              GetOTP={GetOTP}
              statusEnableDisable={statusEnableDisable}
              resendOtp={resendOtp}
              setResendOtp={setResendOtp}
              getOtpButton={getOtpButton}
              setGetOtpButton={setGetOtpButton}
              key={key}
              setStatusEnableDisable={setStatusEnableDisable}
              setShowInput={setShowInput}
              showInput={showInput}
              is_sms_auth_enabled={is_sms_auth_enabled}
              isTimerRunning={isTimerRunning}
              getOTPSuccess={getOTPSuccess}
              timer={timer}
            />
          </Tab>
          <Tab eventKey="password" title="Password">
            <Password />
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default Security;
