const imageUpload = async (e, formik, setPreview, name) => {
  var file = e?.target?.files;
  const isvalidExt = imageExtensionValidator(file[0].name);
  if (isvalidExt) {
    let fileSize = file[0].size / 1024 / 1024;
    if (fileSize <= 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (e) => {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          // checkresolution(e?.target?.files)
          // if (checkresolution(e?.target?.files)) {
          setPreview(e.target.result);
          formik.setFieldValue(name, file[0]);
          // } else {
          //   formik.setFieldError("file","File resolution is greater than 1500x1500")
          // }
        };
      };
    } else {
      //setting custom error
      await formik.setFieldTouched(name, true);
      await formik.setFieldError(
        name,
        "Image size Should be less than or equal to 5mb"
      );
      e.target.value = null;
    }
  } else {
    await formik.setFieldTouched(name, true);
    await formik.setFieldError(name, "File must be of type jpeg, jpg , png");

    e.target.value = null;
  }
};

const imageUploadWithPdf = async (e, formik, setPreview, name) => {
  var file = e?.target?.files;
  const isvalidExt = imageExtensionValidatorWithPdf(file[0].name);
  if (isvalidExt) {
    let fileSize = file[0].size / 1024 / 1024;
    if (fileSize <= 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (e) => {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          // checkresolution(e?.target?.files)
          // if (checkresolution(e?.target?.files)) {
          setPreview(e.target.result);
          formik.setFieldValue(name, file[0]);
          // } else {
          //   formik.setFieldError("file","File resolution is greater than 1500x1500")
          // }
        };
      };
    } else {
      //setting custom error
      await formik.setFieldTouched(name, true);
      await formik.setFieldError(
        name,
        "File size Should be less than or equal to 5MB"
      );
      e.target.value = null;
    }
  } else {
    await formik.setFieldTouched(name, true);
    await formik.setFieldError(
      name,
      "File must be of type jpeg, jpg ,png and pdf"
    );

    e.target.value = null;
  }
};

const imageExtensionValidatorWithPdf = (file) => {
  const ext = [".jpg", ".jpeg", ".png", ".JPG", ".pdf"];
  return ext.some((el) => file.endsWith(el));
};

const checkresolution = (file) => {
  let reader = new FileReader();
  reader.readAsDataURL(file[0]);
  reader.onload = (e) => {
    var image = new Image();
    image.src = e.target.result;
    image.onload = function () {
      if (this.height <= 1500 && this.width <= 2000) {
        return true;
      } else {
        return false;
      }
    };
  };
};

const imageUploadBySizee = async (
  e,
  formik,
  setPreview,
  name,
  minSize,
  maxSize,
  height,
  width
) => {
  var file = e?.target?.files;
  const isvalidExt = imageExtensionValidator(file[0].name);
  if (isvalidExt) {
    let fileSize = file[0].size / 1024 / 1024;
    if (fileSize <= maxSize && fileSize >= minSize) {
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (e) => {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          // checkresolution(e?.target?.files)
          // if (checkresolution(e?.target?.files)) {
          setPreview(e.target.result);
          formik.setFieldValue(name, file[0]);
          // } else {
          //   formik.setFieldError("file","File resolution is greater than 1500x1500")
          // }
        };
      };
    } else {
      //setting custom error
      await formik.setFieldTouched(name, true);
      await formik.setFieldError(
        name,
        `*Image size should be less than or equal to ${maxSize}MB`
      );
      setPreview("");

      e.target.value = null;
    }
  } else {
    await formik.setFieldTouched(name, true);
    await formik.setFieldError(name, "*File must be of type jpeg, jpg , png");

    e.target.value = null;
  }
};

const yupValidationImage = (event, formik, setPreview, file) => {
  formik.setFieldValue(file, event.currentTarget.files[0]);

  const isvalidExt = imageExtensionValidator(event.currentTarget.files[0].name);
  if (isvalidExt) {
    let reader = new FileReader();
    reader.readAsDataURL(event.currentTarget.files[0]);
    let fileSize = event.currentTarget.files[0].size / 1024 / 1024;
    if (fileSize <= 5) {
      reader.onload = (e) => {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          setPreview(e.target.result);
        };
      };
    } else {
      setPreview("");
      event.target.value = null;
    }
  } else {
    setPreview("");
    event.target.value = null;
  }
};

const imageExtensionValidator = (file) => {
  const ext = [".jpg", ".jpeg", ".png", ".JPG"];
  return ext.some((el) => file.endsWith(el));
};

const imageGifVideoExtensionValidator = (file) => {
  const ext = [".jpg", ".jpeg", ".png", ".JPG", ".mp4", ".gif"];
  return ext.some((el) => file.endsWith(el));
};

const imageUploadBySize = async (
  e,
  formik,
  setUploadFile,
  name,
  isVideoGif = false
) => {
  var file = e?.target?.files;
  if (file[0].type === "video/mp4") {
    formik.setFieldValue(name, file[0]);
    // Handle video
    // Instead of setting the actual video file, set a dummy image
    // setUploadFile(videoImage);
  } else {
    // Handle image
    const isvalidExt = isVideoGif
      ? imageGifVideoExtensionValidator(file[0].name)
      : imageExtensionValidator(file[0].name);
    formik.setFieldValue(name, file[0]);
    if (isvalidExt) {
      let reader = new FileReader();
      reader.readAsDataURL(e?.target?.files[0]);
      let fileSize = e?.target?.files[0].size / 1024 / 1024;
      if (fileSize <= 5) {
        reader.onload = (e) => {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            setUploadFile(e.target.result);
          };
        };
      } else {
        setUploadFile("");
        e.target.value = null;
      }
    } else {
      setUploadFile("");
      e.target.value = null;
    }
  }
};

const changeFileNameOnUpload = (file) => {
  if (file.type && file?.name) {
    const renamedFile = new File(
      [file],
      `${Date.now()}.${file.name.split(".").pop()}`,
      {
        type: file.type,
      }
    );
    return renamedFile;
  }
};

export const fileUpload = {
  imageUpload,
  imageUploadWithPdf,
  imageUploadBySizee,
  yupValidationImage,
  imageUploadBySize,
  changeFileNameOnUpload,
};
