import React, { useEffect, useState } from "react";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../../../Common/Ui/CommonTable/CommonTable";
import style from "./Accountindex.module.scss";
import {
  formatDate,
  formatDateWithTime,
  getTime,
} from "../../../../../Helper/common";
import {
  ARTIST_PROFILE_REQUEST_STATUS,
  ARTIST_PROFILE_REQUEST_TYPE,
} from "../../../../../Constant/userConstant";
import {
  artistActivityCancel,
  artistActivityRequestCancel,
} from "../../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../../../Common/ConfirmationModal/ConfirmationModal";

const Requestedit = ({ accountDetail, AccountLists }) => {
  const [show, setShow] = useState(false);
  const [singleRequest, setSingleRequest] = useState();
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);

  const handleAccept = (singleRequest) => {
    // call api
    artistRequestCancel(singleRequest);
    setShow(false);
  };

  const artistRequestCancel = async () => {
    let data = {
      artistRequestId: singleRequest.request_id,
    };
    try {
      await dispatch(artistActivityRequestCancel(data));
      AccountLists();
    } catch (err) {}
  };

  return (
    <>
      <section className={style.request_edit}>
        <CommonTable className={style.request_table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Activity</th>
              <th>Edit</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {accountDetail?.map((item) => (
              <tr>
                <td className="dateSec">{formatDate(item.created_at)}</td>
                <td className="timeSec">{getTime(item?.created_at)}</td>
                <td className="activitySec">
                  <strong>
                    {item.request_type == ARTIST_PROFILE_REQUEST_TYPE.NAME
                      ? "Name"
                      : item.request_type == ARTIST_PROFILE_REQUEST_TYPE.EMAIL
                      ? "Email"
                      : item.request_type == ARTIST_PROFILE_REQUEST_TYPE.MOBILE
                      ? "Mobile"
                      : item.request_type == ARTIST_PROFILE_REQUEST_TYPE.BIO ||
                        item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.BIO_PUBLIC
                      ? "Bio"
                      : item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.FACEBOOK_PUBLIC ||
                        item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.TWITTER_PUBLIC ||
                        item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.LINKEDIN_PUBLIC ||
                        item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.INSTAGRAM_PUBLIC
                      ? "Social link"
                      : "User name"}
                  </strong>
                </td>
                <td className="description" title={item.description}>
                  {item.description == null ? "---" : item.description}
                </td>
                <td>
                  {item.status == ARTIST_PROFILE_REQUEST_STATUS.PENDING
                    ? "Under Review"
                    : item.status == ARTIST_PROFILE_REQUEST_STATUS.APPORVED
                    ? "Approved"
                    : item.status == ARTIST_PROFILE_REQUEST_STATUS.REJECTED
                    ? "Rejected"
                    : "Cancelled"}
                </td>
                <td>
                  <CommonBtn
                    role="btn"
                    className="bordred-black btn_size"
                    title="Cancel"
                    onClick={() => {
                      setShow(true);
                      setSingleRequest(item);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          {accountDetail.length == 0 && (
            <tr>
              <td colSpan={9}>
                <p className="no_recordtable">No records found</p>
              </td>
            </tr>
          )}
        </CommonTable>
      </section>

      {show && (
        <ConfirmationModal
          show={show}
          handleClose={handleClose}
          handleAccept={handleAccept}
          actionText="cancel the request"
        />
      )}
    </>
  );
};

export default Requestedit;
