import React, { useEffect, useRef, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DECIMAL } from "../../../Constant/userConstant";
import artImg from "../../../Assets/images/art_collection4.jpg";
import {
  getAllArtist, getAllMarketPlaceNft, getAllSeries, getAllTags, setMarketplaceFilter, setMarketplaceNft, setMarketplacePage, setTotalDfa,
} from "../../../Features/marketplace/marketplaceSlice";
import { checkFileType, getNumberWithComma } from "../../../Helper/common";
import { toasts } from "../../Common/Toast/Toast";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import CommonCard from "../../Common/Ui/CommonCard/CommonCard";
import Commondropdown from "../../Common/Ui/CommonDropdown/Commondropdown";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import HoverVideoPlayer from "react-hover-video-player";
import style from "./NewMarketplace.module.scss";
import { CloseIcon, FilterIcon, SearchIcon, } from "../../../Assets/svgIcons/SVGicon";
import Notfound from "../../Common/NotFound/Notfound";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import Moremodal from "../Marketplace/Moremodal/Moremodal";
import QuickBuyModal from "../../Common/Ui/QuickBuyModal/QuickBuyModal";
import useOutsideClick from "../../../Helper/useOutsideClick";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import { Helmet } from "react-helmet";
import moment from "moment";
import { async } from "q";

const sortOption = [
  { href: "#", title: "Newest", value: "date_desc" },
  { href: "#", title: "Oldest", value: "date_asc" },
  { href: "#", title: "Price- Low to high", value: "price_asc" },
  { href: "#", title: "Price- High to low", value: "price_desc" },
];

const Marketplace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const marketplaceFilter = useSelector(
    (state) => state?.marketplace?.marketplaceFilter
  );
  const marketPlaceNfts = useSelector(
    (state) => state?.marketplace?.marketplaceNft
  );
  const marketplacePage = useSelector((state) => state?.marketplace?.page);
  const totalDfa = useSelector((state) => state?.marketplace?.totalDfa);

  const [searchArtist, setSearchArtist] = useState("");
  const [searchSeries, setSearchSeries] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [searchInType, setSearchInType] = useState("");
  const [searchType, setSearchType] = useState("");

  // const [marketPlaceNfts, setMarketPlaceNfts] = useState([]);
  // const [pageMarketplace, setPageMarketplace] = useState(1);
  const [show, setShow] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showmore, setShowmore] = useState(false);
  const [artistList, setArtistList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [fileterAllList, setFilterAllList] = useState([]);
  const [MarketFilterList, setMarketFilterList] = useState([
    {
      label: "Marketplace",
      title: "Status",
      options: [
        {
          id: 1,
          name: "Available for Primary Sale",
          label: "Available for Primary Sale",
          isInfo: true,
          infoMessage:
            "Exclusive minted Digital Fine Art (DFA) directly from the artists, ensuring authenticity and unique ownership",
        },
        {
          id: 2,
          name: "Available for Secondary Sale",
          label: "Available for Secondary Sale",
          isInfo: true,
          infoMessage:
            "Exclusive authenticated resales of Digital Fine Art (DFA) from other collectors",
        },
        {
          id: 3,
          name: "Accepting Offers",
          label: "Accepting Offers",
          isInfo: true,
          infoMessage: "Accepting Offers",
        },
      ],
    },
    {
      Searchbar: true,
      title: "Artist",
      label: "Artist",
      options: artistList,
    },
    // {
    //   Searchbar: true,
    //   title: "Series",
    //   label: "Series",
    //   options: seriesList,
    // },
    {
      minMaxvalue: true,
      title: "Price",
      label: "Price",
      options: [
        { id: 1, name: "Under ₹ 20,000", label: "Under ₹ 20,000" },
        { id: 2, name: "₹ 20,000 - ₹ 50,000", label: "₹ 20,000 - ₹ 50,000" },
        { id: 3, name: "₹ 50,000 - ₹ 80,000", label: "₹ 50,000 - ₹ 80,000" },
        {
          id: 4,
          name: "₹ 80,000 - ₹ 1,50,000",
          label: "₹ 80,000 - ₹ 1,50,000",
        },
        // { id: 5, name: "₹ 1,50,000 and above", label: "₹ 1,50,000 and above" },
      ],
    },
    // {
    //   title: "Rarity",
    // label:"Marketplace",
    //   options: [
    //     { id: 1, name: "Unique", label: "Unique" },
    //     { id: 2, name: "2-5 Edition", label: "2-5 Edition" },
    //     { id: 3, name: "6-12 Edition", label: "6-12 Edition" },
    //     { id: 4, name: "13-25 Edition", label: "13-25 Edition" },
    //     { id: 5, name: "26-50 Edition", label: "26-50 Edition" },
    //     { id: 6, name: "50 Above Edition", label: "50 Above Edition" },
    //   ],
    // },
    {
      Searchbar: true,
      title: "Type",
      label: "Type",
      options: [
        { id: 1, name: "Dynamic", label: "Dynamic" },
        // { id: 2, name: "Generative", label: "Generative" },
        { id: 3, name: "Standard", label: "Standard" },
      ],
    },
    {
      Searchbar: true,
      title: "Tags",
      label: "Tags",
      options: tagList,
    },
  ]);

  // useEffect to update filter options when artistList, seriesList, or tagList change

  useEffect(() => {
    // Update Artist filter options if artistList is updated
    if (artistList.length > 0) {
      let list = [...MarketFilterList];
      list.forEach((element) => {
        if (element.title == "Artist") {
          element.options = artistList;
        }
      });
      setMarketFilterList(list);
    }
    // Update Series filter options if seriesList is updated
    if (seriesList.length > 0) {
      let list = [...MarketFilterList];
      list.forEach((element) => {
        if (element.title == "Series") {
          element.options = seriesList;
        }
      });
      setMarketFilterList(list);
    }
    // Update Tags filter options if tagList is updated
    if (tagList.length > 0) {
      let list = [...MarketFilterList];
      list.forEach((element) => {
        if (element.title == "Tags") {
          element.options = tagList;
        }
      });
      setMarketFilterList(list);
    }
  }, [artistList, seriesList, tagList]);

  useEffect(() => {
    let assumedRecords = marketplacePage * 20;
    if (
      (marketPlaceNfts.length > assumedRecords ||
        marketPlaceNfts.length <= assumedRecords - 20) &&
      showmore === false
    ) {
      getAllMarketPlaceApi();
    }
  }, [
    marketplacePage,
    marketplaceFilter.artist,
    marketplaceFilter.artType,
    marketplaceFilter.price,
    marketplaceFilter.rarity,
    marketplaceFilter.series,
    marketplaceFilter.tags,
    marketplaceFilter.status,
    marketplaceFilter.optionFilter.value,
    showmore,
  ]);

  // useEffect(() => {
  //   getAllMarketPlaceApi();
  // }, [pageMarketplace]);

  useEffect(() => {
    getAllArtistList();
    // getAllSeriesList();
    getAllTagList();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [marketPlaceNfts]);

  const scrollHandler = () => {
    let isDfaEnd = marketPlaceNfts.length === totalDfa;
    let { scrollHeight } = document.documentElement;
    let threshold = 5; // Adjust threshold if needed

    if (
      window.innerHeight + window.scrollY >= scrollHeight - threshold &&
      !isDfaEnd
    ) {
      dispatch(setMarketplacePage(marketplacePage + 1));
    }
  };

  /**
   * Function to determine the minimum and maximum price values based on selected price options
   * @returns An object containing the minimum and maximum price values
   */
  const getPriceValues = async () => {
    // Initialize an empty object to store the minimum and maximum price values
    let priceMinMax = {};

    // Define an array of price options with their corresponding minimum and maximum values
    let priceOptions = [
      { id: 1, min: 0, max: 20000 },
      { id: 2, min: 20001, max: 50000 },
      { id: 3, min: 50001, max: 80000 },
      { id: 4, min: 80001, max: 150000 },
      { id: 5, min: 150001, max: 150001 },
    ];

    // Check if only one price option is selected
    if (marketplaceFilter.price.length == 1) {
      // Filter the price options array to find the selected option
      let filteredRecord = await priceOptions.filter(
        (option) => option.id === marketplaceFilter.price[0]
      );
      // Assign the minimum and maximum values of the selected option to the priceMinMax object
      priceMinMax = {
        min: filteredRecord[0].min,
        max: filteredRecord[0].max,
      };
      if (filteredRecord[0].min === filteredRecord[0].max) {
        delete priceMinMax.max;
      }
    }
    // Return the object containing the minimum and maximum price values
    return priceMinMax;
  };

  /**
   * Function to determine the minimum and maximum rarity values based on selected rarity options
   * @returns An object containing the minimum and maximum rarity values
   */
  const getPriceRarity = async () => {
    // Initialize an empty object to store the minimum and maximum rarity values
    let rarityMinMax = {};

    // Define an array of rarity options with their corresponding minimum and maximum values
    let rarityOptions = [
      { id: 1, min: 1, max: 1 },
      { id: 2, min: 2, max: 5 },
      { id: 3, min: 6, max: 12 },
      { id: 4, min: 13, max: 25 },
      { id: 5, min: 26, max: 50 },
      { id: 6, min: 51, max: 51 },
    ];

    // Check if only one rarity option is selected
    if (marketplaceFilter.rarity.length == 1) {
      // Filter the rarity options array to find the selected option
      let filteredRecord = await rarityOptions.filter(
        (option) => option.id === marketplaceFilter.rarity[0]
      );
      // Assign the minimum and maximum values of the selected option to the rarityMinMax object
      rarityMinMax = {
        min: filteredRecord[0].min,
        max: filteredRecord[0].max,
      };
    } else {
      // If multiple rarity options are selected, determine the minimum and maximum values
      let recordWithBiggestId = marketplaceFilter.rarity.reduce(
        (prevRecord, currentRecord) => {
          return currentRecord > prevRecord ? currentRecord : prevRecord;
        }
      );
      let recordWithSmallestId = marketplaceFilter.rarity.reduce(
        (prevRecord, currentRecord) => {
          return currentRecord < prevRecord ? currentRecord : prevRecord;
        }
      );

      // Filter the rarity options array to find the options with the smallest and biggest IDs
      let filteredRecordMax = await rarityOptions.filter(
        (option) => option.id === recordWithBiggestId
      );
      let filteredRecordMin = await rarityOptions.filter(
        (option) => option.id === recordWithSmallestId
      );

      // Assign the minimum and maximum values of the selected options to the rarityMinMax object
      rarityMinMax = {
        min: filteredRecordMin[0].min,
        max: filteredRecordMax[0].max,
      };
    }

    // Return the object containing the minimum and maximum rarity values
    return rarityMinMax;
  };

  const getMarketStatus = () => {
    let marketMap = {
      1: "primary",
      2: "secondary",
      3: "offer",

    };

    return marketplaceFilter.status.map((statusId) => marketMap[statusId]);

    // return market[marketplaceFilter.status[0]];
  };

  const stringify = (data) => {
    return JSON.stringify(data);
  };

  const getAllMarketPlaceApi = async () => {
    let rarityMinMax = {};
    let priceMinMax = {};
    if (marketplaceFilter.price.length > 0) {
      priceMinMax = await getPriceValues();
    }
    if (marketplaceFilter.price.length == 0) {
      //for input minmax value
      if (Object.keys(marketplaceFilter.priceInputValue).length > 0) {
        priceMinMax = {
          min: Number(marketplaceFilter.priceInputValue.minVal),
          max: Number(marketplaceFilter.priceInputValue.maxVal),
        };
      }
    }
    if (marketplaceFilter.rarity.length > 0) {
      rarityMinMax = await getPriceRarity();
    }
    let params = {
      size: 20,
      page: marketplacePage,
      series_id: stringify(marketplaceFilter.series),
      // no_of_editions: rarityMinMax, //rarity
      tags: stringify(marketplaceFilter.tags),
      price_value: stringify(priceMinMax),
      artist_ids: stringify(marketplaceFilter.artist),
      art_type: stringify(marketplaceFilter.artType),
      // market:getMarketStatus(),
      market: JSON.stringify(getMarketStatus()),
      sort: marketplaceFilter.optionFilter.value,
    };
    try {
      let response = await dispatch(getAllMarketPlaceNft(params));
      await dispatch(
        setMarketplaceNft([...marketPlaceNfts, ...response.data.data.rows])
      );
      dispatch(setTotalDfa(response.data.data.totalItems));
    } catch (err) { }
  };

  const getAllArtistList = async () => {
    try {
      let response = await dispatch(getAllArtist());
      setArtistList(response.data.data);
    } catch (err) { }
  };

  const getAllSeriesList = async () => {
    try {
      let response = await dispatch(getAllSeries());
      setSeriesList(response.data.data);
    } catch (err) { }
  };

  const getAllTagList = async () => {
    try {
      let response = await dispatch(getAllTags());
      setTagList(response.data.data);
    } catch (err) { }
  };

  const handalClick = () => {
    setToggleMenu(!toggleMenu);
  };

  const onCheckUncheckFilter = (e, item, id) => {
    window.scrollTo(0, 0);
    dispatch(setMarketplacePage(1));

    let isChecked = e.target.checked;
    switch (item.title) {
      case "Status": {
        // let statusItem = [...marketplaceFilter.status];
        // if (id !== statusItem[0]) {
        //   // statusItem.pop(); // remove the element is just for we have should the only one checkbox checked at a one time
        //   statusItem.push(id);
        //   dispatch(setMarketplaceNft([]));
        //   dispatch(setMarketplaceFilter({ type: "status", data: statusItem }));
        // }
        // break;

        let statusItem = [...marketplaceFilter.status];
        if (isChecked) {
          if (!statusItem.includes(id)) {
            statusItem.push(id); // Add the new status to the array
          }
        } else {
          const index = statusItem.indexOf(id);
          if (index !== -1) {
            statusItem.splice(index, 1); // Remove the status from the array
          }
        }

        dispatch(setMarketplaceNft([]));
        dispatch(setMarketplaceFilter({ type: "status", data: statusItem }));
        break;

      }
      case "Artist": {

        dispatch(setMarketplaceNft([]));
        let artistItem = [...marketplaceFilter.artist];
        if (isChecked) {
          artistItem.push(id);
          dispatch(setMarketplaceFilter({ type: "artist", data: artistItem }));
        } else {
          const indexed = artistItem.indexOf(id);
          if (indexed !== -1) {
            artistItem.splice(indexed, 1);
          }
          dispatch(setMarketplaceFilter({ type: "artist", data: artistItem }));
        }
        break;
      }
      case "Series": {
        dispatch(setMarketplaceNft([]));
        let seriesItem = [...marketplaceFilter.series];
        if (isChecked) {
          seriesItem.push(id);
          dispatch(setMarketplaceFilter({ type: "series", data: seriesItem }));
        } else {
          const indexed = seriesItem.indexOf(id);
          if (indexed !== -1) {
            seriesItem.splice(indexed, 1);
          }
          dispatch(setMarketplaceFilter({ type: "series", data: seriesItem }));
        }
        break;
      }
      case "Price": {
        dispatch(
          setMarketplaceFilter({
            type: "priceInputValue",
            data: { minVal: "", maxVal: "" },
          })
        );
        let priceItem = [...marketplaceFilter.price];
        if (id !== priceItem[0]) {
          priceItem.pop();
          priceItem.push(id);
          dispatch(setMarketplaceNft([]));
          dispatch(setMarketplaceFilter({ type: "price", data: priceItem }));
        } else {
          priceItem.pop();
          dispatch(setMarketplaceNft([]));
          dispatch(setMarketplaceFilter({ type: "price", data: priceItem }));
        }
        break;
      }
      case "Rarity": {
        dispatch(setMarketplaceNft([]));
        let rarityItem = [...marketplaceFilter.rarity];
        if (isChecked) {
          rarityItem.push(id);
          dispatch(setMarketplaceFilter({ type: "rarity", data: rarityItem }));
        } else {
          const indexed = rarityItem.indexOf(id);
          if (indexed !== -1) {
            rarityItem.splice(indexed, 1);
          }
          dispatch(setMarketplaceFilter({ type: "rarity", data: rarityItem }));
        }
        break;
      }

      case "Type": {
        dispatch(setMarketplaceNft([]));
        let typeItem = [...marketplaceFilter.artType];

        if (isChecked) {
          typeItem.push(id);
          // setType(typeItem);
          dispatch(setMarketplaceFilter({ type: "artType", data: typeItem }));
        } else {
          const indexed = typeItem.indexOf(id);
          if (indexed !== -1) {
            typeItem.splice(indexed, 1);
          }
          // setType(typeItem);
          dispatch(setMarketplaceFilter({ type: "artType", data: typeItem }));
        }
        break;
      }
      case "Tags": {
        dispatch(setMarketplaceNft([]));
        let tagsItem = [...marketplaceFilter.tags];
        if (isChecked) {
          tagsItem.push(id);
          dispatch(setMarketplaceFilter({ type: "tags", data: tagsItem }));
        } else {
          const indexed = tagsItem.indexOf(id);
          if (indexed !== -1) {
            tagsItem.splice(indexed, 1);
          }
          dispatch(setMarketplaceFilter({ type: "tags", data: tagsItem }));
        }
        break;
      }
    }
  };
  const getCheckedStatus = (item, id) => {
    switch (item.title) {
      case "Status": {
        if (marketplaceFilter.status.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Artist": {
        if (marketplaceFilter.artist.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Series": {
        if (marketplaceFilter.series.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Price": {
        if (marketplaceFilter.price.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Rarity": {
        if (marketplaceFilter.rarity.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Type": {
        if (marketplaceFilter.artType.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Tags": {
        if (marketplaceFilter.tags.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const setSearchByType = (e, type) => {
    let searchText = e.target.value;
    setSearchType(type);
    switch (type) {
      case "Artist": {
        setSearchArtist(searchText);
        break;
      }
      case "Series": {
        setSearchSeries(searchText);
        break;
      }
      case "Type": {
        setSearchInType(searchText);
        break;
      }
      case "Tags": {
        setSearchTag(searchText);
        break;
      }
    }
  };

  const filteredData = (data, type) => {
    switch (type) {
      case "Artist": {
        let filterData = data.filter(
          (artist) =>
            artist.name?.toLowerCase().includes(searchArtist.toLowerCase())
          /* ||
                      artist.first_name
                        ?.toLowerCase()
                        .includes(searchArtist.toLowerCase()) ||
                      artist.last_name?.toLowerCase().includes(searchArtist.toLowerCase()) */
        );
        return filterData.slice(0, 4);
      }
      case "Series": {
        let filterData = data.filter((series) =>
          series.name.toLowerCase().includes(searchSeries.toLowerCase())
        );
        return filterData.slice(0, 4);
      }
      case "Type": {
        let filterData = data.filter((type) =>
          type.name.toLowerCase().includes(searchInType.toLowerCase())
        );
        return filterData.slice(0, 4);
      }
      case "Tags": {
        let filterData = data.filter((tags) =>
          tags.tag_name.toLowerCase().includes(searchTag.toLowerCase())
        );
        return filterData.slice(0, 4);
      }
      case "Price": {
        return data;
      }
      case "Rarity": {
        return data;
      }
      case "Status": {
        return data;
      }
    }
  };

  const showAllListItem = (type) => {
    setSearchType(type);
    setShowmore(true);

    switch (type) {
      case "Artist": {
        setFilterAllList(artistList);
        break;
      }
      case "Series": {
        setFilterAllList(seriesList);
        break;
      }

      case "Tags": {
        setFilterAllList(tagList);
        break;
      }
    }
  };

  const getRecordsAsperMinMax = async () => {
    if (
      Number(marketplaceFilter.priceInputValue.minVal) <=
      Number(marketplaceFilter.priceInputValue.maxVal)
    ) {
      dispatch(setMarketplacePage(1));
      dispatch(setMarketplaceNft([]));
      dispatch(setMarketplaceFilter({ type: "price", data: [] }));
      // getAllMarketPlaceApi();
    } else {
      toasts.error("Max value should greater than or equal to min value");
    }
  };

  const clearSearchTextFxn = (type) => {
    switch (type) {
      case "Artist": {
        setSearchArtist("");
        break;
      }
      case "Series": {
        setSearchSeries("");
        break;
      }
      case "Type": {
        setSearchInType("");
        break;
      }
      case "Tags": {
        setSearchTag("");
        break;
      }
    }
  };

  // const getEdtionInfoRender = (item) => {
  //   if (getMarketStatus() === "primary" && item?.drop_type === 4) {
  //     return `Open Edition`;
  //   } else if (
  //     (getMarketStatus() === "secondary" || getMarketStatus() === "offer") &&
  //     item?.drop_type === 4
  //   ) {
  //     return `Edition Of ${getNumberWithComma(item?.sold_no_of_editions)}`;
  //   } else {
  //     return `Edition Of ${getNumberWithComma(item?.no_of_editions)}`;
  //   }
  // };

  const getEdtionInfoRender = (item, edition) => {
    const marketStatuses = getMarketStatus(); // Now an array

    if (marketStatuses.includes("primary") && item?.drop_type === 4) {
      return `Open Edition`;
    } else if (
      (marketStatuses.includes("secondary") || marketStatuses.includes("offer")) &&
      item?.drop_type === 4
    ) {
      return `Edition Of ${getNumberWithComma(item?.sold_no_of_editions)}`;
    } else {
      return `Edition Of ${getNumberWithComma(item?.no_of_editions)}`;
    }
  };

  // const getPriceToRender = (item) => {
  //   if (getMarketStatus() === "primary") {
  //     return (
  //       <p>
  //         Price :{" "}
  //         <strong>
  //           ₹ {`${getNumberWithComma(Number(item?.price) / DECIMAL)}`}
  //         </strong>
  //       </p>
  //     );
  //   } else if (getMarketStatus() === "secondary") {
  //     return (
  //       <p>
  //         Floor Price :{" "}
  //         <strong>
  //           ₹{" "}
  //           {`${getNumberWithComma(Number(item?.min_selling_price) / DECIMAL)}`}
  //         </strong>
  //       </p>
  //     );
  //   } else {
  //     return " ";
  //   }
  // };

  const getPriceToRender = (item) => {
    const marketStatuses = getMarketStatus(); // Array of statuses

    if (marketStatuses.includes("primary")) {
      return (
        <p>
          Price :{" "}
          <strong>
            ₹ {`${getNumberWithComma(Number(item?.price) / DECIMAL)}`}
          </strong>
        </p>
      );
    } else if (marketStatuses.includes("secondary")) {
      return (
        <p>
          {"hhhhhhhh"} :{" "}
          <strong>
            ₹{" "}
            {`${getNumberWithComma(Number(item?.floor_price) / DECIMAL)}`}
          </strong>
        </p>
      );
    } else {
      return " ";
    }
  };


  const getUsernameToRender = (item) => {
    if (marketplaceFilter.status[0] === 1) {
      return `@${item?.art_user?.username}`;
    } else {
      return `@${item?.username}`;
    }
  };

  const getArtTypeHandler = (item) => {
    let artIdn = item?.art_type_id ?? item?.art_type

    // let artId = 
    //   marketplaceFilter?.status?.[0] === 1 || marketplaceFilter?.status?.[0] === 4 ? artIdn : item?.art_type;
    switch (artIdn) {
      case 1:
        return "DYNAMIC";
      // case 2: 
      //   return "GENERATIVE";
      // case 3:
      //   return "";
      // case 4:
      //   return "RESPONSIVE";
      default:
        return "";
    }
  };

  const navigationOfArtHandler = (item) => {
    // if (marketplaceFilter.status[0] === 1) {
    navigate(`${RoutesUrls.digitalFineArts}/${item?.id}`);
    // } else {
    // navigate(`/digital-fine-arts/${item?.art_id}`);
    // }
  };

  const getRemainingTime = (endTime) => {
    const currentTime = Date.now();
    const remainingTime = endTime - currentTime;

    if (remainingTime <= 0) {
      return "";
    }

    const duration = moment.duration(remainingTime, "milliseconds");

    if (duration.asDays() >= 1) {
      return `${Math.floor(duration.asDays())} Days`;
    }
    else if (duration.asHours() >= 1) {
      return `${Math.floor(duration.asHours())} Hrs`;
    } else if (duration.asMinutes() >= 1) {
      return `${Math.floor(duration.asMinutes())} Min`;
    } else {
      return `${Math.floor(duration.asSeconds())} Sec`;
    }
  };

  const getArtTypeName = (item) => {
    switch (item.drop_type) {
      case 1: {
        let priceLabel = item.market == "PRIMARY" ? "Min. Eligible Bid" : "Floor Price"
        let editionLabel = item.market == "PRIMARY" ? "" : "Edition of"
        return {
          dropType: "Auction",
          label: priceLabel,
          editionLabel,
          editionNo: getNumberWithComma(item?.no_of_editions)
        }
      }
      case 2: {
        let priceLabel = item.market == "PRIMARY" ? "Min. Eligible Bid" : "Floor Price"
        return {
          dropType: "Ranked Auction",
          label: priceLabel,
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions)
        }
      }
      case 3: {
        return {
          dropType: "First Come First Buy",
          label: "Floor Price",
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions)
        }
      }
      case 4: {
        let editionLabel = item.market == "PRIMARY" ? "Editions Released:" : "Edition of"
        return {
          dropType: "Open Edition",
          label: "Floor Price",
          editionLabel,
          editionNo: item?.sold_no_of_editions > 0 ? item.sold_no_of_editions : '--'
        }
      }
      case 5: {
        let priceLabel = item.market == "PRIMARY" ? "Entry Price" : "Floor Price"
        return {
          dropType: "Raffle",
          label: priceLabel,
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions)
        }
      }
      default:
        return {
          dropType: "",
          label: "Floor Price",
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions)

        }
    }
  }


  const getImageSection = (item) => {
    // console.log("hjfjsgdhjgsdf", typeof item?.sold_no_of_editions);
    let type = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    let artMetaData = getArtTypeName(item)
    if (type == "MP4" || type == "OGG") {
      return (
        <HoverVideoPlayer
          loop={true}
          restartOnPaused
          unloadVideoOnPaused={true}
          disableRemotePlayback={false}
          paused={true}
          muted={true}
          onClick={() => navigationOfArtHandler(item)}
          className={`video_hover_sec ${style.image_sec}`}
          videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          pausedOverlay={
            <CommonCard
              className={style.cardmarket}
              imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
              to={item.to}
              title={item.art_name}
              // subtitle={getUsernameToRender(item)}
              subtitle={`@${item?.art_user?.username}`}
              bottomclass="align-items-center"
              // bottomTitle={getPriceToRender}
              // bottomSubTitle={getEdtionInfoRender(item,artMetaData.editionLabel)}
              isMarketPlace={true}
              // quickbuy={
              //   item?.user_id != userId &&
              //   marketplaceFilter.isSale != 0 &&
              //   "Quick Buy"
              // }
              //   onClickbuy={(e) => {
              //     e.stopPropagation();
              //     if (isUserLoggedin) {
              //       dispatch(
              //         saveLatestPrice({
              //           price: Number(item.min_selling_price) / DECIMAL,
              //           market: "secondary",
              //           quantity: 1,
              //           editionNumber: item.edition_number,
              //         })
              //       );
              //       navigate(`/checkout/art/${item.id}`);
              //     } else {
              //       dispatch(saveNextRoute(RoutesUrls.Marketplace));
              //       navigate("/login");
              //     }
              //   }} 
              tagTitile={getArtTypeHandler(item)}
              item={item}
              onClick={() => navigationOfArtHandler(item)}
              livedata={item.market}
              endData={item.drop_type != 3 ? getRemainingTime(item.end_time) : ""}
              artTitle={getArtTypeName(item)}
              // getArtTypeName={getArtTypeName(item?.drop_type, item?.market)}
              label={artMetaData.label}
              price={item?.floor_price}
              editionLabel={artMetaData.editionLabel}
              editionNo={artMetaData.editionNo}
            />
          }
        />
      );
    } else {
      return (
        <CommonCard
          className={style.cardmarket}
          imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
          featureUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          to={item.to}
          title={item.art_name}
          // subtitle={getUsernameToRender(item)}
          subtitle={`@${item?.art_user?.username}`}
          bottomclass="align-items-center"
          livedata={item.market}
          // bottomTitle={getPriceToRender}
          // bottomTitle={getPriceToRender(item)}

          // bottomSubTitle={getEdtionInfoRender(item)}
          isMarketPlace={true}
          // quickbuy={
          //   item?.user_id != userId &&
          //   marketplaceFilter.isSale != 0 &&
          //   "Quick Buy"
          // }
          // onClickbuy={(e) => {
          //     e.stopPropagation();
          //     if (isUserLoggedin) {
          //         dispatch(
          //             saveLatestPrice({
          //                 price: Number(item.min_selling_price) / DECIMAL,
          //                 market: "secondary",
          //                 quantity: 1,
          //                 editionNumber: item.edition_number,
          //             })
          //         );
          //         navigate(`/checkout/art/${item.id}`);
          //     } else {
          //         dispatch(saveNextRoute(RoutesUrls.Marketplace));
          //         navigate("/login");
          //     }
          // }}
          tagTitile={getArtTypeHandler(item)}
          item={item}
          onClick={() => navigationOfArtHandler(item)}
          endData={item.drop_type != 3 ? getRemainingTime(item.end_time) : ""}
          artTitle={getArtTypeName(item)}
          // getArtTypeName={getArtTypeName(item?.drop_type, item?.market)}
          label={artMetaData.label}
          price={item?.floor_price}
          editionLabel={artMetaData.editionLabel}
          editionNo={artMetaData.editionNo}

        />
      );
    }
  };
  const filterBar = useRef();
  useOutsideClick(filterBar, () => setToggleMenu(false));

  return (
    <>
      <section className={style.marketPlace}>
        <Helmet>
          <title>Marketplace | Buy and Sell Digital fine Art | NewArtX</title>
          <meta
            name="description"
            content="Visit NewArtX to explore a world of excellent Digital Fine Art. View, purchase, and sell original NFT artwork created by gifted artists. Invest in the digital art of the future and discover new areas of creativity enabled by blockchain technology."
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Marketplace | Buy and Sell Digital fine Art | NewArtX"
          />
          <meta
            property="og:url"
            content="https://www.newartx.com/marketplace"
          />
          <meta
            property="og:image"
            content="https://www.newartx.com/static/media/Nax%20Canvas.1496f78d0023808b36e5.png"
          />
          <meta
            property="og:description"
            content="Visit NewArtX to explore a world of excellent Digital Fine Art. View, purchase, and sell original NFT artwork created by gifted artists. Invest in the digital art of the future and discover new areas of creativity enabled by blockchain technology."
          />
          <link rel="canonical" href="https://www.newartx.com/marketplace" />
        </Helmet>
        <Container>
          <div className={style.marketPlace_innerPart}>
            <div className={style.topHead}>
              <h1 className={`smallText ${style.headings}`}>
                Digital Fine Art
              </h1>
              <div className={style.rightText}>
                {totalDfa > 0 && <h2>{totalDfa} DFA</h2>}
                <div className={style.filterSection}>
                  <div className={style.arttoggleBtn}>
                    {toggleMenu ? (
                      <div className={style.btnFilter}>
                        <FilterIcon />
                      </div>
                    ) : (
                      <button onClick={handalClick}>
                        <FilterIcon />
                      </button>
                    )}
                  </div>
                  <Commondropdown
                    dropdowntitle={
                      marketplaceFilter.optionFilter.title == ""
                        ? "Sort By"
                        : marketplaceFilter.optionFilter.title
                    }
                    classdrop={style.dropFilter}
                  >
                    {sortOption.map((data) => (
                      <Dropdown.Item
                        href={data.href}
                        onClick={() => {
                          if (
                            data.value !== marketplaceFilter.optionFilter.value
                          ) {
                            dispatch(setMarketplaceNft([]));
                            dispatch(setMarketplacePage(1));
                          }
                          dispatch(
                            setMarketplaceFilter({
                              type: "optionFilter",
                              data: {
                                title: data.title,
                                value: data.value,
                              },
                            })
                          );
                        }}
                      >
                        {data.title}
                      </Dropdown.Item>
                    ))}
                  </Commondropdown>
                </div>
              </div>
            </div>
            <div className={style.mainContaint}>
              <div
                className={`sidefilter ${toggleMenu ? "show" : "hide"} ${style.leftFilter
                  }`}
                ref={filterBar}
              >
                <form>
                  <ul>
                    {MarketFilterList.map((item, index) => (
                      <li>
                        <div className={style.vaule_selected}>
                          <div className={style.serch_head}>
                            <h5>{item.label}</h5>
                            {item.Searchbar && (
                              <div className={style.serch_value}>
                                <FormikControls
                                  variant={style.searchBar}
                                  rightIcon={
                                    (item.title == "Artist" &&
                                      searchArtist == "") ||
                                      (item.title == "Series" &&
                                        searchSeries == "") ||
                                      (item.title == "Tags" && searchTag == "") ||
                                      (item.title == "Type" &&
                                        searchInType == "") ? (
                                      <SearchIcon />
                                    ) : (item.title == "Artist" &&
                                      searchArtist !== "") ||
                                      (item.title == "Series" &&
                                        searchSeries !== "") ||
                                      (item.title == "Tags" &&
                                        searchTag !== "") ||
                                      (item.title == "Type" &&
                                        searchInType !== "") ? (
                                      <CloseIcon />
                                    ) : (
                                      ""
                                    )
                                  }
                                  control="input"
                                  type="text"
                                  name="search"
                                  rightIconClass={
                                    (item.title == "Artist" &&
                                      !(searchArtist == "")) ||
                                      (item.title == "Series" &&
                                        !(searchSeries == "")) ||
                                      (item.title == "Tags" &&
                                        !(searchTag == "")) ||
                                      (item.title == "Type" &&
                                        !(searchInType == ""))
                                      ? style.clickable
                                      : ""
                                  }
                                  rightIconClick={() => {
                                    if (
                                      (item.title == "Artist" &&
                                        searchArtist !== "") ||
                                      (item.title == "Series" &&
                                        searchSeries !== "") ||
                                      (item.title == "Tags" &&
                                        searchTag !== "") ||
                                      (item.title == "Type" &&
                                        searchInType !== "")
                                    ) {
                                      clearSearchTextFxn(item.title);
                                    }
                                  }}
                                  placeholder={`Search ${item.title}`}
                                  onChange={(e) =>
                                    setSearchByType(e, item.title)
                                  }
                                  value={
                                    item.title == "Artist"
                                      ? searchArtist
                                      : item.title == "Series"
                                        ? searchSeries
                                        : item.title == "Tags"
                                          ? searchTag
                                          : searchInType
                                  }
                                />
                              </div>
                            )}
                          </div>
                          {filteredData(item.options, item.title).map(
                            (data, index) => (
                              <FormikControls
                                key={index}
                                className="fieldBlack checkInfoBtn"
                                control="checkbox"
                                label={
                                  item.title == "Artist"
                                    ? data.name
                                    : item.title == "Series"
                                      ? data.name
                                      : item.title == "Series"
                                        ? data.name
                                        : item.title == "Tags"
                                          ? data.tag_name
                                          : data.label
                                }
                                checked={getCheckedStatus(
                                  item,
                                  item.title == "Tags" ? data.tag_name : data.id
                                )}
                                onChange={(e) => {
                                  onCheckUncheckFilter(
                                    e,
                                    item,
                                    item.title == "Tags"
                                      ? data.tag_name
                                      : data.id
                                  );
                                }}
                                isInfo={data.isInfo}
                                infoMessage={data.infoMessage}
                              />
                            )
                          )}

                          {filteredData(item.options, item.title).length ==
                            0 && <p>No records found</p>}

                          {((item.title == "Artist" && searchArtist == "") ||
                            (item.title == "Series" && searchSeries == "") ||
                            (item.title == "Tags" && searchTag == "") ||
                            (item.title == "Type" && searchInType == "")) &&
                            item?.options.length > 4 && (
                              <CommonBtn
                                className={`bg-border-without ${style.morebtn}`}
                                role="btn"
                                title={
                                  <>
                                    <span>+</span> {item?.options.length - 4}{" "}
                                    more
                                  </>
                                }
                                type="button"
                                onClick={() => showAllListItem(item?.title)}
                              />
                            )}
                        </div>
                        {item.minMaxvalue && (
                          <div className={style.min_max_value}>
                            <FormikControls
                              key={index}
                              className={style.borderform}
                              control="input"
                              type="text"
                              name="minvalue"
                              id="minvalue"
                              placeholder="Min."
                              value={marketplaceFilter.priceInputValue.minVal}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value)) {
                                  dispatch(
                                    setMarketplaceFilter({
                                      type: "priceInputValue",
                                      data: {
                                        ...marketplaceFilter.priceInputValue,
                                        minVal: value,
                                      },
                                    })
                                  );
                                }
                              }}
                            />
                            <FormikControls
                              key={index}
                              className={style.borderform}
                              control="input"
                              name="maxvalue"
                              type="text"
                              id="maxvalue"
                              placeholder="Max."
                              value={marketplaceFilter.priceInputValue.maxVal}
                              onChange={(e) => {
                                //regex for only digit
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value)) {
                                  dispatch(
                                    setMarketplaceFilter({
                                      type: "priceInputValue",
                                      data: {
                                        ...marketplaceFilter.priceInputValue,
                                        maxVal: value,
                                      },
                                    })
                                  );
                                }
                              }}
                            />
                            <CommonBtn
                              className={`black-btn ${style.btngo}`}
                              role="btn"
                              title="GO"
                              type="button"
                              disabled={
                                (marketplaceFilter?.priceInputValue?.minVal ==
                                  0 ||
                                  marketplaceFilter?.priceInputValue?.minVal ==
                                  "") &&
                                (marketplaceFilter?.priceInputValue?.maxVal ==
                                  0 ||
                                  marketplaceFilter?.priceInputValue?.maxVal ==
                                  "")
                              }
                              onClick={() => getRecordsAsperMinMax()}
                            />
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </form>
              </div>
              <div className={style.rightCards}>
                <div className={style.cardRow}>
                  {marketPlaceNfts.map((item, index) => (
                    <div className={style.cardBox}>{getImageSection(item)}</div>
                  ))}
                </div>
                {marketPlaceNfts.length === 0 && (
                  <Notfound
                    className={style.marketNotdata}
                    text={"No digital art found."}
                  />
                )}
                {/* {totalPages > 1 && (
                  <CustomPagination
                    className="ms-auto"
                    activePage={pageMarketplace}
                    totalPage={totalPages}
                    setSearchParams={setSearchParams}
                    setActivePage={setPageMarketplace}
                    maxVisiblePages={5}
                  />
                )} */}
              </div>
            </div>
          </div>
        </Container>
      </section>

      <QuickBuyModal
        show={show}
        onHide={() => setShow(false)}
        artImg={artImg}
        creatorFees="xhsdbssdfj"
        processingFees={"fkgfakdf"}
        texas={"gfsgdf"}
        totalAmount={"NXJSKWK"}
        artistName="@artistname"
        edition={1}
        totalEditions={5}
      />
      {/* {toggleMenu && (
        <div className={style.ovarlay} onClick={() => setToggleMenu(false)} />
      )} */}
      {showmore && (
        <Moremodal
          show={showmore}
          data={fileterAllList}
          onHide={() => {
            dispatch(setMarketplacePage(1));
            dispatch(setMarketplaceNft([]));
            setShowmore(false);
          }}
          searchType={searchType}
          getCheckedStatus={getCheckedStatus}
          artist={marketplaceFilter.artist}
          tag={marketplaceFilter.tags}
          series={marketplaceFilter.series}
        />
      )}
    </>
  );
};

export default Marketplace;
