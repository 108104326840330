import React, { useEffect, useState } from "react";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import Banner from "../../../Assets/images/profile-bg.png";
import CarddetailsOtherUser from "./Carddetails/CarddetailOtherUser";
import { Container, Nav, Tab, Tabs } from "react-bootstrap";
import Followers from "./Followers/Followers";
import Following from "./Following/Following";
import Like from "./Like/Like";
import Artownedsec from "./Artowned/Artownedsec";
import {
  getUserProfile,
  getUserArtFollowersLikes,
  followUnfollowAndRemove,
  getUserLikesData,
  incremenentViews,
  setPageOtherUserProfile,
  setTabTypeOtherUserProfile,
  saveUserProfileOther,
  likeUnlikeArt,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPARISION_CONSTANT_NAMES,
  PER_PAGE_RECORD_LIMIT,
} from "../../../Constant/userConstant";
import { useDebounce } from "../../../customHooks/useDebounce";
import { useNavigate, useParams } from "react-router-dom";
import { getArts } from "../../../Features/drop/dropSlice";
import ArtistDigitalArtOtherProfile from "../../Pages/Userdashboard/Activedigitalart/ArtistDigitalArtOtherProfile";
import { useLocation } from "react-router-dom";
import style from "./Userprofile.module.scss";
import CoverImage from "../../../Assets/images/User-Cover.png";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import { Helmet } from "react-helmet";
import { SearchIcon } from "../../../Assets/svgIcons/SVGicon";

const limitOption = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
];

const Otheruserprofile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username } = useParams();
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [likes, setLikes] = useState([]);
  const [artOwned, setArtOwned] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState("");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(limitOption[0]);

  const debouncedSearchTerm = useDebounce(search, 500);
  const otherUserProfile = useSelector(
    (state) => state?.users?.profileDataOther
  );
  const userProfile = useSelector((state) => state?.users?.profileData);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryTab = params.get("tab");

  const pageOtherUserProfile = useSelector(
    (state) => state.users.pageOtherUserProfile
  );
  const tabTypeOtherUserProfile = useSelector(
    (state) => state.users.tabTypeOtherUserProfile
  );

  const tabTypeUserProfile = useSelector(
    (state) => state.users.tabTypeUserProfile
  );

  useEffect(() => {
    // incremenentViewForUsers();
    getUserPersonalDetails();
    return () => {
      dispatch(saveUserProfileOther({}));
    };
  }, [username, ArtistId]);

  useEffect(() => {
    if (queryTab === "ART_CREATED") {
      // setTabType(queryTab);
      dispatch(setTabTypeOtherUserProfile(queryTab));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userProfile)) {
      if (userProfile?.username == username) {
        navigate(RoutesUrls.User_Profile);
      }
    }
  }, [userProfile, otherUserProfile]);

  const incremenentViewForUsers = async () => {
    try {
      let data = {
        id: otherUserProfile.id,
      };
      let response = await dispatch(incremenentViews(data));
      if (response.status == 200) {
        getUserPersonalDetails();
      }
    } catch (err) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (
      (debouncedSearchTerm.length == COMPARISION_CONSTANT_NAMES.ZERO ||
        debouncedSearchTerm.length >= COMPARISION_CONSTANT_NAMES.THREE) &&
      otherUserProfile.id
    ) {
      if (
        tabTypeOtherUserProfile == "LIKES" ||
        tabTypeOtherUserProfile == "ART_OWN"
      ) {
        getUserLikesArtOwn();
      } else if (tabTypeOtherUserProfile == "ART_CREATED") {
        getUserCreatedArt();
      } else {
        getUserArtFollowersLikesAPI();
      }
    }
  }, [
    tabTypeOtherUserProfile,
    pageOtherUserProfile,
    debouncedSearchTerm,
    otherUserProfile.id,
    ArtistId,
    limit.value,
  ]);

  const getUserCreatedArt = async () => {
    let data = {
      id: otherUserProfile.id,
      size: limit.value,
      page: pageOtherUserProfile,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getArts(data));
      if (response) {
        let record = response.data.data;

        setTotalPage(record.totalPages);
        setTotalItems(record?.totalItems);
      }
    } catch (err) {}
  };

  const getUserLikesArtOwn = async () => {
    let data = {
      id: otherUserProfile.id,
      filter: tabTypeOtherUserProfile,
      page: search !== "" ? 1 : pageOtherUserProfile,
      size: limit.value,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }
    try {
      let response = await dispatch(getUserLikesData(data));
      if (response) {
        let record = response.data.data;

        setTotalPage(record.totalPages);
        setTotalItems(record?.totalItems);
        if (tabTypeOtherUserProfile == "ART_OWN") {
          setArtOwned(record.data);
        }
        if (tabTypeOtherUserProfile == "LIKES") {
          setLikes(record.data);
        }
      }
    } catch (err) {}
  };

  const getUserArtFollowersLikesAPI = async () => {
    let data = {
      id: otherUserProfile.id,
      filter: tabTypeOtherUserProfile,
      page: search !== "" ? 1 : pageOtherUserProfile,
      size: limit.value,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getUserArtFollowersLikes(data));
      if (response) {
        let record = response.data.data;
        setTotalPage(record.totalPages);
        setTotalItems(record?.totalItems);
        if (tabTypeOtherUserProfile == "FOLLOWING") {
          setFollowing(record.data);
        }
        if (tabTypeOtherUserProfile == "FOLLOWERS") {
          setFollowers(record.data);
        }
      }
    } catch (err) {}
  };

  const getUserPersonalDetails = async () => {
    try {
      let data = {
        username: username,
      };
      await dispatch(getUserProfile(data));
    } catch (err) {
      navigate(-1);
    }
  };
  const onTabChange = (e) => {
    setSearch("");
    dispatch(setPageOtherUserProfile(1));
    setTotalPage(0);
    dispatch(setTabTypeOtherUserProfile(e));
    setLimit(limitOption[0]);
  };

  const followUnfollowAndRemoveApi = async (type, data) => {
    // type=0 (UNFOLLOW) , 1 ( FOLLOW) , 2 (REMOVE)
    if (isUserLoggedin) {
      let record = {
        type: type == "remove" ? 2 : type == "follow" ? 1 : 0,
        id:
          type == "remove"
            ? data.follower_id
            : type == "follow"
            ? data.user.id
            : type == "unfollow"
            ? data.user.id
            : "",
      };
      try {
        let response = await dispatch(followUnfollowAndRemove(record));
        if (response) {
          if (
            tabTypeOtherUserProfile == "LIKES" ||
            tabTypeOtherUserProfile == "ART_OWN"
          ) {
            getUserLikesArtOwn();
          } else if (tabTypeOtherUserProfile == "ART_CREATED") {
            getUserCreatedArt();
          } else {
            getUserArtFollowersLikesAPI();
          }
          getUserPersonalDetails();
        }
      } catch (err) {}
    } else {
      dispatch(saveNextRoute(`/profile/${username}`));
      navigate("/login");
    }
  };

  const getPlaceHolder = () => {
    if (tabTypeOtherUserProfile === "ART_OWN") {
      return `Search Art, Artist`;
    } else if (tabTypeOtherUserProfile === "ART_CREATED") {
      return `Search Art`;
    } else if (tabTypeOtherUserProfile === "FOLLOWERS") {
      return `Search Artist, User`;
    } else if (tabTypeOtherUserProfile === "FOLLOWING") {
      return `Search Artist, User`;
    } else if (tabTypeOtherUserProfile === "LIKES") {
      return `Search Art`;
    }
  };

  const likeUnlikeLikeArt = async (id, type) => {
    let record = {
      type: type === "like" ? 0 : 1,
      art_id: id,
    };
    try {
      let response = await dispatch(likeUnlikeArt(record));
      if (response) {
        getUserLikesArtOwn();
      }
    } catch (err) {}
  };

  return (
    <>
      <section className={style.user_profile}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
        </Helmet>
        <CommonHero
          backgroundImg={
            otherUserProfile?.cover_image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${otherUserProfile?.cover_image_path}`
              : CoverImage
          }
        />
        <CarddetailsOtherUser
          followUser={followUnfollowAndRemoveApi}
          userId={otherUserProfile.id}
          artOwned={artOwned.length}
          isUserLoggedin={isUserLoggedin}
        />
        <Container>
          <div className={style.tablist}>
            <Tab.Container
              id="left-tabs-example"
              className="tablists"
              defaultActiveKey={tabTypeOtherUserProfile}
              activeKey={tabTypeOtherUserProfile}
              onSelect={onTabChange}
            >
              <div className={style.navtablist}>
                <Nav variant="pills" className="nav_tabslist">
                  {/* user dashboardlist */}
                  <Nav.Item>
                    <Nav.Link eventKey="ART_OWN">ART OWNED</Nav.Link>
                  </Nav.Item>
                  {otherUserProfile?.user_type == 2 && (
                    <Nav.Item>
                      <Nav.Link eventKey="ART_CREATED">ART CREATED</Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link eventKey="FOLLOWERS">FOLLOWERS</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="FOLLOWING">FOLLOWING</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="LIKES">LIKES</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className={style.rightSearch}>
                  <form>
                    <>
                      <div className="search_input">
                        <div className="search_input_inner">
                          <button type="submit" className="search_input_icon">
                            <SearchIcon />
                          </button>
                          <input
                            type="search"
                            className="form-control"
                            placeholder={getPlaceHolder()}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  </form>
                </div>
              </div>

              <Tab.Content>
                {/* user dashboardlist */}
                <Tab.Pane eventKey="ART_OWN">
                  {tabTypeOtherUserProfile == "ART_OWN" && (
                    <Artownedsec
                      data={artOwned}
                      setSearch={setSearch}
                      search={search}
                      page={pageOtherUserProfile}
                      totalPage={totalPage}
                      type="other"
                      getUserLikesArtOwn={getUserLikesArtOwn}
                      isUserLoggedin={isUserLoggedin}
                      username={username}
                      limit={limit}
                      setLimit={setLimit}
                      limitOption={limitOption}
                      totalItems={totalItems}
                    />
                  )}
                </Tab.Pane>
                {otherUserProfile?.user_type == 2 && (
                  <Tab.Pane eventKey="ART_CREATED">
                    {tabTypeOtherUserProfile == "ART_CREATED" && (
                      <ArtistDigitalArtOtherProfile
                        id={otherUserProfile.id}
                        isUserLoggedin={isUserLoggedin}
                        setSearch={setSearch}
                        search={search}
                        debouncedSearchTerm={debouncedSearchTerm}
                        limit={limit}
                        setLimit={setLimit}
                        limitOption={limitOption}
                        totalItems={totalItems}
                        setTotalItems={setTotalItems}
                      />
                    )}
                  </Tab.Pane>
                )}
                {/* <Tab.Pane eventKey="uploaded-arts">uploaded-arts</Tab.Pane> */}
                <Tab.Pane eventKey="FOLLOWERS">
                  {tabTypeOtherUserProfile == "FOLLOWERS" && (
                    <Followers
                      data={followers}
                      setSearch={setSearch}
                      search={search}
                      page={pageOtherUserProfile}
                      totalPage={totalPage}
                      followUser={followUnfollowAndRemoveApi}
                      type="other"
                      isUserLoggedin={isUserLoggedin}
                      limit={limit}
                      setLimit={setLimit}
                      limitOption={limitOption}
                      totalItems={totalItems}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="FOLLOWING">
                  {tabTypeOtherUserProfile == "FOLLOWING" && (
                    <Following
                      data={following}
                      setSearch={setSearch}
                      search={search}
                      page={pageOtherUserProfile}
                      totalPage={totalPage}
                      followUser={followUnfollowAndRemoveApi}
                      type="other"
                      isUserLoggedin={isUserLoggedin}
                      limit={limit}
                      setLimit={setLimit}
                      limitOption={limitOption}
                      totalItems={totalItems}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="LIKES">
                  {tabTypeOtherUserProfile == "LIKES" && (
                    <Like
                      data={likes}
                      setSearch={setSearch}
                      search={search}
                      page={pageOtherUserProfile}
                      totalPage={totalPage}
                      followUser={followUnfollowAndRemoveApi}
                      likeUnlikeArt={likeUnlikeLikeArt}
                      type="other"
                      isUserLoggedin={isUserLoggedin}
                      limit={limit}
                      setLimit={setLimit}
                      username={username}
                      limitOption={limitOption}
                      totalItems={totalItems}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Otheruserprofile;
