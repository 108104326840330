// import { API_HOST } from "../constant";
import { fetch } from "../Helper/httpRequest";
const API_HOST = process.env.REACT_APP_API_HOST;

const activateDevice = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/link/activate_device`,
    data,
    { token },
    {}
  );
};

const artOwnedList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/link/art_owned`,
    {},
    { token },
    params
  );
};

const getUploadeArtList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/connected_devices/media_list`,
    {},
    { token },
    params
  );
};

const updateSelectedArt = (data, token) => {
  return fetch(
    "put",
    `${API_HOST}/nft/v1/nft/link/select_art`,
    data,
    { token },
    {}
  );
};

const updateSelectedUploadedArt = (data, token) => {
  return fetch(
    "put",
    `${API_HOST}/nft/v1/nft/media_library/select_media`,
    data,
    { token },
    {}
  );
};

const nameDevice = (data, token) => {
  return fetch(
    "put",
    `${API_HOST}/nft/v1/nft/link/rename_device`,
    data,
    { token },
    {}
  );
};

const selectDevice = (data, params, token) => {
  return fetch(
    "put",
    `${API_HOST}/nft/v1/nft/link/select_device/${params.art_id}`,
    data,
    { token },
    {}
  );
};

const getConnectedDevice = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/link/device_list`,
    {},
    { token },
    params
  );
};

const removeConnectedDevice = (token, params) => {
  return fetch(
    "delete",
    `${API_HOST}/nft/v1/nft/link/remove_device/${params.device_id}`,
    {},
    { token },
    {}
  );
};

const renameConnectedDevice = (token, data) => {
  return fetch(
    "put",
    `${API_HOST}/nft/v1/nft/link/rename_device`,
    data,
    { token },
    {}
  );
};

export const linkedDeviceServices = {
  activateDevice,
  nameDevice,
  artOwnedList,
  getUploadeArtList,
  updateSelectedArt,
  updateSelectedUploadedArt,
  selectDevice,
  getConnectedDevice,
  removeConnectedDevice,
  renameConnectedDevice,
};
