import { createSlice } from "@reduxjs/toolkit";
import { toasts } from "../../Component/Common/Toast/Toast";
import { userService } from "../../Services/userService";
import {
  disableButton,
  enableButton,
  startLoading,
  startMarketplaceLoading,
  startProfileLoading,
  stopLoading,
  stopMarketplaceLoading,
  stopProfileLoading,
} from "../loading/loadingSlice";
import {
  isUserLogin,
  saveAccessToken,
  saveIp,
  saveUserId,
  saveUserType,
} from "../persist/persistSlice";
import { store } from "../../App/store";
import { initialStateUser } from "../InitialState";

let token;

const initialState = initialStateUser;

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logoutUser: (state) => {},
    openPopUp: (state, action) => {
      state.loginCase = action.payload;
    },
    saveLatestPrice: (state, action) => {
      state.latestPrice = action.payload;
    },
    saveUserProfile: (state, action) => {
      state.profileData = action.payload;
    },
    saveUserProfileOther: (state, action) => {
      state.profileDataOther = action.payload;
    },
    saveSignUpCredential: (state, action) => {
      state.signUpData = action.payload;
    },
    saveNextRoute: (state, action) => {
      state.nextRoute = action.payload;
    },
    setSessionExpired: (state, action) => {
      state.isSessionExpired = action.payload;
    },
    setActiveTab: (state, action) => {
      state.key = action.payload;
    },
    updateUserDetails: (state, action) => {
      let data = { ...action.payload };
      state.profileData = data;
    },
    setIsAnoToggleOnCheckout: (state, action) => {
      state.isAnoToggleOnCheckout = action.payload;
    },
    setDashboardActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    setDashboardActiveSubTab: (state, action) => {
      state.activeSubTab = action.payload;
    },
    setWalletActiveTab: (state, action) => {
      state.walletActiveTab = action.payload;
    },
    setWalletSubActiveTab: (state, action) => {
      state.walletActiveSubTab = action.payload;
    },
    setScreenOrientation: (state, action) => {
      state.orientation = action.payload;
    },
    setNotifyFromTop: (state, action) => {
      state.notifyFromTop = action.payload;
    },
    setPageMarketplace: (state, action) => {
      state.pageMarketplace = action.payload;
    },
    setPageUserProfile: (state, action) => {
      state.pageUserProfile = action.payload;
    },
    setTabTypeUserProfile: (state, action) => {
      state.tabTypeUserProfile = action.payload;
    },
    setPageOtherUserProfile: (state, action) => {
      state.pageOtherUserProfile = action.payload;
    },
    setTabTypeOtherUserProfile: (state, action) => {
      state.tabTypeOtherUserProfile = action.payload;
    },
    setPagePurchase: (state, action) => {
      state.pagePurchase = action.payload;
    },
    setTabTypeTransaction: (state, action) => {
      state.tabTypeTransaction = action.payload;
    },
    setPageLinkedDevice: (state, action) => {
      state.pageLinkedDevice = action.payload;
    },
    setPageRecentActivity: (state, action) => {
      state.pageRecentActivity = action.payload;
    },
    saveLatLong: (state, action) => {
      state.latLong = action.payload;
    },
  },
});

export const {
  openPopUp,
  logoutUser,
  saveLatestPrice,
  saveUserProfile,
  saveUserProfileOther,
  saveSignUpCredential,
  saveNextRoute,
  setSessionExpired,
  setActiveTab,
  updateUserDetails,
  setDashboardActiveTab,
  setDashboardActiveSubTab,
  setScreenOrientation,
  setNotifyFromTop,
  setPageMarketplace,
  setActiveSubTab,
  setWalletActiveTab,
  setWalletSubActiveTab,
  setPageUserProfile,
  setTabTypeUserProfile,
  setPageOtherUserProfile,
  setTabTypeOtherUserProfile,
  setPagePurchase,
  setTabTypeTransaction,
  setPageLinkedDevice,
  setPageRecentActivity,
  saveLatLong,
  setIsAnoToggleOnCheckout,
} = userSlice.actions;

export default userSlice.reducer;

export const signupAction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.signup(data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const verifySignupAction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.verifySignup(data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(openPopUp("login"));
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const sendOtp = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.sendOtp(data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const loginAction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.login(data);
        let loginResponse = response?.data?.data;
        resolve(response);
        dispatch(saveUserId({ realId: response.data.data.uid }));
        if (
          loginResponse.is_email_auth_enabled == 1 &&
          loginResponse.is_sms_auth_enabled == 1
        ) {
          dispatch(openPopUp("TwoFa"));
        } else {
          dispatch(openPopUp(""));
          toasts.success(response.data.message);
          dispatch(saveAccessToken(loginResponse?.accessToken));
          dispatch(isUserLogin(true));
          dispatch(saveUserType(response.data.data.user_type));
        }

        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const ForgetPasswordAction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.ForgetPassword(data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const ResetPasswordAction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.ResetPassword(data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const changePasswordAction = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        // dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.changePassword(data, token);

        toasts.success(response.data.message);
        resolve(response);

        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        // dispatch(stopLoading());
      }
    });
  };
};

export const logoutAction = (token, handleCallback) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(enableButton());
        dispatch(saveUserType(""));
        dispatch(isUserLogin(false));
        const response = await userService.logout(token);
        dispatch(saveAccessToken(""));
        dispatch(saveIp(""));
        dispatch(logoutUser());
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        handleCallback();
        dispatch(disableButton());
      }
    });
  };
};

export const uploadKyc = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.uploadKyc(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const uploadCoverImage = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.uploadCoverImage(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getDocList = (token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getDocList(token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getKycDetail = (token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getKycDetail(token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getUserPrivacyDetail = (token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getUserPrivacyDetail(token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const saveUserPrivacyDetail = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.saveUserPrivacyDetail(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const uploadDoc = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.uploadDoc(data, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const Contactus = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.Contactus(data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const Createus = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.Createus(data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};
export const getWalletBalance = (token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getWalletBalance(token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const cashoutRequest = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.cashoutRequest(data, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getCashoutHistory = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getCashoutHistory(params, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const cancelCashoutRequest = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.cancelCashoutRequest(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getWalletLedger = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist?.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getWalletLedger(data, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getUserProfile = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = getState().persist?.accessToken;
      try {
        dispatch(startProfileLoading());
        dispatch(enableButton());
        const response = await userService.getUserProfile(params, token);
        let profleData = response.data.data.data;
        if (!params?.type) {
          if (params == undefined) {
            dispatch(saveUserProfile(profleData));
          } else {
            dispatch(saveUserProfileOther(profleData));
          }
        }

        resolve(response);
        dispatch(disableButton());
        dispatch(stopProfileLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopProfileLoading());
      }
    });
  };
};

export const updateProfileData = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;

      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.updateProfileData(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const addGstDetail = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.addGstDetail(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getGstList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getGstList(params, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};
export const deleteGst = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.deleteGst(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};
export const updateGst = (data, params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.updateGst(data, token, params);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const updateGstPrimary = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.updateGstPrimary(
          data.body,
          token,
          data.params
        );
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getUserArtFollowersLikes = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getUserArtFollowersLikes(
          params,
          token
        );
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const followUnfollowAndRemove = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;

      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.followUnfollowAndRemove(data, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        // toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getTwoFA = (token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getTwoFA(token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getTwoFAUpdate = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getUpdate2FA(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const SendOtp = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.sendotp(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getMakeOfferDetail = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getMakeOfferDetail(data, token);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const createMakeOffer = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.createMakeOffer(data, token);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);

        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getUserLikesData = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getUserLikesData(params, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};
export const getSearchedRecords = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        // dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getSearchedRecords(params, token);
        // toasts.success(response.data.message);
        resolve(response.data);
        // dispatch(stopLoading());
        dispatch(disableButton());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        // dispatch(stopLoading());
      }
    });
  };
};

export const likeUnlikeArt = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.likeUnlikeArt(params, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};
export const addFunds = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.addFunds(data, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const cancelPayment = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.cancelPayment(data, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const emailSubscribe = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.emailSubscribe(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const followUnfollowArt = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        // dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.followUnfollowArt(data, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        // dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        // dispatch(stopLoading());
      }
    });
  };
};

export const incremenentViews = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.incremenentViews(data, token);
        let profleData = response.data.data.data;

        if (data == undefined) {
          dispatch(saveUserProfile(profleData));
        } else {
          dispatch(saveUserProfileOther(profleData));
        }

        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const Login2FaVerificationAction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.Login2FaVerificationAction(data);

        if (response) {
          toasts.success(response.data.message);
          let loginResponse = response?.data?.data;
          dispatch(saveAccessToken(loginResponse?.accessToken));
          dispatch(saveUserType(response.data.data.user_type));
          dispatch(isUserLogin(true));
        }

        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getLoginOtpAction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getLoginOtp(data);

        if (response) {
          toasts.success(response.data.message);
        }

        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getFollowStatus = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getFollowStatus(params, token);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const artistActivityRequestCancel = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.artistActivityRequestCancel(
          data,
          token
        );
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getAccountActivity = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getAccountActivity(params, token);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const artOfArtist = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.artOfArtist(params, token);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

//phone email alert
export const getNotificationSettingList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getNotificationSettingList(
          params,
          token
        );
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const getNotificationList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getNotificationList(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const clearAllNotificationBox = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.clearAllNotificationBox(token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

//post

export const updateNotificationFun = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;

      try {
        dispatch(startLoading());
        const response = await userService.updateNotification(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const markAsRead = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;

      try {
        // dispatch(startLoading());
        const response = await userService.markAsRead(data, token);
        // toasts.success(response.data.message);
        resolve(response);
        // dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        // dispatch(stopLoading());
      }
    });
  };
};

//get earnings list
export const getEarningsList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getEarningsList(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

//browse marketplace

export const getBrowseMarketplaceList = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getBrowseMarketplaceList(token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

//Art details secondary marketplace

export const getSecondaryMarketplaceArtDetail = (params, isLoading) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        if (isLoading) {
          dispatch(startMarketplaceLoading());
        }
        const response = await userService.getSecondaryMarketplaceArtDetail(
          params,
          token
        );
        resolve(response.data);
        if (isLoading) {
          dispatch(stopMarketplaceLoading());
        }
      } catch (err) {
        reject(err);
        if (isLoading) {
          dispatch(stopMarketplaceLoading());
        }
      }
    });
  };
};

//Global offer

export const makeGlobalOffer = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.makeGlobalOffer(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const createCounterGlobalOffer = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.createCounterGlobalOffer(
          data,
          token
        );
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

//get fee detail

export const getFeeDetail = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getFeeDetail(token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const downloadInvoiceAction = (order_id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        // dispatch(enableButton());
        const response = await userService.downloadInvoice(order_id, token);
        resolve(response);
        // dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        // dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getCouponList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getCouponList(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const couponVerify = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.couponVerify(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getNotify = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;

      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.getNotify(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getNotifiedStatus = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getNotifiedStatus(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getRecentActivity = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getRecentActivity(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getLeaderBoard = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getLeaderBoard(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getExportArtDetail = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getExportArtDetail(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const createExportRequest = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.createExportRequest(data, token);
        toasts.success(response.data.message);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const createImportRequest = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.createImportRequest(data, token);
        toasts.success(response.data.message);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const confirmTransaction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        // dispatch(startLoading());
        const response = await userService.confirmTransaction(data, token);
        toasts.success(response.data.message);
        resolve(response.data);
        // dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        // dispatch(stopLoading());
      }
    });
  };
};
export const calculateExportFee = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        // dispatch(startLoading());
        const response = await userService.calculateExportFee(data, token);
        resolve(response.data);
        // dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        // dispatch(stopLoading());
      }
    });
  };
};

export const externalPrimaryCheckout = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.externalPrimaryCheckout(data, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const externalSecondaryCheckout = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.externalSecondaryCheckout(
          data,
          token
        );
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const paymentIntentClientSecretApi = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.paymentIntentClientSecretApi(
          data,
          token
        );
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const redeemArt = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.redeemArt(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getImportExportTransactionList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getImportExportTransactionList(
          params,
          token
        );
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const uploadArtDeatails = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.uploadArtDeatails(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getUploadeArtList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await userService.getUploadeArtList(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const deleteuploadedArts = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.deleteuploadedArts(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const selectedDeviceToPlay = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        // dispatch(startLoading());
        dispatch(enableButton());
        const response = await userService.selectedDeviceToPlay(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        // dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        // dispatch(stopLoading());
      }
    });
  };
};
