import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Accountinformation from "./Component/Accountinformation";
import AddEditAnonymousUser from "./Component/AddEditAnonymousUser";
import style from "./Editporfile.module.scss";
import CommonTable from "../../../Common/Ui/CommonTable/CommonTable";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import { useFormik } from "formik";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import ViewGstDetailsModal from "../../../Common/Ui/ViewGstDetailsModal/ViewGstDetailsModal";
import { inputFieldValidation } from "../../../../Constant/formikConstant";
import * as Yup from "yup";
import "yup-phone";
import { parsePhoneNumber } from "react-phone-number-input";
import { indianStates } from "../../../../Constant/State";
import { useDispatch, useSelector } from "react-redux";
import { addGstDetail, deleteGst, getGstList, updateGst, updateGstPrimary, } from "../../../../Features/user/userSlice";
import ConfirmationModal from "../../../Common/ConfirmationModal/ConfirmationModal";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
// import { citiesInIndia } from "../../../../Constant/City";
import {
  getAnonymousUserFunction,
  saveAnonymousDetail,
} from "../../../../Features/Anonymous/anonymousUserSlice";
import { useLocation } from "react-router-dom";
import { toasts } from "../../../Common/Toast/Toast";
import { USER_TYPE } from "../../../../Constant/userConstant";

const Editprofile = (props) => {
  const dispatch = useDispatch();
  const targetRef = useRef(null);
  const [showGstEditModal, setShowGstEditModal] = useState(false);
  const [showGstForm, setShowGstForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [phoneCountryCode, setPhoneNumberCountryCode] = useState("");
  const [gstList, setGstList] = useState([]);
  const [totalGstPages, setGstTotalPages] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [gstViewRecord, setGstViewRecord] = useState({});
  const userType = useSelector((state) => state?.persist?.userType);
  // const [cityOption, setCityOption] = useState([]);
  const [selectedTab, setSelectedTab] = useState("accountinformation");

  const saveAnonymousUserData = useSelector(
    (state) => state?.anonymousUser?.anonymouseFormData
  );
  let { updateProfile, profileData } = props;

  const location = useLocation();
  const routeData = location.state;

  useEffect(() => {
    //This is use for selection of anonymous tab when there is no anonymous is created with a message
    if (routeData?.tab && Object.keys(saveAnonymousUserData).length == 0) {
      toasts.info("Please fill this form!");
      setSelectedTab(routeData?.tab);
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ top: 0, behavior: "smooth" });
      }
    }
  }, [location]);

  const initialValue = {
    state: "",
    gst: "",
    legalBusinessName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    pinCode: "",
    phoneNumber: "",
    email: "",
  };

  const gstSchema = Yup.object().shape({
    state: Yup.object().required(
      inputFieldValidation.commonMessage.required("State")
    ),
    gst: Yup.string()
      .matches(/^\S+$/, "*Space is not allowed")
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "*Only alphanumeric characters and spaces are allowed"
      )
      .min(
        inputFieldValidation.gst.minLength,
        `*Must be of ${inputFieldValidation.gst.minLength} digit`
      )
      .max(
        inputFieldValidation.gst.maxLength,
        `*Must be of ${inputFieldValidation.gst.minLength} digit`
      ),
    // .required(inputFieldValidation.commonMessage.required("GST number")),
    legalBusinessName: Yup.string()
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "*Only alphanumeric characters and spaces are allowed"
      )
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Business name should not start or end with a space"
      )
      .min(
        inputFieldValidation.businessName.minLength,
        `*Must be between ${inputFieldValidation.businessName.minLength}-${inputFieldValidation.businessName.maxLength} characters`
      )
      .max(
        inputFieldValidation.businessName.maxLength,
        `*Must be between ${inputFieldValidation.businessName.minLength}-${inputFieldValidation.businessName.maxLength} characters`
      )
      .required(inputFieldValidation.commonMessage.required("Business name")),
    addressLine1: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      )
      .min(
        inputFieldValidation.address.minLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .max(
        inputFieldValidation.address.maxLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .required(inputFieldValidation.commonMessage.required("Address")),
    addressLine2: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      )
      .min(
        inputFieldValidation.address.minLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .max(
        inputFieldValidation.address.maxLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .required(inputFieldValidation.commonMessage.required("Address")),
    city: Yup.string().required(
      inputFieldValidation.commonMessage.required("City")
    ),
    pinCode: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Pin code"))
      .matches(/^[1-9]\d{5}$/, "*Invalid PIN code"),
    phoneNumber: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Phone number"))
      .phone(phoneCountryCode, true, "*Invalid phone number"),
    email: Yup.string().matches(
      inputFieldValidation.email.regexForSingup.expresionForSingup,
      inputFieldValidation.email.regexForSingup.errorMessageForSingup
    ),
    // .required(inputFieldValidation.commonMessage.required("Email")),
  });

  useEffect(() => {
    if (selectedTab === "gst-details") {
      getGstListHandler();
    }
  }, [selectedTab, activePage]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: gstSchema,
    onSubmit: (values) => {
      addGstDetailsHandler(values);
    },
  });

  const addGstDetailsHandler = async (values) => {
    try {
      let data = {
        gst_number: values.gst,
        business_name: values.legalBusinessName,
        country_code: `+${parsePhoneNumber(values?.phoneNumber)?.countryCallingCode
          }`,
        mobile: parsePhoneNumber(values?.phoneNumber)?.nationalNumber,
        email: values.email,
        address_line_1: values.addressLine1,
        address_line_2: values.addressLine2,
        city: values.city,
        state: values.state.value,
        pincode: values.pinCode,
      };

      await dispatch(addGstDetail(data));
      setShowGstForm(false);
      getGstListHandler();
      formik.resetForm();
    } catch (err) { }
  };

  const getGstListHandler = async () => {
    let params = {
      size: 10,
      page: activePage,
    };
    try {
      const response = await dispatch(getGstList(params));
      setGstList(response?.data?.data?.list);
      setGstTotalPages(response?.data?.data?.totalPages);
      handleScroll();
    } catch (error) { }
  };

  const deleteGstHandler = async ({ gst_id }) => {
    let data = { gst_id };
    const response = await dispatch(deleteGst(data));
    getGstListHandler();
    try {
    } catch (error) { }
  };

  const handleClose = (action) => {
    if (action === "viewGst") {
      setShowGstEditModal(false);
    } else if (action === "deleteGst") {
      setShowConfirmation(false);
    }
  };
  const handleOpen = (action) => {
    if (action === "viewGst") {
      setShowGstEditModal(true);
    } else if (action === "deleteGst") {
      setShowConfirmation(true);
    }
  };

  const handleAccept = () => {
    deleteGstHandler(gstViewRecord);
    handleClose("deleteGst");
  };
  //
  const updateGstHandler = async (values) => {
    try {
      let data = {
        gst_number: values.gst,
        business_name: values.legalBusinessName,
        country_code: `+${parsePhoneNumber(values?.phoneNumber)?.countryCallingCode
          }`,
        mobile: parsePhoneNumber(values?.phoneNumber)?.nationalNumber,
        email: values.email,
        address_line_1: values.addressLine1,
        address_line_2: values.addressLine2,
        city: values.city,
        state: values.state.value,
        pincode: values.pinCode,
      };
      let params = {
        gst_id: gstViewRecord.gst_id,
      };
      await dispatch(updateGst(data, params));
      handleClose("viewGst");
      getGstListHandler();
    } catch (error) { }
  };

  const updateGstPrimaryHandler = async (item) => {
    try {
      let data = {
        body: { is_primary: 1 },
        params: { gst_id: item.gst_id },
      };
      await dispatch(updateGstPrimary(data));
      getGstListHandler();
    } catch (error) { }
  };

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  // const statewiseCity = (value) => {
  //   setCityOption(citiesInIndia[value]);
  // };

  const getAnonymousDetail = async () => {
    try {
      let response = await dispatch(getAnonymousUserFunction());
      dispatch(saveAnonymousDetail(response?.data?.data));
    } catch (err) { }
  };

  return (
    <>
      <section className={style.edit_profile} ref={targetRef}>
        <Tabs
          id="uncontrolled-tab-example"
          className="tablists"
          activeKey={selectedTab}
          onSelect={(e) => {
            setSelectedTab(e);
          }}
        >
          <Tab eventKey="accountinformation" title="Account Information">
            {selectedTab === "accountinformation" && (
              <Accountinformation
                updateProfile={updateProfile}
                profileData={profileData}
              />
            )}
          </Tab>
          {(userType == USER_TYPE.ARTIST ||
            userType == USER_TYPE.ANONYMOUS) && (
              <Tab eventKey="addeditanonymous" title="Anonymous User">
                {selectedTab === "addeditanonymous" && (
                  <AddEditAnonymousUser
                    saveAnonymousUserData={saveAnonymousUserData}
                    getAnonymousDetail={getAnonymousDetail}
                  />
                )}
              </Tab>
            )}
          <Tab
            eventKey="gst-details"
            className={style.gst_details}
            title="GST Details"
          >
            <CommonTable className={style.table}>
              <thead>
                <tr>
                  <th>State / Province / Region</th>
                  <th>Tax Type</th>
                  <th>Tax ID</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {gstList.map((item) => (
                  <tr>
                    <td>{item?.state}</td>
                    <td>GST</td>
                    <td>{`${item?.gst_number.substring(
                      0,
                      3
                    )}*******${item?.gst_number.substring(12)}`}</td>
                    <td>
                      {item?.is_primary === 1 ? (
                        <CommonBtn
                          role="btn"
                          className="bordred-black without_hover btnSize me-3"
                          title="Primary"
                          onClick={() => {
                            // updateGstPrimaryHandler(item);
                            // handleOpen("deleteGst");
                          }}
                        />
                      ) : (
                        <CommonBtn
                          role="btn"
                          className="bordred-black btnSize me-3"
                          title="Set As Primary"
                          onClick={() => {
                            updateGstPrimaryHandler(item);
                            // handleOpen("deleteGst");
                          }}
                        />
                      )}

                      <CommonBtn
                        role="btn"
                        title="View"
                        className="bordred-black btnSize me-3"
                        onClick={() => {
                          setGstViewRecord(item);
                          handleOpen("viewGst");
                        }}
                      />
                      <CommonBtn
                        role="btn"
                        className="bordred-black btnSize"
                        title="Delete"
                        onClick={() => {
                          setGstViewRecord(item);
                          handleOpen("deleteGst");
                        }}
                      />
                    </td>
                  </tr>
                ))}
                {gstList.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      <p className="no_recordtable">
                        <strong>No records found</strong>
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </CommonTable>
            {totalGstPages > 1 && (
              <CustomPagination
                className="ms-auto"
                activePage={activePage}
                totalPage={totalGstPages}
                setActivePage={setActivePage}
                handleScroll={handleScroll}
              />
            )}

            <CommonBtn
              onClick={() => setShowGstForm(true)}
              role="btn"
              title="Add New GSTIN"
              className={`bordred-black ${style.add_btn}`}
            />
            {showGstForm && (
              <form onSubmit={formik.handleSubmit} className={style.form}>
                <Row>
                  <Col xl={6} sm={6}>
                    <FormikControls
                      options={indianStates}
                      type="select"
                      className={style.select}
                      name="state"
                      formik={formik}
                      control="select"
                      label="Select State"
                      onChange={(value) => {
                        formik.setFieldValue("state", value);
                        formik.setFieldValue("city", "");
                        // statewiseCity(value.value);
                      }}
                      value={formik.values.state}
                    />
                  </Col>
                  <Col xl={6} sm={6}>
                    <FormikControls
                      variant="input_spaces darkLabel"
                      className="input_border"
                      control="input"
                      type="input"
                      name="gst"
                      placeholder="Enter GST"
                      formik={formik}
                      maxLength={15}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (/^[a-zA-Z0-9\s]*$/.test(e.target.value)) {
                          formik.setFieldValue(
                            "gst",
                            e.target.value.toUpperCase()
                          );
                        }
                      }}
                      value={formik.values.gst}
                      label="Enter GST"
                    />
                  </Col>
                  <Col xl={6} sm={6}>
                    <FormikControls
                      variant="input_spaces"
                      className="input_border"
                      control="input"
                      type="input"
                      name="legalBusinessName"
                      placeholder="Legal Business Name"
                      label="Legal Business Name"
                      formik={formik}
                      onChange={formik.handleChange}
                      value={formik.values.legalBusinessName}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                  <Col md={6}></Col>
                  <Col xl={6} sm={6}>
                    <FormikControls
                      variant="input_spaces"
                      className="input_border"
                      control="input"
                      type="input"
                      name="addressLine1"
                      placeholder="Address Line 1"
                      label="Address Line 1"
                      formik={formik}
                      onBlur={formik.handleBlur}
                      value={formik.values.addressLine1}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xl={6} sm={6}>
                    <FormikControls
                      variant="input_spaces"
                      className="input_border"
                      control="input"
                      type="input"
                      name="addressLine2"
                      placeholder="Address Line 2"
                      label="Address Line 2"
                      formik={formik}
                      onBlur={formik.handleBlur}
                      value={formik.values.addressLine2}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xl={6} sm={6}>
                    <FormikControls
                      variant="input_spaces"
                      className="input_border"
                      control="input"
                      type="input"
                      name="city"
                      placeholder="City"
                      label="Enter City"
                      formik={formik}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      disabled={!formik.values.state.value}
                    />
                  </Col>
                  <Col xl={6} sm={6}>
                    <FormikControls
                      variant="input_spaces"
                      className="input_border"
                      control="input"
                      type="input"
                      name="pinCode"
                      placeholder="Pincode"
                      label="Pincode"
                      formik={formik}
                      maxLength={6}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        //regex for only digit
                        if (/^\d*$/.test(e.target.value)) {
                          formik.setFieldValue("pinCode", e.target.value);
                        }
                      }}
                      value={formik.values.pinCode}
                    />
                  </Col>
                  <Col xl={6} sm={6}>
                    <FormikControls
                      control="phone"
                      countryCallingCodeEditable={false}
                      classinput="phone_border"
                      type="input"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      label="Phone Number"
                      variant="input_number"
                      maxlength="20"
                      international
                      formik={formik}
                      onBlur={formik.handleBlur}
                      onChange={async (value) => {
                        await formik.setFieldTouched("phoneNumber", true);
                        if (value) {
                          setPhoneNumberCountryCode(
                            parsePhoneNumber(value)?.country
                          );
                        }
                        formik.setFieldValue("phoneNumber", value);
                      }}
                      value={formik.values.phoneNumber}
                    />
                  </Col>
                  <Col xl={6} sm={6}>
                    <FormikControls
                      variant="input_spaces"
                      className="input_border"
                      control="input"
                      type="input"
                      name="email"
                      placeholder="Email Address"
                      label="Email Address"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      formik={formik}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                </Row>
                <CommonBtn
                  role="btn"
                  title="Save Changes"
                  className={`bordred-black ${style.btnSave}`}
                  type="sumbit"
                />
              </form>
            )}
          </Tab>
        </Tabs>
      </section>
      {showConfirmation && (
        <ConfirmationModal
          show={showConfirmation}
          actionText={"delete GST"}
          handleClose={() => handleClose("deleteGst")}
          handleAccept={() => handleAccept("deleteGst")}
        />
      )}
      {showGstEditModal && (
        <ViewGstDetailsModal
          show={showGstEditModal}
          gstViewRecord={gstViewRecord}
          handleClose={() => handleClose("viewGst")}
          handleOpen={() => handleOpen("viewGst")}
          updateGstHandler={updateGstHandler}
        />
      )}
    </>
  );
};

export default Editprofile;
