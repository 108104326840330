import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sliceChar } from "../../../../Helper/common";
import Notfound from "../../../Common/NotFound/Notfound";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import style from "./Like.module.scss";
import {
  saveNextRoute,
  setPageOtherUserProfile,
} from "../../../../Features/user/userSlice";
import Select from "react-select";

const Like = (props) => {
  let {
    data,
    page,
    totalPage,
    likeUnlikeArt,
    type,
    limitOption,
    setLimit,
    limit,
    totalItems,
    username,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);

  const getButtonText = (item) => {
    if (type == "self") {
      return "Unlike";
    } else {
      if (isUserLoggedin) {
        if (item.isLiked === 0) {
          return "Like";
        } else {
          return "Unlike";
        }
      } else {
        return "Like";
      }
    }
  };

  const goToUserProfile = (item) => {
    navigate(`/profile/${item?.art?.user?.username}`);
  };

  const artNavigation = async (item) => {
    // checking the art is upcoming or live or previous
    navigate(`/digital-fine-arts/${item.id}`);
  };

  const likeUnlikeHanlder = (item) => {
    if (isUserLoggedin) {
      if (type == "self") {
        likeUnlikeArt(item.art_id, "unlike");
      } else {
        if (item?.isLiked === 0) {
          likeUnlikeArt(item.art_id, "unlike");
        } else {
          likeUnlikeArt(item.art_id, "like");
        }
      }
    } else {
      dispatch(saveNextRoute(`/profile/${username}`));
      navigate("/login");
    }
  };

  return (
    <>
      <section className={style.like}>
        {/* <div className={style.searchbar}>
          <div className="search_input">
            <div className="search_input_inner">
              <button type="submit" className="search_input_icon">
                <SearchIcon />
              </button>
              <input
                type="search"
                className="form-control"
                placeholder="Search Arts"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div> */}
        <div className={style.like_section}>
          <div className={style.like_section_arttable}>
            {/* <h5>Art</h5> */}
            <div className={style.like_table}>
              {data.length > 0 && (
                <Row className={style.rowFollow}>
                  {data.map((item) => (
                    <Col
                      xxl={2}
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={6}
                      className={style.colFollow}
                    >
                      <div className={style.info_user}>
                        <div className={style.info_user_innerSec}>
                          <img
                            onClick={() => artNavigation(item?.art)}
                            src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.art?.thumbnail_image}`}
                            alt="userImg"
                          />
                          <div className={style.text_info}>
                            <h6
                              title={`${item?.art?.art_name}`}
                              onClick={() => artNavigation(item?.art)}
                            >
                              {sliceChar(item?.art?.art_name, 20)}
                            </h6>
                            <p
                              title={`@${item?.art?.user?.username}`}
                              className="cursor-pointer"
                              onClick={() => goToUserProfile(item)}
                            >
                              @{sliceChar(item?.art?.user?.username, 15)}
                            </p>
                            {/* {type == "self" && ( */}
                            <CommonBtn
                              role="btn"
                              title={getButtonText(item)}
                              className={`bordred-black ${style.btnfollow}`}
                              onClick={() => likeUnlikeHanlder(item)}
                            />
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
              {data.length == 0 && (
                <div className={style.noData}>
                  <Notfound />
                </div>
              )}
              <div className="filterPagination">
                {totalItems > 10 && (
                  <div className="selectShow">
                    <label>Show</label>
                    <div className="selectShow_inner">
                      <Select
                        classNamePrefix="react-select"
                        className="react-select"
                        options={limitOption}
                        isSearchable={false}
                        onChange={(option) => {
                          setLimit(option);
                          dispatch(setPageOtherUserProfile(1));
                        }}
                        value={limit}
                      />
                    </div>
                  </div>
                )}
                {totalPage > 1 && (
                  <CustomPagination
                    // className="ms-auto"
                    activePage={page}
                    totalPage={totalPage}
                    maxVisiblePages={5}
                    dispatch={dispatch}
                    componentType={"Profile"}
                    componentTypes={"OtherUserProfile"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Like;
