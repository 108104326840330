import React, { useState } from "react";
import style from "./Offersmade.module.scss";
import FormikControls from "../../../../Common/Ui/Formik/FormikControls";
import { Col, Dropdown, Row } from "react-bootstrap";
import Commondropdown from "../../../../Common/Ui/CommonDropdown/Commondropdown";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import { SearchIcon } from "../../../../../Assets/svgIcons/SVGicon";
import {
  OFFER_STATUS,
  filterOnMadeOffer,
  offerType,
  sortByOnOfferMade,
} from "../../../../../Constant/userConstant";
import Select from "react-select";
import CustomPagination from "../../../../Common/Ui/Pagination/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateCounterOfferModal from "../../../../Private/Userprofile/Modals/CreateCounterOfferModal";
import GlobalMadeOffers from "./GlobalMadeOffers";
import IndividualMadeOffers from "./IndividualMadeOffers";
import AcceptCounterAcceptModal from "../../../Newdesigns/AcceptCounterAcceptModal/AcceptCounterAcceptModal";
import { updateOfferStatus } from "../../../../../Features/offerSlice/offerSlice";
import ConfirmationModal from "../../../../Common/ConfirmationModal/ConfirmationModal";
import AcceptOfferMadeGlobal from "../../../Newdesigns/AcceptCounterAcceptModal/AcceptOfferMadeGlobal";
import DeclineGlobalOfferModal from "../../../Newdesigns/DeclineGlobalOfferModal/DeclineGlobalOfferModal";

const Offersmade = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let {
    data,
    setSearch,
    setFilter,
    page,
    totalItems,
    totalPage,
    setPage,
    setFilterByStatus,
    filterByStatus,
    getUserOfferMadeList,
    setSelectedList,
    selectedList,
    dataGlobal,
    onCloseOfferSuccess,
    limitOption,
    limit,
    setLimit,
    setOfferMadeListGlobal,
  } = props;
  const [showing, setShowing] = useState(false);
  const [makeOfferDetails, setMakeOfferDetails] = useState({});
  const [offer, setOffer] = useState({});
  const [showAcceptOfferMade, setShowAcceptOfferMade] = useState(false);
  const [showAcceptOfferMadeGlobal, setShowAcceptOfferMadeGlobal] =
    useState(false);
  const [saveActionDetails, setSaveActionDetails] = useState({});
  const [saveActionDetailsGlobalMade, setSaveActionDetailsGlobalMade] =
    useState({});
  const [show, setShow] = useState(false);
  const [closeOfferData, setCloseOfferData] = useState({});
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShowConfirmation = (data) => {
    setCloseOfferData(data);
    setShow(true);
  };
  const handleAccept = () => {
    // call api
    updateStatus(closeOfferData);
  };

  const makeNewOfferOpenModal = (offer_data) => {
    let offerDetailsData = {
      edition_no: offer_data.edition_no,
      no_of_editions: offer_data.no_of_editions,
      id: offer_data.id,
    };
    let makeofferDetailsData = {
      art_name: offer_data.art_name,
      username: offer_data.username,
      drop_type: offer_data.drop_type,
      selling_price: offer_data.selling_price,
      highest_price: offer_data.highest_price,
      art_image: offer_data.art_image,
    };
    setMakeOfferDetails(makeofferDetailsData);
    setOffer(offerDetailsData);
    setShowing(true);
  };

  const onCheckUnChecked = (e, type) => {
    let checkedValue = e.target.checked;
    let record = [...filterByStatus];
    if (!checkedValue) {
      const indexToRemove = record.indexOf(type);
      if (indexToRemove !== -1) {
        record.splice(indexToRemove, 1);
      }
    } else {
      record.push(type);
    }
    setFilterByStatus(record);
  };

  const getMakeNewofferStatus = (item) => {
    if (
      item.status == OFFER_STATUS.pending ||
      (item.counter == 0 && item.status == OFFER_STATUS.declined)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateStatus = async (item) => {
    //on accept and decline for individual made only
    let data = {
      status: item.status,
      id: item.id,
    };
    try {
      let response = await dispatch(updateOfferStatus(data));
      if (response) {
        setShow(false);
        getUserOfferMadeList();
        setShowAcceptOfferMade(false);
      }
    } catch (err) {}
  };

  const updateStatusGlobal = async (item) => {
    let updateStatusData = {
      status: item.status,
      id: item.id,
    };

    if (item.status == OFFER_STATUS.declined) {
      updateStatusData.declined_reason = item.declined_reason;
    }
    try {
      let response = await dispatch(updateOfferStatus(updateStatusData));
      if (response) {
        setShow(false);
        getUserOfferMadeList();
        setShowAcceptOfferMadeGlobal(false);
        setShowDeclineModal(false);
      }
    } catch (err) {}
  };

  const updateStatusGlobalDecline = async () => {
    let updateStatusData = {
      status: OFFER_STATUS.declined,
      id: saveActionDetailsGlobalMade?.id,
    };

    try {
      let response = await dispatch(updateOfferStatus(updateStatusData));
      if (response) {
        setShow(false);
        getUserOfferMadeList();
        setShowAcceptOfferMadeGlobal(false);
        setShowDeclineModal(false);
      }
    } catch (err) {}
  };

  const updateStatusOpenModal = (update_status, item) => {
    let data = {
      status: update_status,
      id: item.id,
      offer: item,
    };
    setSaveActionDetails(data);
    if (update_status == OFFER_STATUS.accepted) {
      setShowAcceptOfferMade(true);
    } else if (update_status == OFFER_STATUS.declined) {
      handleShowConfirmation(data); // close confirmation
    }
  };

  const updateStatusGlobalOpenModal = (update_status, item, art) => {
    let data = {
      status: update_status,
      id: item.id,
      art: art,
      offer: item,
    };
    setSaveActionDetailsGlobalMade(data);
    if (update_status == OFFER_STATUS.accepted) {
      setShowAcceptOfferMadeGlobal(true);
    } else if (update_status == OFFER_STATUS.closed) {
      handleShowConfirmation(data); // close confirmation
    } else if (update_status == OFFER_STATUS.declined) {
      setShowDeclineModal(true);
    }
  };

  return (
    <>
      <section className={style.offers_made}>
        <div className={style.searchbar}>
          <Row>
            {/* <Col lg={12}>
              <>
                <div className="search_input mb-5">
                  <div className="search_input_inner">
                    <button type="submit" className="search_input_icon">
                      <SearchIcon />
                    </button>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search Art"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </>
            </Col> */}
            <Col lg={12}>
              <div className={style.tab_fields}>
                <div className={style.btn_tabs}>
                  <CommonBtn
                    role="btn"
                    className={`${
                      selectedList == offerType.SINGLE ? "activeBtn" : ""
                    } ${style.btneditsize} ${"bordred-black me-4"} `}
                    title="Individual Offer"
                    onClick={() => setSelectedList(offerType.SINGLE)}
                  />

                  <CommonBtn
                    role="btn"
                    className={` ${
                      selectedList == offerType.GLOBAL ? "activeBtn" : ""
                    } ${style.btneditsize} ${"bordred-black"}`}
                    title="Global Offer"
                    onClick={() => setSelectedList(offerType.GLOBAL)}
                  />
                </div>
                <div className={style.filter_btn}>
                  {selectedList == offerType.SINGLE && (
                    <Commondropdown classdrop="" dropdowntitle="Sort By">
                      <Dropdown.Item
                        href="#"
                        onClick={() => setFilter(sortByOnOfferMade.MOST_RECENT)}
                      >
                        Most Recent
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => setFilter(sortByOnOfferMade.ENDING_SOON)}
                      >
                        Ending Soon
                      </Dropdown.Item>
                    </Commondropdown>
                  )}
                  {selectedList == offerType.SINGLE && (
                    <Commondropdown
                      classdrop="filterDrop"
                      dropdowntitle="Filter"
                    >
                      <ul className={style.list_check}>
                        <li>
                          <FormikControls
                            control="checkbox"
                            type="checkbox"
                            name="terms"
                            label="Accepted"
                            id="Accepted"
                            className={style.setlimitcheck}
                            checked={filterByStatus.includes(
                              filterOnMadeOffer.ACCEPETED
                            )}
                            onChange={(e) =>
                              onCheckUnChecked(e, filterOnMadeOffer.ACCEPETED)
                            }
                          />
                        </li>
                        <li>
                          <FormikControls
                            control="checkbox"
                            type="checkbox"
                            name="terms"
                            label="Declined"
                            id="Declined"
                            className={style.setlimitcheck}
                            checked={filterByStatus.includes(
                              filterOnMadeOffer.DECLINED
                            )}
                            onChange={(e) =>
                              onCheckUnChecked(e, filterOnMadeOffer.DECLINED)
                            }
                          />
                        </li>
                        <li>
                          <FormikControls
                            control="checkbox"
                            type="checkbox"
                            name="terms"
                            label="Counter Offer"
                            id="Counter Offer"
                            className={style.setlimitcheck}
                            checked={filterByStatus.includes(
                              filterOnMadeOffer.COUNTERED
                            )}
                            onChange={(e) =>
                              onCheckUnChecked(e, filterOnMadeOffer.COUNTERED)
                            }
                          />
                        </li>
                      </ul>
                    </Commondropdown>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {selectedList == offerType.SINGLE && (
          <IndividualMadeOffers
            data={data}
            makeNewOfferOpenModal={makeNewOfferOpenModal}
            updateStatusOpenModal={updateStatusOpenModal}
            getMakeNewofferStatus={getMakeNewofferStatus}
          />
        )}
        {selectedList == offerType.GLOBAL && (
          <GlobalMadeOffers
            data={dataGlobal}
            makeNewOfferOpenModal={makeNewOfferOpenModal}
            updateStatusOpenModal={updateStatusGlobalOpenModal}
            getMakeNewofferStatus={getMakeNewofferStatus}
            onCloseOfferSuccess={onCloseOfferSuccess}
            setOfferMadeListGlobal={setOfferMadeListGlobal}
          />
        )}
        <div className="filterPagination">
          {console.log(totalItems)}
          {totalItems > 10 && (
            <div className="selectShow">
              <label>Show</label>
              <div className="selectShow_inner">
                <Select
                  classNamePrefix="react-select"
                  className="react-select"
                  options={limitOption}
                  isSearchable={false}
                  onChange={(option) => {
                    setLimit(option);
                    setPage(1);
                  }}
                  value={limit}
                />
              </div>
            </div>
          )}
          {totalPage > 1 && (
            <CustomPagination
              data={data}
              className="ms-auto"
              activePage={page}
              totalPage={totalPage}
              setActivePage={setPage}
              maxVisiblePages={5}
            />
          )}
        </div>
      </section>

      {showing && (
        <CreateCounterOfferModal
          show={showing}
          makeOfferDetails={makeOfferDetails}
          onHide={() => setShowing(false)}
          edition={1}
          offer={offer}
        />
      )}

      {/* working */}
      {showAcceptOfferMade && (
        <AcceptCounterAcceptModal
          show={showAcceptOfferMade}
          onHide={() => setShowAcceptOfferMade(false)}
          makeOfferDetails={saveActionDetails}
          updateStatus={updateStatus}
        />
      )}

      {showAcceptOfferMadeGlobal && (
        <AcceptOfferMadeGlobal
          show={showAcceptOfferMadeGlobal}
          onHide={() => setShowAcceptOfferMadeGlobal(false)}
          makeOfferDetails={saveActionDetailsGlobalMade}
          updateStatus={updateStatusGlobal}
        />
      )}

      {/* {showDeclineModal && (
        <DeclineGlobalOfferModal
          show={showDeclineModal}
          onHide={() => setShowDeclineModal(false)}
          makeOfferDetails={saveActionDetailsGlobalMade}
          updateStatus={updateStatusGlobal}
        />
      )} */}

      {show && (
        <ConfirmationModal
          show={show}
          handleClose={handleClose}
          handleAccept={handleAccept}
          actionText="decline this offer"
        />
      )}

      {showDeclineModal && (
        <ConfirmationModal
          show={showDeclineModal}
          handleClose={() => setShowDeclineModal(false)}
          handleAccept={updateStatusGlobalDecline}
          actionText="decline this offer"
        />
      )}
    </>
  );
};

export default Offersmade;
