import React, { useRef, useState } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  deleteuploadedArts,
  selectedDeviceToPlay,
  setPageUserProfile,
} from "../../../../Features/user/userSlice";
import Notfound from "../../../Common/NotFound/Notfound";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import style from "./UploadedArt.module.scss";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import UploadArtModal from "../Modals/UploadArtModal";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import { sliceChar } from "../../../../Helper/common";
import ConfirmationModal from "../../../Common/ConfirmationModal/ConfirmationModal";
import { toasts } from "../../../Common/Toast/Toast";

const UploadedArt = (props) => {
  let {
    page,
    totalPage,
    options,
    setLimit,
    limit,
    totalItems,
    uploadArtData,
    uploadArtStoragePercentage,
    uploadedArtsData,
    getUploadedArtList,
  } = props;

  const dispatch = useDispatch();
  const [uploadshow, setUploadshow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [show, setShow] = useState(false);
  const [activateButton, setActivateButton] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const mediaRef = useRef(null);

  const handleCheckboxChange = (id, checked) => {
    if (checked) {
      setSelectedItems((prev) => [...prev, id]); // Add ID to selected list
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id)); // Remove ID from list
      setIsAllSelected(false);
    }
  };

  const handleDelete = () => {
    setShow(true);
  };

  const customStyles = {
    option: (provided, state) => {
      // Log information specific to the option style
      return {
        ...provided,
        backgroundColor:
          state.data.is_art_played == 1 ? "#edc45d" : "#00000099", // Use the color property from the option
        color: state.data.is_art_played == 1 ? "black" : "#ffffff66",
      };
    },
  };

  const handleClose = () => setShow(false);

  const handleAccept = async () => {
    if (!selectedItems || selectedItems.length === 0) {
      // Exit early if no items are selected
      toasts.error("No items selected.");
      setShow(false);
      return;
    }

    let params = {
      mediaId: JSON.stringify(selectedItems),
    };

    // return;
    try {
      let response = await dispatch(deleteuploadedArts(params));
      if (response?.data?.status == 200) {
        setShow(false);
        setActivateButton(!activateButton);
        getUploadedArtList();
      }
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  const handleSelectAll = () => {
    if (selectedItems.length === 0) {
    }
    if (isAllSelected) {
      // Unselect all
      setSelectedItems([]);
      setIsAllSelected(false);
    } else {
      // Select all
      setSelectedItems(uploadArtData?.list?.map((item) => item.media_id));
      setIsAllSelected(true);
    }
  };
  const openFullScreen = (item) => {
    const mediaType = item?.media_file.split(".").pop().toUpperCase();
    const mediaUrl = `${process.env.REACT_APP_IMG_BASE_URL}${item.media_file}`;

    let mediaElement;
    if (mediaType === "MP4" || mediaType === "OGG") {
      mediaElement = document.createElement("video");
      mediaElement.src = mediaUrl;
      mediaElement.controls = true;
      mediaElement.autoplay = true;
      mediaElement.muted = true;
      mediaElement.loop = true;
    } else {
      mediaElement = document.createElement("img");
      mediaElement.src = mediaUrl;
      mediaElement.alt = "Full Screen Image";
    }

    // Style for fullscreen
    mediaElement.style.width = "100%";
    mediaElement.style.height = "100%";
    mediaElement.style.objectFit = "contain";

    document.body.appendChild(mediaElement);
    mediaRef.current = mediaElement;

    // Enter full screen
    if (mediaElement.requestFullscreen) {
      mediaElement.requestFullscreen();
    } else if (mediaElement.webkitRequestFullscreen) {
      mediaElement.webkitRequestFullscreen();
    } else if (mediaElement.msRequestFullscreen) {
      mediaElement.msRequestFullscreen();
    }

    // Handle full-screen exit
    document.onfullscreenchange = () => {
      if (!document.fullscreenElement && mediaElement.parentNode) {
        document.body.removeChild(mediaElement);
      }
    };
  };

  const renderMedia = (mediaFile) => {
    // Get file extension
    const fileType = mediaFile.split(".").pop().toLowerCase();

    // Base URL for media files
    const mediaUrl = `${process.env.REACT_APP_IMG_BASE_URL}${mediaFile}`;

    if (fileType === "mp4" || fileType === "ogg" || fileType === "webm") {
      // Render video element for video files
      return (
        <video
          src={mediaUrl}
          //   controls
          //   loop
          //   autoPlay
          //   muted
          className={style.mediaElement}
        />
      );
    } else {
      // Render image element for other file types
      return (
        <img src={mediaUrl} alt="Media File" className={style.mediaElement} />
      );
    }
  };

  const handleChange = async (selectedDevice, item) => {
    //0 means unselected 1 means selected
    try {
      let data = {
        device_id: selectedDevice?.device_info,
        is_play_on_device: selectedDevice.is_art_played === 1 ? 0 : 1,
        media_ids: [item.media_id],
      };
      const response = await dispatch(selectedDeviceToPlay(data));
      if (response) {
        getUploadedArtList();
      }
    } catch (error) {
      console.log("error in catch", error);
    }
  };

  function convertToWholeNumber(num) {
    return num % 1 === 0 ? Math.floor(num) : Number(num).toFixed(2);
  }
  return (
    <>
      <section className={style.uploadedArt}>
        <div className={style.uploadSelect}>
          {!activateButton && (
            <CommonBtn
              className={`bordred-black ${style.uploadBtn}`}
              role="btn"
              title="Upload"
              onClick={() => setUploadshow(true)}
            />
          )}
          {uploadArtData?.list?.length > 0 && (
            <CommonBtn
              className={` ms-3 ${style.uploadBtn} ${
                activateButton ? "black-btn" : "bordred-black"
              }`}
              role="btn"
              title="Select"
              onClick={() => {
                setActivateButton(!activateButton);
                if (!activateButton) {
                  setIsAllSelected(false);
                  setSelectedItems([]);
                }
              }}
            />
          )}

          {activateButton && (
            <div className={style.selectBtn}>
              <CommonBtn
                className={`bg-border-without ${style.btnSize}`}
                role="btn"
                title={isAllSelected ? "Deselect All" : "Select All"}
                onClick={handleSelectAll}
              />
              {selectedItems.length > 0 && (
                <CommonBtn
                  className={`bg-border-without ${style.btnSize}`}
                  role="btn"
                  title="Delete"
                  onClick={handleDelete}
                />
              )}
            </div>
          )}
        </div>
        <div className={style.uploadedArt_section}>
          <div className={style.uploadedArt_section_arttable}>
            <div className={style.uploadedArt_table}>
              <Row className={style.rowFollow}>
                {uploadArtData?.list?.map((item) => {
                  const array = [];
                  item.linked_devices?.map((ele, index) => {
                    array.push({
                      value: ele.device_id,
                      label: ele.device_name,
                      is_art_played: ele?.is_art_played,
                      device_id: ele?.device_id,
                      device_info: ele?.device_info,
                    });
                  });
                  //0 means unselected 1 means selected
                  return (
                    <Col
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={4}
                      sm={6}
                      xs={6}
                      className={style.colFollow}
                    >
                      <div className={style.info_user}>
                        <div className={style.info_user_innerSec}>
                          {activateButton && (
                            <div className={style.checkFile}>
                              <FormikControls
                                className="fieldBlack"
                                control="checkbox"
                                checked={selectedItems.includes(item.media_id)}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    item.media_id,
                                    e.target.checked
                                  )
                                }
                              />
                            </div>
                          )}
                          <div
                            className={`${style.mediaContainer} cursor-pointer`}
                            onClick={() => openFullScreen(item)}
                          >
                            {renderMedia(item.media_file)}
                          </div>
                          <div className={style.text_info}>
                            <h6 title={sliceChar(item.media_name)}>
                              {sliceChar(item.media_name, 25)}
                            </h6>
                            {activateButton === false && (
                              <Select
                                options={array}
                                className="react-select selectDevice"
                                classNamePrefix="react-select"
                                onChange={(selectedDevice) =>
                                  handleChange(selectedDevice, item)
                                }
                                styles={customStyles}
                                defaultValue={array.filter(
                                  (item) => item.is_art_played === 1
                                )}
                                placeholder={"Play on device"}
                                value={array.filter(
                                  (item) => item.is_art_played === 1
                                )}
                                // menuIsOpen
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              {uploadedArtsData?.length == 0 && (
                <div className={style.noData}>
                  <Notfound />
                </div>
              )}
              <div className={style.artFooter}>
                <div className={style.storageData}>
                  <label>Storage</label>
                  <div className={style.storageData_innerSec}>
                    <ProgressBar
                      variant={
                        uploadArtStoragePercentage <= 80 ? "success" : "danger"
                      }
                      now={uploadArtStoragePercentage}
                    />
                    <p>
                      {convertToWholeNumber(
                        uploadArtData?.userStorage?.memory_consumed
                      )}{" "}
                      MB of{" "}
                      {convertToWholeNumber(
                        uploadArtData?.userStorage?.memory_allocated
                      )}{" "}
                      MB <span>({uploadArtStoragePercentage}%)</span> used
                    </p>
                  </div>
                </div>
                <div className="filterPagination mt-0">
                  {uploadArtData?.totalItems > 10 && (
                    <div className="selectShow">
                      <label>Show</label>
                      <div className="selectShow_inner">
                        <Select
                          classNamePrefix="react-select"
                          className="react-select"
                          options={options}
                          isSearchable={false}
                          onChange={(option) => {
                            setLimit(option);
                            dispatch(setPageUserProfile(1));
                          }}
                          value={limit}
                        />
                      </div>
                    </div>
                  )}
                  {totalPage > 1 && (
                    <CustomPagination
                      activePage={page}
                      totalPage={totalPage}
                      maxVisiblePages={5}
                      dispatch={dispatch}
                      componentType={"Profile"}
                      componentTypes={"OtherUserProfile"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UploadArtModal
        show={uploadshow}
        onHide={() => setUploadshow(false)}
        getUploadedArtList={getUploadedArtList}
        uploadArtData={uploadArtData}
      />

      {show && (
        <ConfirmationModal
          show={show}
          handleClose={handleClose}
          handleAccept={handleAccept}
          actionText="delete art"
        />
      )}
    </>
  );
};

export default UploadedArt;
