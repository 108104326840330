import React, { useEffect, useRef, useState } from "react";
import { Nav, Tab, Tabs } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import style from "./Earnings.module.scss";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import Earningstable from "./Commontable/Earningstable";
import { getEarningsList } from "../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  EVENT_KEYS_FOR_TABS,
  PER_PAGE_RECORD_LIMIT,
} from "../../../../Constant/userConstant";

const Earnings = ({ setSortBy, sortBy }) => {
  const [tab, setTab] = useState(EVENT_KEYS_FOR_TABS.TWENTYFOUR_HOURS);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [earningsData, setEarningsData] = useState({});
  const [earningsList, setEarningsList] = useState([]);
  const [page, setPage] = useState(1);
  const elementToScroll = useRef(null);
  const [date, setDate] = useState(null);

  const dispatch = useDispatch();

  const handleDateChange = (name, value) => {
    setDate(value);
  };

  const initialValue = {
    startDate: "",
    endDate: "",
    datarange: "",
  };
  const validationSchema = Yup.object({
    startDate: Yup.date().nullable().required("From date is required"),
    endDate: Yup.date().nullable().required("To date is required"),
    datarange: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  const dateConversion = async () => {
    const currentDate = new Date();
    let endDate = new Date(currentDate);
    let startDate;
    switch (tab) {
      case EVENT_KEYS_FOR_TABS.TWENTYFOUR_HOURS:
        startDate = new Date(endDate);
        startDate.setHours(endDate.getHours() - 24);
        break;
      case EVENT_KEYS_FOR_TABS.SEVEN_DAYS:
        startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 7);
        break;
      case EVENT_KEYS_FOR_TABS.THREE_MONTH:
        startDate = new Date(endDate);
        startDate.setMonth(endDate.getMonth() - 3);
        break;
      case EVENT_KEYS_FOR_TABS.SIX_MONTH:
        startDate = new Date(endDate);
        startDate.setMonth(endDate.getMonth() - 6);
        break;
      case EVENT_KEYS_FOR_TABS.TWELVE_MONTH:
        startDate = new Date(endDate);
        startDate.setFullYear(endDate.getFullYear() - 1);
        break;
      default:
        startDate = new Date(endDate);
        startDate.setHours(endDate.getHours() - 24);
        break;
    }
    return { startDate, endDate };
  };

  const tabChange = async (tab) => {
    setTab(tab);
    setSortBy("Newest");
    formik.setFieldValue("startDate", "");
    formik.setFieldValue("endDate", "");
  };

  useEffect(() => {
    if (tab !== "custom") {
      getEarningsListFun();
    }
  }, [tab, page, sortBy]);

  useEffect(() => {
    if (tab === "custom") {
      getEarningsListFun();
    }
  }, [tab, page, sortBy, formik?.values?.startDate, formik?.values?.endDate]);

  useEffect(() => {
    if (formik?.values?.startDate !== "" && formik?.values?.endDate !== "") {
      setTab("custom");
    }
  }, [sortBy, page, formik?.values?.startDate, formik?.values?.endDate]);

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };
  const getEarningsListFun = async () => {
    let params;
    const dateT = await dateConversion();
    // For calender option
    if (formik?.values?.startDate !== "" && formik?.values?.endDate !== "") {
      params = {
        startDate: moment(formik?.values?.startDate).format("YYYY-MM-DD"),
        endDate: moment(formik?.values?.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.TWENTYFOUR_HOURS) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.SEVEN_DAYS) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.THREE_MONTH) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.SIX_MONTH) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.TWELVE_MONTH) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
        sort: sortBy === "Newest" ? 1 : 0,
      };
    }

    try {
      let response = await dispatch(getEarningsList(params));
      setEarningsData(response?.data);
      setEarningsList(response?.data?.list);
      scrollToElement();
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  const handleClean = () => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      setTab("24hours");
    }
  };

  return (
    <>
      <section className={style.earnings} ref={elementToScroll}>
        {/* <button type="button" onClick={()=>downloadPdf()} >CLick me</button> */}
        <div className={style.tablist}>
          <Tab.Container
            activeKey={tab}
            defaultActiveKey="24hours"
            id="uncontrolled-tab-example"
            className="tabvertical"
            onSelect={(e) => tabChange(e)}
          >
            <div className={style.tabTop}>
              <Nav variant="pills" className="tablists">
                <Nav.Item>
                  <Nav.Link eventKey="24hours">24 hours</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="1week">1 Week</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="3month">3 months</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="6month">6 months</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="12month">12 months</Nav.Link>
                </Nav.Item>
                {formik?.values?.endDate !== "" &&
                  formik?.values?.startDate && (
                    <Nav.Item>
                      <Nav.Link eventKey="custom">Custom</Nav.Link>
                    </Nav.Item>
                  )}
              </Nav>
              <div className={style.calander_field}>
                <form onSubmit={formik.handleSubmit}>
                  <input
                    // control="date"
                    title="From Date"
                    formik={formik}
                    name="startDate"
                    type="date"
                    max={
                      formik.values.endDate
                        ? formik.values.endDate
                        : new Date().toISOString().slice(0, 10)
                    }
                    className={`${style.createInputBox}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.startDate}
                  />
                  <input
                    type="date"
                    name="endDate"
                    title="To Date"
                    formik={formik}
                    min={formik.values.startDate}
                    max={new Date().toISOString().slice(0, 10)}
                    className={`${style.createInputBox}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.endDate}
                  />
                  {/* <FormikControls
                    control="daterange"
                    name="datarange"
                    startDate={startDate}
                    endDate={endDate}
                    placeholder="From Date - To Date"
                    formik={formik}
                    className={`${style.createInputBox}`}
                    onChange={(selectedDate) => {
                      setDateRange(selectedDate);
                      handleClean();
                    }}
                    onFocus={() => {
                      setTab("");
                    }}
                    isClearable={true}
                  /> */}
                </form>
              </div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="24hours">
                {tab === "24hours" && (
                  <Earningstable
                    earningsData={earningsData}
                    earningsList={earningsList}
                    setPage={setPage}
                    page={page}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="1week">
                {tab === "1week" && (
                  <Earningstable
                    earningsData={earningsData}
                    earningsList={earningsList}
                    setPage={setPage}
                    page={page}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="3month">
                {tab === "3month" && (
                  <Earningstable
                    earningsData={earningsData}
                    earningsList={earningsList}
                    setPage={setPage}
                    page={page}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="6month">
                {tab === "6month" && (
                  <Earningstable
                    earningsData={earningsData}
                    earningsList={earningsList}
                    setPage={setPage}
                    page={page}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="12month">
                {tab === "12month" && (
                  <Earningstable
                    earningsData={earningsData}
                    earningsList={earningsList}
                    setPage={setPage}
                    page={page}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                  />
                )}
              </Tab.Pane>
              {formik?.values?.endDate !== "" &&
                formik?.values?.startDate !== "" && (
                  <Tab.Pane eventKey="custom">
                    {tab === "custom" && (
                      <Earningstable
                        earningsData={earningsData}
                        earningsList={earningsList}
                        setPage={setPage}
                        page={page}
                        setSortBy={setSortBy}
                        sortBy={sortBy}
                      />
                    )}
                  </Tab.Pane>
                )}
            </Tab.Content>
          </Tab.Container>

          {/* <Tabs
            activeKey={tab}
            defaultActiveKey="24hours"
            id="uncontrolled-tab-example"
            className="
    width: 100%;
    max-width: calc(100% / 5);"
            onSelect={(e) => tabChange(e)}
          >
            <Tab eventKey="24hours" title="24 hours">
            </Tab>
            <Tab eventKey="7days" title="7 days">
            </Tab>
            <Tab eventKey="3month" title="3 months">
            </Tab>
            <Tab eventKey="6month" title="6 months">
            </Tab>
            <Tab eventKey="12month" title="12 months">
            </Tab>
          </Tabs> */}
          {tab == "" && (
            <Earningstable
              earningsData={earningsData}
              earningsList={earningsList}
              setPage={setPage}
              page={page}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Earnings;
