import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PersonIcon from "../../../../../Assets/images/person_icon.jpg";
import { SearchIcon } from "../../../../../Assets/svgIcons/SVGicon";
import { DECIMAL } from "../../../../../Constant/userConstant";
import { getNumberWithComma, sliceChar } from "../../../../../Helper/common";
import Notfound from "../../../../Common/NotFound/Notfound";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../../../Common/Ui/CommonTable/CommonTable";
import CustomPagination from "../../../../Common/Ui/Pagination/CustomPagination";
import style from "../index_dashboard.module.scss";
import { setPagePurchase } from "../../../../../Features/user/userSlice";
import Select from "react-select";

export default function Sold(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { totalPage, page, data, totalItems, limitOption, limit, setLimit } =
    props;

  const checkNavigation = (username) => {
    navigate(`/profile/${username}`);
  };

  const artNavigation = (item) => {
    navigate(`/digital-fine-arts/${item.art_id}`);
  };

  const getEditionNumber = (item) => {
    if (item.edition_number == 0) {
      return `Updating...`;
    } else if (item?.total_editions === null) {
      return `#${item.edition_number} of ${item?.sold_no_of_editions}`;
    } else {
      return `#${item.edition_number} of ${item?.total_editions}`;
    }
  };

  return (
    <div className={style.sold_tab}>
      {/* <div className={style.searchbar}>
        <div className="form">
          <>
            <div className="search_input mb-5">
              <div className="search_input_inner">
                <button type="submit" className="search_input_icon">
                  <SearchIcon />
                </button>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Artist, User"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </>
        </div>
      </div> */}
      <div className={style.table_section}>
        <CommonTable className={style.artowned_table}>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className="field_table">
                    <img
                      src={
                        item?.thumbnail_image
                          ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`
                          : PersonIcon
                      }
                      alt="artimage"
                      className="cursor-pointer"
                      onClick={() => artNavigation(item)}
                    />
                    <div className="text_field cursor-pointer">
                      <h5
                        title={item.art_name}
                        onClick={() => artNavigation(item)}
                      >
                        {sliceChar(item.art_name, 20)}
                      </h5>
                      <p
                        title={item.artist_username}
                        onClick={() => checkNavigation(item?.artist_username)}
                      >
                        @{sliceChar(item.artist_username, 15)}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="text_field_all">
                    <h6>Edition Number</h6>
                    {item.edition_number && <p>{getEditionNumber(item)}</p>}
                  </div>
                </td>
                <td>
                  <div className="Secfield_table">
                    <img
                      src={
                        item?.purchase_user_image
                          ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.purchase_user_image}`
                          : PersonIcon
                      }
                      alt="artimage"
                    />
                    <div className="text_field_all">
                      <h6 className="smallText">Purchased From</h6>
                      <p
                        className="cursor-pointer smallText"
                        title={item.purchase_account_username}
                        onClick={() =>
                          checkNavigation(item?.purchase_account_username)
                        }
                      >
                        @{sliceChar(item.purchase_account_username, 15)}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="Secfield_table">
                    <img
                      src={
                        item?.to_user_image
                          ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.to_user_image}`
                          : PersonIcon
                      }
                      alt="artimage"
                    />
                    <div className="text_field_all">
                      <h6 className="smallText">Sold To</h6>
                      <p
                        className="cursor-pointer smallText"
                        title={item.to_account_username}
                        onClick={() =>
                          checkNavigation(item?.to_account_username)
                        }
                      >
                        @{sliceChar(item.to_account_username, 15)}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="text_field_all">
                    <h6>Purchased Price</h6>
                    {item.art_price ? (
                      <p>
                        ₹{" "}
                        {getNumberWithComma(
                          (Number(item.art_price) / DECIMAL).toFixed(2)
                        )}{" "}
                      </p>
                    ) : (
                      <p> -- </p>
                    )}
                  </div>
                </td>

                <td>
                  <div className="text_field_all">
                    <h6>Selling Price</h6>
                    {item.art_selling_price ? (
                      <p>
                        ₹{" "}
                        {getNumberWithComma(
                          (Number(item.art_selling_price) / DECIMAL).toFixed(2)
                        )}{" "}
                      </p>
                    ) : (
                      <p> -- </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className="text_field_all">
                    <h6>Profit / Loss</h6>
                    <p>
                      ₹{" "}
                      {getNumberWithComma(
                        item.art_selling_price / DECIMAL -
                          item.art_price / DECIMAL
                      )}{" "}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="btn_section">
                    <CommonBtn
                      role="btn"
                      className={`bordred-black btneditsize`}
                      title="Purchase History"
                    />
                  </div>
                </td>
              </tr>
            ))}
            {data.length === 0 && <Notfound text={"No record found."} />}
          </tbody>
        </CommonTable>
      </div>
      <div className="filterPagination">
        {totalItems > 10 && (
          <div className="selectShow">
            <label>Show</label>
            <div className="selectShow_inner">
              <Select
                classNamePrefix="react-select"
                className="react-select"
                options={limitOption}
                isSearchable={false}
                onChange={(option) => {
                  dispatch(setPagePurchase(1));
                  setLimit(option);
                }}
                value={limit}
              />
            </div>
          </div>
        )}
        {totalPage > 1 && (
          <CustomPagination
            // className="ms-auto mt-4"
            activePage={page}
            totalPage={totalPage}
            // setActivePage={setPage}
            maxVisiblePages={5}
            dispatch={dispatch}
            componentType={"Transaction"}
          />
        )}
      </div>
    </div>
  );
}
