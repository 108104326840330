import React, { useEffect, useRef, useState } from "react";
import { Col, Dropdown, Row, Tab, Tabs } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../../Common/Ui/CommonTable/CommonTable";
import { getCouponList, redeemArt } from "../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import {
  formatDate,
  handleCopyClick,
  sliceChar,
} from "../../../../Helper/common";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import {
  DECIMAL,
  PER_PAGE_RECORD_LIMIT,
} from "../../../../Constant/userConstant";
import Notfound from "../../../Common/NotFound/Notfound";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import { inputFieldValidation } from "../../../../Constant/formikConstant";
import PersonIcon from "../../../../Assets/images/profileSquare.svg";
import Commondropdown from "../../../Common/Ui/CommonDropdown/Commondropdown";
import style from "./Couponstab.module.scss";

const Couponstab = ({ ArtistId, sortBy, setSortBy }) => {
  const dispatch = useDispatch();
  const [couponList, setCouponList] = useState([]);
  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const targetRef = useRef(null);
  const elementToScroll = useRef(null);
  const [activeKey, setActiveKey] = useState("coupons"); // State to manage the active tab key

  useEffect(() => {
    getCouponListFunction();
  }, [page, ArtistId, sortBy]);

  const handleScroll = () => {
    // if (targetRef.current) {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  };

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const getCouponListFunction = async () => {
    let params = {
      page: page,
      size: PER_PAGE_RECORD_LIMIT.PREVIOUS_DROP_LIST_PER_PAGE_LIMIT,
      sort: sortBy === "Newest" ? 1 : 0,
    };

    try {
      let response = await dispatch(getCouponList(params));
      setCouponList(response?.data?.list);
      setPaginationData(response?.data);
      scrollToElement();
    } catch (err) {
      console.log("error", err);
    }
  };

  //Formik
  const CashoutSchema = Yup.object({
    redeem: Yup.string().required(
      inputFieldValidation.commonMessage.required("Code")
    ),
  });

  const formik = useFormik({
    validationSchema: CashoutSchema,
    initialValues: {
      redeem: "",
    },
    onSubmit: (values) => {
      redeemArtHandler(values);
    },
  });

  const redeemArtHandler = async (values) => {
    try {
      let data = {
        code: values.redeem,
      };
      let response = await dispatch(redeemArt(data));

      if (response.data.status == 200) {
        formik.resetForm();
      }
    } catch (err) {
      return false;
    }
  };

  const handleTabSelect = (key) => {
    setActiveKey(key); // Update the active tab key when a tab is selected
    setSortBy("Newest");
    formik.handleReset();
  };

  return (
    <>
      <section className={style.coupons_tab} ref={elementToScroll}>
        <Tabs
          defaultActiveKey="coupons"
          id="uncontrolled-tab-example"
          className="tablists mb-4"
          onSelect={handleTabSelect}
        >
          <Tab eventKey="coupons" title="Coupons">
            <CommonTable className={style.coupons_table}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Offer</th>
                  <th>Expires on</th>
                  {/* <th>Coupon Valid On</th> */}
                  <th>
                    <div className="accept_check">
                      <Commondropdown
                        classdrop=""
                        dropdowntitle={sortBy ? sortBy : "Sort By"}
                      >
                        <Dropdown.Item
                          href="#"
                          onClick={() => setSortBy("Newest")}
                          className={sortBy === "Newest" ? "active" : ""}
                        >
                          Newest
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => setSortBy("Oldest")}
                          className={sortBy === "Oldest" ? "active" : ""}
                        >
                          Oldest
                        </Dropdown.Item>
                      </Commondropdown>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {couponList.map((item, index) => (
                  <tr key={index}>
                    <td title={item.coupon_name}>
                      {sliceChar(item.coupon_name, 15)}
                    </td>
                    <td title={item.coupon_code}>{item.coupon_code}</td>
                    <td title={item.discount_offer / DECIMAL}>
                      {item.discount_type == "PERCENTAGE"
                        ? item.discount_offer / DECIMAL + "% Discount"
                        : item.discount_offer / DECIMAL + "₹ Discount"}
                    </td>
                    <td>{formatDate(item.end_date)}</td>
                    {/* <td className="copoenData">
                      <div className="field_table">
                        <img
                          src={PersonIcon}
                          alt="artimage"
                          className="cursor-pointer"
                        />
                        <div className="text_field cursor-pointer">
                          <h5 title={item.art_name}>
                            {sliceChar(item.art_name, 10)}She is Nameless
                            Because She is Everything
                          </h5>
                          <p title={item.artist_username}>
                            @{sliceChar(item.artist_username, 15)}PriyankaAelay
                          </p>
                        </div>
                      </div>
                    </td> */}
                    <td>
                      <div className="btn_section">
                        <CommonBtn
                          role="btn"
                          className={`bordred-black btneditsize`}
                          title="Copy Code"
                          onClick={() =>
                            handleCopyClick(item.coupon_code, "Coupon code")
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
                {couponList.length == 0 && (
                  <tr>
                    <td colSpan={5}>
                      <Notfound text={"No data found."} />
                    </td>
                  </tr>
                )}
              </tbody>
            </CommonTable>

            {paginationData.totalItems > 10 && (
              <CustomPagination
                className="ms-auto"
                activePage={page}
                totalPage={paginationData.totalPages}
                setActivePage={setPage}
                maxVisiblePages={5}
                handleScroll={handleScroll}
              />
            )}
          </Tab>
          <Tab eventKey="redeem" title="Redeem">
            <Row>
              <Col lg={4}>
                <div className={style.redeemData}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="fund_inputs">
                      <FormikControls
                        control="input"
                        type="text"
                        name="redeem"
                        id="redeem"
                        placeholder="Enter Code To Redeem"
                        variant="fund_input"
                        onBlur={formik.handleBlur}
                        value={formik?.values?.redeem}
                        formik={formik}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          if (/^[a-zA-Z0-9\s]*$/.test(e.target.value)) {
                            formik.setFieldValue("redeem", e.target.value);
                          }
                        }}
                      />
                    </div>
                    <CommonBtn
                      role="btn"
                      className="btnsubmit black-btn"
                      title="Redeem"
                    />
                  </form>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default Couponstab;
