import React, { useEffect, useRef, useState } from "react";
import CommonTable from "../../../Common/Ui/CommonTable/CommonTable";
import style from "./Othertransactions.module.scss";
import { getImportExportTransactionList } from "../../../../Features/user/userSlice";
import { PER_PAGE_RECORD_LIMIT } from "../../../../Constant/userConstant";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
  getDateInIST,
  getTimeInIST,
  handleCopyClick,
  sliceChar,
} from "../../../../Helper/common";
import { useNavigate } from "react-router-dom";
import NewArtXLogo from "../../../../Assets/images/newArtX_Logo_Img.png";
import Commondropdown from "../../../Common/Ui/CommonDropdown/Commondropdown";
import { Dropdown } from "react-bootstrap";

const Othertransactions = ({ setSortBy, sortBy }) => {
  const [importExportData, setImportExportData] = useState({});
  const elementToScroll = useRef(null);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getImportExportTransactionListHandler();
  }, [page, sortBy]);

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const getImportExportTransactionListHandler = async () => {
    try {
      let params = {
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
        sort: sortBy === "Newest" ? 1 : 0,
      };
      let response = await dispatch(getImportExportTransactionList(params));
      setImportExportData(response?.data);
      scrollToElement();
    } catch (err) {}
  };

  // Navigate to art details page based on drop status, start time and end time
  const artNavigation = async (item) => {
    navigate(`/digital-fine-arts/${item.art_id}`);
  };

  // Common onClick handler for navigating to the user's profile page to user
  const navigateToUserProfile = (username) => {
    navigate(`/profile/${username}`);
  };

  const getTransactionStatus = (item) => {
    if (item.isImport == "true" && item.status == 1) {
      if (item.transferred == 1) {
        return "APPROVED";
      }
      if (item.transferred == 0 || item.transferred == 2) {
        return "PENDING";
      }
    } else {
      if (item.status == 0) {
        return "PENDING";
      } else if (item.status == 1) {
        return "APPROVED";
      } else {
        return "REJECTED";
      }
    }
  };

  return (
    <>
      <section className={style.other_transactions} ref={elementToScroll}>
        <CommonTable className={style.other_transactions_table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Transaction ID</th>
              <th>Art Details</th>
              <th>Transferred From</th>
              <th>Transferred To</th>
              <th>Status</th>
              <th className="shortDrop">
                <div className="accept_check">
                  <Commondropdown
                    classdrop=""
                    dropdowntitle={sortBy ? sortBy : "Sort By"}
                  >
                    <Dropdown.Item
                      href="#"
                      onClick={() => setSortBy("Newest")}
                      className={sortBy === "Newest" ? "active" : ""}
                    >
                      Newest
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => setSortBy("Oldest")}
                      className={sortBy === "Oldest" ? "active" : ""}
                    >
                      Oldest
                    </Dropdown.Item>
                  </Commondropdown>
                </div>
              </th>
            </tr>
          </thead>

          {importExportData?.list?.length > 0 ? (
            <tbody>
              {importExportData?.list.map((item, index) => (
                <tr key={index}>
                  <td>{getDateInIST(item.createdAt)}</td>
                  <td>{getTimeInIST(item.createdAt)}</td>
                  <td>
                    <div
                      className="cursor-pointer"
                      title={item.transaction_id ? item.transaction_id : `-`}
                      onClick={() =>
                        handleCopyClick(
                          item.transaction_id ? item.transaction_id : "",
                          "Transaction Id"
                        )
                      }
                    >
                      {item.transaction_id ? item.transaction_id : `-`}
                    </div>
                  </td>
                  <td className="artDetails">
                    <div className="field_table">
                      <img
                        src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.art_image}`}
                        alt="artimage"
                        className="clickable"
                        onClick={() => artNavigation(item)}
                      />
                      <div className="text_field cursor-pointer">
                        <h5 title={item?.art_name}>
                          {sliceChar(item?.art_name, 10)}
                        </h5>

                        <p
                          title={item?.username}
                          onClick={() => navigateToUserProfile(item?.username)}
                        >
                          @{sliceChar(item?.username, 15)}
                        </p>
                      </div>
                    </div>
                  </td>
                  {item?.isImport == "true" ? (
                    <td>
                      <div className="transfer_table">
                        <h5 title={item.wallet_address}>
                          {item.wallet_address}
                        </h5>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div className="transfer_table">
                        <img src={NewArtXLogo} alt="artimage" />
                        <h5>NewArtX</h5>
                      </div>
                    </td>
                  )}

                  {item?.isImport == "true" ? (
                    <td>
                      <div className="transfer_table">
                        <img src={NewArtXLogo} alt="artimage" />
                        <h5>NewArtX</h5>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div className="transfer_table">
                        <h5 title={item?.wallet_address}>
                          {item?.wallet_address}
                        </h5>
                      </div>
                    </td>
                  )}
                  {/* <td>{item.status == 0 ? "PENDING" : "APPROVED"}</td> */}
                  <td>{getTransactionStatus(item)}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={8} className="text-center">
                  No data found
                </td>
              </tr>
            </tbody>
          )}
        </CommonTable>

        {importExportData.totalItems > 10 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={importExportData.totalPages}
            setActivePage={setPage}
            maxVisiblePages={5}
            //   handleScroll={handleScroll}
          />
        )}
      </section>
    </>
  );
};

export default Othertransactions;
