import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import style from "./Addandchangeart.module.scss";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { PER_PAGE_RECORD_LIMIT } from "../../../../Constant/userConstant";
import { artOwnedList } from "../../../../Features/linkedDevice/linkedDeviceSlice";
import { useDispatch } from "react-redux";
import Notfound from "../../../Common/NotFound/Notfound";
import { sliceChar } from "../../../../Helper/common";
import { getUploadeArtList } from "../../../../Features/user/userSlice";

const Addandchangeart = ({
  updateSelectedArtHandler,
  updateSelectedUploadedArtHandler,
  artId,
  ArtistId,
  uploadArtId,
}) => {
  const targetRef = useRef(null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [data, setData] = useState({});
  const [tab, setTab] = useState("artOwned");
  const dispatch = useDispatch();

  useEffect(() => {
    if (tab === "artOwned") {
      artOwnedListHandler();
    } else if (tab === "uploadedArt") {
      getUploadeArtListHandler();
    }
  }, [page, ArtistId, tab]);

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  const artOwnedListHandler = async () => {
    try {
      let params = {
        page: page,
        size: PER_PAGE_RECORD_LIMIT.ART_OWNED_SELECT_LIST_PER_PAGE,
      };
      const response = await dispatch(artOwnedList(params));
      setList(response.data.list);
      setData(response.data);
    } catch (error) {
      console.log("error in catch", error);
    }
  };

  const getUploadeArtListHandler = async () => {
    try {
      let params = {
        page: page,
        size: PER_PAGE_RECORD_LIMIT.ART_OWNED_SELECT_LIST_PER_PAGE,
      };
      const response = await dispatch(getUploadeArtList(params));
      setList(response.data.list);
      setData(response.data);
    } catch (error) {
      console.log("error in catch", error);
    }
  };

  const renderMedia = (mediaFile) => {
    // Get file extension
    const fileType = mediaFile?.split(".").pop().toLowerCase();

    // Base URL for media files
    const mediaUrl = `${process.env.REACT_APP_IMG_BASE_URL}${mediaFile}`;

    if (fileType === "mp4" || fileType === "ogg" || fileType === "webm") {
      // Render video element for video files
      return (
        <video
          src={mediaUrl}
          //   controls
          //   loop
          //   autoPlay
          //   muted
        />
      );
    } else {
      // Render image element for other file types
      return <img src={mediaUrl} alt="Media File" />;
    }
  };
  return (
    <>
      <Tabs
        id="uncontrolled-tab-example"
        defaultActiveKey={tab}
        className="tablists"
        onSelect={(tab) => {
          setList([]);
          setData({});
          setTab(tab);
        }}
      >
        <Tab eventKey="artOwned" title="Art Owned">
          {tab === "artOwned" && (
            <section className={style.add_changeart}>
              <Row ref={targetRef}>
                {list.map((item) => (
                  <Col xl={3} lg={4} md={6} sm={6}>
                    <div className={style.card_art}>
                      <img
                        src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
                      />
                      <div class={style.card_art__body}>
                        <div class="relative mt-auto">
                          <h4 class={style.artist_name} title={item.art_name}>
                            {sliceChar(item.art_name, 15)}
                          </h4>
                          <h5 class={style.links_userId}>@{item.username}</h5>

                          {(item?.art_id != artId || artId == 0) && (
                            <div className={style.amount_Buy}>
                              <CommonBtn
                                title="Select Art"
                                role="btn"
                                type="button"
                                className={style.buy_select_srt}
                                onClick={() => {
                                  updateSelectedArtHandler(item?.art_id);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              {list.length == 0 && <Notfound text={"No data found."} />}
              {data.totalPages > 1 && (
                <CustomPagination
                  className="ms-auto"
                  activePage={page}
                  totalPage={data.totalPages}
                  setActivePage={setPage}
                  maxVisiblePages={5}
                  handleScroll={handleScroll}
                />
              )}
            </section>
          )}
        </Tab>
        <Tab eventKey="uploadedArt" title="Uploaded Art">
          {tab === "uploadedArt" && (
            <section className={style.add_changeart}>
              <Row ref={targetRef}>
                {list.map((item) => (
                  <Col xl={3} lg={4} md={6} sm={6}>
                    <div className={style.card_art}>
                      {renderMedia(item?.media_file)}
                      <div class={style.card_art__body}>
                        <div class="relative mt-auto">
                          <h4 class={style.artist_name} title={item.media_name}>
                            {sliceChar(item.media_name, 15)}
                          </h4>
                          {/* <h5 class={style.links_userId}>@{item.username}</h5> */}

                          {(item?.media_id != uploadArtId ||
                            uploadArtId == 0) && (
                            <div className={style.amount_Buy}>
                              <CommonBtn
                                title="Select Art"
                                role="btn"
                                type="button"
                                className={style.buy_select_srt}
                                onClick={() => {
                                  updateSelectedUploadedArtHandler(
                                    item?.media_id
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              {list.length == 0 && <Notfound text={"No data found."} />}
              {data.totalPages > 1 && (
                <CustomPagination
                  className="ms-auto"
                  activePage={page}
                  totalPage={data.totalPages}
                  setActivePage={setPage}
                  maxVisiblePages={5}
                  handleScroll={handleScroll}
                />
              )}
            </section>
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default Addandchangeart;
