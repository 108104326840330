import React from "react";
import { Container } from "react-bootstrap";
import HoverVideoPlayer from "react-hover-video-player";
import { useNavigate } from "react-router-dom";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";
import { checkFileType, getNumberWithComma } from "../../../../Helper/common";
import Notfound from "../../../Common/NotFound/Notfound";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonCard from "../../../Common/Ui/CommonCard/CommonCard";
import CommonSlider from "../../../Common/Ui/CommonSlider/CommonSlider";
import LoadingOverlay from "../../../VideoGif/LoadingOverlay";
import styles from "./BrowseMarketplace.module.scss";
import moment from "moment";

const BrowseMarketplace = ({ marketplaceListData }) => {
  const navigate = useNavigate();

  const rsp = [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const getArtTypeHandler = (item) => {
    let artIdn = item?.art_type_id ?? item?.art_type;

    // let artId =
    //   marketplaceFilter?.status?.[0] === 1 || marketplaceFilter?.status?.[0] === 4 ? artIdn : item?.art_type;
    switch (artIdn) {
      case 1:
        return "DYNAMIC";
      // case 2:
      //   return "GENERATIVE";
      // case 3:
      //   return "";
      // case 4:
      //   return "RESPONSIVE";
      default:
        return "";
    }
  };

  const getArtTypeName = (item) => {
    switch (item.drop_type) {
      case 1: {
        let priceLabel =
          item.market == "PRIMARY" ? "Min. Eligible Bid" : "Floor Price";
        let editionLabel = item.market == "PRIMARY" ? "" : "Edition of";
        return {
          dropType: "Auction",
          label: priceLabel,
          editionLabel,
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
      }
      case 2: {
        let priceLabel =
          item.market == "PRIMARY" ? "Min. Eligible Bid" : "Floor Price";
        return {
          dropType: "Ranked Auction",
          label: priceLabel,
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
      }
      case 3: {
        return {
          dropType: "First Come First Buy",
          label: "Floor Price",
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
      }
      case 4: {
        let editionLabel =
          item.market == "PRIMARY" ? "Editions Released:" : "Edition of";
        return {
          dropType: "Open Edition",
          label: "Floor Price",
          editionLabel,
          editionNo:
            item?.sold_no_of_editions > 0 ? item.sold_no_of_editions : "--",
        };
      }
      case 5: {
        let priceLabel =
          item.market == "PRIMARY" ? "Entry Price" : "Floor Price";
        return {
          dropType: "Raffle",
          label: priceLabel,
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
      }
      default:
        return {
          dropType: "",
          label: "Floor Price",
          editionLabel: "Edition of",
          editionNo: getNumberWithComma(item?.no_of_editions),
        };
    }
  };

  const getRemainingTime = (endTime) => {
    const currentTime = Date.now();
    const remainingTime = endTime - currentTime;

    if (remainingTime <= 0) {
      return "";
    }

    const duration = moment.duration(remainingTime, "milliseconds");

    if (duration.asDays() >= 1) {
      return `${Math.floor(duration.asDays())} Days`;
    } else if (duration.asHours() >= 1) {
      return `${Math.floor(duration.asHours())} Hrs`;
    } else if (duration.asMinutes() >= 1) {
      return `${Math.floor(duration.asMinutes())} Min`;
    } else {
      return `${Math.floor(duration.asSeconds())} Sec`;
    }
  };

  const getImageSectionBrowseMarket = (item) => {
    let type = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    let artMetaData = getArtTypeName(item);
    if (type == "MP4" || type == "OGG") {
      return (
        <HoverVideoPlayer
          loop={true}
          restartOnPaused
          unloadVideoOnPaused={true}
          disableRemotePlayback={false}
          paused={true}
          muted={true}
          onClick={() => navigate(`/digital-fine-arts/${item.art_id}`)}
          className={`video_hover_sec ${styles.image_sec}`}
          videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          pausedOverlay={
            <CommonCard
              className={` ${styles.cardhover}`}
              imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
              title={item.art_name}
              subtitle={`@${item.username}`}
              isMarketPlace={true}
              item={item}
              onClick={() => navigate(`/digital-fine-arts/${item.art_id}`)}
              tagTitile={getArtTypeHandler(item)}
              livedata={item.market}
              endData={
                item.drop_type != 3 ? getRemainingTime(item.end_time) : ""
              }
              artTitle={getArtTypeName(item)}
              // getArtTypeName={getArtTypeName(item?.drop_type, item?.market)}
              label={artMetaData.label}
              price={item?.floor_price}
              editionLabel={artMetaData.editionLabel}
              editionNo={artMetaData.editionNo}
            />
          }
          loadingOverlay={<LoadingOverlay />}
        />
      );
    } else {
      return (
        <CommonCard
          // className={`card_withouthover ${styles.cardhover}`}
          imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
          featureUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          title={item.art_name}
          subtitle={`@${item.username}`}
          item={{ ...item, artist_id: item.user_id }}
          onClick={() => navigate(`/digital-fine-arts/${item.art_id}`)}
          isMarketPlace={true}
          tagTitile={getArtTypeHandler(item)}
          livedata={item.market}
          endData={item.drop_type != 3 ? getRemainingTime(item.end_time) : ""}
          artTitle={getArtTypeName(item)}
          // getArtTypeName={getArtTypeName(item?.drop_type, item?.market)}
          label={artMetaData.label}
          price={item?.floor_price}
          editionLabel={artMetaData.editionLabel}
          editionNo={artMetaData.editionNo}
        />
      );
    }
  };

  return (
    <>
      <section className={styles.BrowseMarketplace}>
        <Container>
          <h2>Browse Digital Fine Art</h2>
          {marketplaceListData?.length > 0 && (
            <>
              <CommonSlider
                className="arrowsDown"
                // speed={3000}
                dots={false}
                // autoplay={true}
                slidesToShow={5}
                arrows={marketplaceListData?.length > 5 ? true : false}
                slidesToScroll={1}
                responsive={rsp}
                infinite={marketplaceListData?.length > 5 ? true : false}
                pauseOnHover={true}
              >
                {marketplaceListData?.map((item, index) => (
                  <div key={index}>{getImageSectionBrowseMarket(item)}</div>
                ))}
              </CommonSlider>

              <div className={styles.actionsBtn}>
                <CommonBtn
                  className={styles.btnSize}
                  role="link"
                  to={RoutesUrls.Marketplace}
                  title="Explore All Digital Fine Art"
                  onClick={() => navigate(RoutesUrls?.Marketplace)}
                />
                <CommonBtn
                  title="What is DFA?"
                  className={`bg-border-without ${styles.btnSize}`}
                  role="btn"
                  onClick={() => navigate(RoutesUrls.About_dfa)}
                />
              </div>
            </>
          )}
          {marketplaceListData?.length === 0 && (
            <Notfound
              className={`my-5 ${styles.notFound}`}
              text={"No digital art found."}
            />
          )}
        </Container>
      </section>
    </>
  );
};

export default BrowseMarketplace;
