import { createSlice } from "@reduxjs/toolkit";
import { toasts } from "../../Component/Common/Toast/Toast";
import { linkedDeviceServices } from "../../Services/linkedDeviceServices";
import {
  disableButton,
  enableButton,
  startLoading,
  stopLoading,
} from "../loading/loadingSlice";
import { store } from "../../App/store";

let token;

const initialState = {
  // tabs: "",
};

export const linkedDeviceSlice = createSlice({
  name: "linkeddevice",
  initialState,
  reducers: {
    // setTabs: (state, action) => {
    //   state.tabs = action.payload;
    // },
  },
});

export const {
  // setTabs
} = linkedDeviceSlice.actions;

export default linkedDeviceSlice.reducer;

export const activateDevice = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await linkedDeviceServices.activateDevice(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const nameDevice = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await linkedDeviceServices.nameDevice(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const selectDevice = (data, params, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        // dispatch(startLoading());
        dispatch(enableButton());
        const response = await linkedDeviceServices.selectDevice(
          data,
          params,
          token
        );
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        // dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        // dispatch(stopLoading());
      }
    });
  };
};

export const artOwnedList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await linkedDeviceServices.artOwnedList(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getUploadeArtList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await linkedDeviceServices.getUploadeArtList(params, token);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const updateSelectedArt = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await linkedDeviceServices.updateSelectedArt(
          data,
          token
        );
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const updateSelectedUploadedArt = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        dispatch(enableButton());
        const response = await linkedDeviceServices.updateSelectedUploadedArt(
          data,
          token
        );
        toasts.success(response.data.message);
        resolve(response);
        dispatch(disableButton());
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(disableButton());
        dispatch(stopLoading());
      }
    });
  };
};

export const getConnectedDeviceList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await linkedDeviceServices.getConnectedDevice(
          token,
          params
        );
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const removeConnectedDevice = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await linkedDeviceServices.removeConnectedDevice(
          token,
          params
        );
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const renameConnectedDevice = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await linkedDeviceServices.renameConnectedDevice(
          token,
          data
        );
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
