import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  CloseIcon,
  FBIcon,
  InstaIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../../../../Assets/svgIcons/SVGicon";
import style from "./Privacysettings.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import TextEditor from "../Editprofile/TextEditor/TextEditor";

const Privacysettings = ({
  saveUserPrivacyDetailHandler,
  privacySettingData,
}) => {
  const initialValue = {
    firstName: privacySettingData?.first_name
      ? privacySettingData?.first_name
      : "",
    middleName: privacySettingData?.middle_name
      ? privacySettingData?.middle_name
      : "",
    lastName: privacySettingData?.last_name
      ? privacySettingData?.last_name
      : "",
    makepublicBio: privacySettingData?.bio_setting,
    makepublicName: privacySettingData?.name_flag,
    bio: privacySettingData?.bio ? privacySettingData?.bio : "",
    makepublicInsta: privacySettingData?.isInstagramPublic,
    makepublicFacebook: privacySettingData?.isFacebookPublic,
    makepublicTwitter: privacySettingData?.isTwitterPublic,
    makepublicLinkdin: privacySettingData?.isLinkedinPublic,
    file: privacySettingData?.image_path
      ? `${process.env.REACT_APP_IMG_BASE_URL}${privacySettingData?.image_path}`
      : "",
    facebookUrl: privacySettingData?.facebook
      ? privacySettingData?.facebook
      : "",
    instagramUrl: privacySettingData?.instagram
      ? privacySettingData?.instagram
      : "",
    twitterUrl: privacySettingData?.twitter ? privacySettingData?.twitter : "",
    linkedinUrl: privacySettingData?.linkedin
      ? privacySettingData?.linkedin
      : "",
  };
  const validationSchema = Yup.object({
    makepublicInsta: Yup.boolean(),
    makepublicFacebook: Yup.boolean(),
    makepublicTwitter: Yup.boolean(),
    makepublicLinkdin: Yup.boolean(),
    makepublicBio: Yup.boolean(),
    makepublicName: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let data = {
        name_flag: values?.makepublicName,
        isFacebookPublic: values?.makepublicFacebook,
        isTwitterPublic: values?.makepublicTwitter,
        isLinkedinPublic: values?.makepublicLinkdin,
        isInstagramPublic: values?.makepublicInsta,
        bio_setting: values?.makepublicBio,
      };
      saveUserPrivacyDetailHandler(data);
    },
  });

  const makePublic = (e, name) => {
    formik.setFieldValue(name, e.target.checked ? 1 : 0);
  };
  return (
    <>
      <section className={style.privacy_settings}>
        <Tabs
          defaultActiveKey="makepublic"
          id="uncontrolled-tab-example"
          className="tablists"
        >
          <Tab eventKey="makepublic" title="Make Public">
            <div className={style.privacy_form}>
              <form onSubmit={formik.handleSubmit}>
                <div className={style.input_check}>
                  <FormikControls
                    className="checkbox_input"
                    control="checkbox"
                    name="makepublicName"
                    formik={formik}
                    onBlur={formik.handleBlur}
                    onChange={(e) => makePublic(e, "makepublicName")}
                    checked={formik.values.makepublicName}
                  />
                  <FormikControls
                    variant="input_spaces"
                    className="input_border"
                    control="input"
                    label="First Name"
                    type="text"
                    name="firstName"
                    formik={formik}
                    placeholder="Enter First Name"
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    // onChange={formik.handleChange}
                    disabled={true}
                  />
                </div>
                <FormikControls
                  variant="input_spaces"
                  className="input_border"
                  control="input"
                  label="Middle Name (Optional)"
                  type="middleName"
                  name="middleName"
                  formik={formik}
                  placeholder="Enter Middle Name"
                  onBlur={formik.handleBlur}
                  value={formik.values.middleName}
                  //   onChange={formik.handleChange}
                  disabled={true}
                />
                <FormikControls
                  variant="input_spaces"
                  className="input_border"
                  control="input"
                  label="Last Name"
                  type="text"
                  name="lastName"
                  formik={formik}
                  placeholder="Enter Last Name"
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  //   onChange={formik.handleChange}
                  disabled={true}
                />
                <div className={style.input_checkBio}>
                  <FormikControls
                    className="checkbox_input"
                    control="checkbox"
                    name="makepublicBio"
                    formik={formik}
                    onBlur={formik.handleBlur}
                    onChange={(e) => makePublic(e, "makepublicBio")}
                    checked={formik.values.makepublicBio}
                  />
                  <TextEditor
                    className={"input_spaces"}
                    label={"Bio"}
                    id={"bio"}
                    name={"bio"}
                    placeholder="Enter Bio"
                    formik={formik}
                    readOnly={true}
                  />
                </div>
                <div className={style.input_check}>
                  {/* {!formik.errors["facebookUrl"] &&
                    formik.values.facebookUrl != "" &&
                    !profileData?.is_facebook_public_input_disabled && ( */}
                  <FormikControls
                    className="checkbox_input"
                    control="checkbox"
                    name="makepublicFacebook"
                    formik={formik}
                    onBlur={formik.handleBlur}
                    onChange={(e) => makePublic(e, "makepublicFacebook")}
                    checked={formik.values.makepublicFacebook}
                  />
                  {/* )} */}
                  <FormikControls
                    variant="input_spaces darkLabel"
                    className="input_border"
                    control="input"
                    label="Facebook Link"
                    type="text"
                    name="facebookUrl"
                    leftIcon={<FBIcon />}
                    formik={formik}
                    placeholder="Enter Facebook Link"
                    onBlur={formik.handleBlur}
                    value={formik.values.facebookUrl}
                    disabled={true}
                  />
                </div>
                <div className={style.input_check}>
                  {/* {!formik.errors["instagramUrl"] &&
                    formik.values.instagramUrl != "" &&
                    !profileData?.is_instagram_public_input_disabled && ( */}
                  <FormikControls
                    className="checkbox_input"
                    control="checkbox"
                    name="makepublicInsta"
                    formik={formik}
                    onBlur={formik.handleBlur}
                    onChange={(e) => makePublic(e, "makepublicInsta")}
                    checked={formik.values.makepublicInsta}
                  />
                  {/* )} */}
                  <FormikControls
                    variant="input_spaces darkLabel"
                    className="input_border"
                    control="input"
                    label="Instagram Link"
                    type="text"
                    name="instagramUrl"
                    leftIcon={<InstaIcon />}
                    //   rightIcon={<CloseIcon />}
                    formik={formik}
                    placeholder="Enter Instagram Link"
                    onBlur={formik.handleBlur}
                    value={formik.values.instagramUrl}
                    disabled={true}
                  />
                </div>
                <div className={style.input_check}>
                  {/* {!formik.errors["twitterUrl"] &&
                    formik.values.twitterUrl != "" &&
                    !profileData?.is_twitter_public_input_disabled && ( */}
                  <FormikControls
                    className="checkbox_input"
                    control="checkbox"
                    name="makepublicTwitter"
                    formik={formik}
                    onBlur={formik.handleBlur}
                    onChange={(e) => makePublic(e, "makepublicTwitter")}
                    checked={formik.values.makepublicTwitter}
                  />
                  {/* )} */}
                  <FormikControls
                    variant="input_spaces darkLabel"
                    className="input_border"
                    control="input"
                    label="Twitter Link"
                    type="text"
                    name="twitterUrl"
                    leftIcon={<TwitterIcon />}
                    formik={formik}
                    placeholder="Enter Twitter Link"
                    onBlur={formik.handleBlur}
                    value={formik.values.twitterUrl}
                    // onChange={formik.handleChange}
                    disabled={true}
                  />
                </div>
                <div className={style.input_check}>
                  {/* {!formik.errors["linkedinUrl"] &&
                    formik.values.linkedinUrl != "" &&
                    !profileData?.is_linkedin_public_input_disabled && ( */}
                  <FormikControls
                    className="checkbox_input"
                    control="checkbox"
                    name="makepublicLinkdin"
                    formik={formik}
                    onBlur={formik.handleBlur}
                    onChange={(e) => makePublic(e, "makepublicLinkdin")}
                    checked={formik.values.makepublicLinkdin}
                  />
                  {/* )} */}
                  <div className={style.social_media}>
                    <FormikControls
                      variant="input_spaces darkLabel"
                      className="input_border"
                      control="input"
                      label="Linkedin Link"
                      type="text"
                      name="linkedinUrl"
                      leftIcon={<LinkedinIcon />}
                      formik={formik}
                      placeholder="Enter Linkedin Link"
                      onBlur={formik.handleBlur}
                      value={formik.values.linkedinUrl}
                      // onChange={formik.handleChange}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className={style.btnsave}>
                  <CommonBtn
                    role="btn"
                    className={`bordred-black ${style.btnsize}`}
                    title="Save Changes"
                  />
                </div>
              </form>
            </div>
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default Privacysettings;
