import React, { useEffect } from "react";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../../Common/Ui/CommonTable/CommonTable";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import style from "./NotificationTable.module.scss";
import personIcon from "../../../../Assets/images/person_icon.jpg";
import Notfound from "../../../Common/NotFound/Notfound";
import {
  NOTIFICATION_CATEGORY,
  tabName,
  WALLET_TAB,
} from "../../../../Constant/userConstant";
import {
  markAsRead,
  setActiveTab,
  setDashboardActiveSubTab,
  setDashboardActiveTab,
  setWalletActiveTab,
  setWalletSubActiveTab,
} from "../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CouponIcon from "../../../../Assets/icons/couponIcon.svg";
import CashIcon from "../../../../Assets/icons/cash.svg";

import DOMPurify from "dompurify";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";

export const NotificationTable = ({
  notificationList,
  page,
  setPage,
  totalPage,
  getNotificationHandler,
  setImportData,
  setShowQrAddress,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Function to sanitize HTML content
  const sanitizedContent = (item) => {
    return { __html: DOMPurify.sanitize(item.content) };
  };

  useEffect(() => {
    if (page > 1 && notificationList.length === 0) {
      setPage(page - 1);
    }
  }, [notificationList]);

  // Function to handle navigation based on notification type
  const notificationNavigation = (type) => {
    switch (Number(type?.notificationCategory)) {
      case NOTIFICATION_CATEGORY.PROFILE:
      case NOTIFICATION_CATEGORY.NEW_ARTIST:
        userNavigation(type); // Navigate to user profile
        break;
      case NOTIFICATION_CATEGORY.BIO:
        // Navigate to edit profile page
        dispatch(setActiveTab("editprofile"));
        navigate(RoutesUrls.User_account_setting);
        break;
      case NOTIFICATION_CATEGORY.DFA_LANDING:
        artNavigation(type); // Navigate to digital art page
        break;
      case NOTIFICATION_CATEGORY.DROP:
        dropNavigation(type); // Navigate to drop page
        break;
      case NOTIFICATION_CATEGORY.KYC:
        // Navigate to KYC page
        dispatch(setActiveTab("kyc"));
        navigate(RoutesUrls.User_account_setting);
        break;
      case NOTIFICATION_CATEGORY.OFFERS_MADE:
      case NOTIFICATION_CATEGORY.OFFERS_RECEIVED:
        // Navigate to user dashboard
        dispatch(setDashboardActiveTab("offers"));
        dispatch(setDashboardActiveSubTab(tabName.OFFER_MADE));
        navigate(RoutesUrls.User_dashboard);
        break;
      case NOTIFICATION_CATEGORY.COUPONS:
        // Navigate to coupons page
        dispatch(setDashboardActiveTab("coupons"));
        navigate(RoutesUrls.User_dashboard);
        break;
      case NOTIFICATION_CATEGORY.IMPORT:
        // Navigate to coupons page
        setImportData(type);
        setShowQrAddress(true);
        break;

      case NOTIFICATION_CATEGORY.FIAT_WITHDRAW:
        dispatch(setWalletActiveTab(WALLET_TAB.MAINTAB.CASHOUT_HISTORY));
        dispatch(setWalletSubActiveTab(WALLET_TAB.SUBTAB.INPROCRESS));
        navigate(RoutesUrls.Wallet);
        break;
      case NOTIFICATION_CATEGORY.CANCEL_WITHDRAW:
        dispatch(setWalletActiveTab(WALLET_TAB.MAINTAB.CASHOUT_HISTORY));
        dispatch(setWalletSubActiveTab(WALLET_TAB.SUBTAB.INPROCRESS));
        navigate(RoutesUrls.Wallet);
        break;
      case NOTIFICATION_CATEGORY.APPROVE_WITHDRAW_REQ:
        dispatch(setWalletActiveTab(WALLET_TAB.MAINTAB.CASHOUT_HISTORY));
        dispatch(setWalletSubActiveTab(WALLET_TAB.SUBTAB.COMPLETED));
        navigate(RoutesUrls.Wallet);
        break;
      case NOTIFICATION_CATEGORY.CANCELLED_WITHDRAW_REQ:
        dispatch(setWalletActiveTab(WALLET_TAB.MAINTAB.CASHOUT_HISTORY));
        dispatch(setWalletSubActiveTab(WALLET_TAB.SUBTAB.CANCELLEDREJECT));
        navigate(RoutesUrls.Wallet);
        break;
      case NOTIFICATION_CATEGORY.REJECTED_WITHDRAW_REQ:
        dispatch(setWalletActiveTab(WALLET_TAB.MAINTAB.CASHOUT_HISTORY));
        dispatch(setWalletSubActiveTab(WALLET_TAB.SUBTAB.CANCELLEDREJECT));
        navigate(RoutesUrls.Wallet);
        break;
      case NOTIFICATION_CATEGORY.CANCEL_WITHDRAW_REQ_REJECT:
        dispatch(setWalletActiveTab(WALLET_TAB.MAINTAB.CASHOUT_HISTORY));
        dispatch(setWalletSubActiveTab(WALLET_TAB.SUBTAB.CANCELLEDREJECT));
        navigate(RoutesUrls.Wallet);
        break;
    }
  };

  // Function to get notification image based on notification category
  const notificationImage = (type) => {
    switch (Number(type?.notificationCategory)) {
      case NOTIFICATION_CATEGORY.PROFILE:
      case NOTIFICATION_CATEGORY.BIO:
      case NOTIFICATION_CATEGORY.KYC:
      case NOTIFICATION_CATEGORY.NEW_ARTIST:
        // Return user profile image or default profile image
        return type?.userImage
          ? `${process.env.REACT_APP_IMG_BASE_URL}${type?.userImage}`
          : personIcon;
      case NOTIFICATION_CATEGORY.DFA_LANDING:
      case NOTIFICATION_CATEGORY.DROP:
      case NOTIFICATION_CATEGORY.OFFERS_MADE:
      case NOTIFICATION_CATEGORY.OFFERS_RECEIVED:
        // Return art image
        return `${process.env.REACT_APP_IMG_BASE_URL}${type?.artImage}`;
      case NOTIFICATION_CATEGORY.COUPONS:
        // Return coupon icon
        return CouponIcon;
      case NOTIFICATION_CATEGORY.IMPORT:
        return personIcon;
      case NOTIFICATION_CATEGORY.FIAT_WITHDRAW:
        return CashIcon;
      case NOTIFICATION_CATEGORY.CANCEL_WITHDRAW:
        return CashIcon;
      case NOTIFICATION_CATEGORY.APPROVE_WITHDRAW_REQ:
        return CashIcon;
      case NOTIFICATION_CATEGORY.CANCELLED_WITHDRAW_REQ:
        return CashIcon;
      case NOTIFICATION_CATEGORY.REJECTED_WITHDRAW_REQ:
        return CashIcon;
      case NOTIFICATION_CATEGORY.CANCEL_WITHDRAW_REQ_REJECT:
        return CashIcon;
      default:
        return personIcon;
    }
  };

  // Function to get extra image for specific notification category
  const notificationExtraImage = (type) => {
    switch (type?.notificationCategory) {
      case NOTIFICATION_CATEGORY.PROFILE:
        // Return following user image or default profile image
        return type?.followingUserImage
          ? `${process.env.REACT_APP_IMG_BASE_URL}${type?.followingUserImage}`
          : personIcon;
      default:
        return personIcon;
    }
  };

  // Function to navigate to user profile based on userId
  const userNavigation = (item) => {
    if (item.userName) {
      navigate(`/profile/${item.userName}`); // Navigate to other user's profile
    }
  };

  // Function to navigate to art page
  const artNavigation = async (item) => {
    navigate(`/digital-fine-arts/${item.artId}`); // Navigate to previous art page.
  };

  // Function to navigate to drop page
  const dropNavigation = (item) => {
    navigate(`/drop/${item.dropId}/all`); // Navigate to drop page
  };

  // Function to mark notification as read
  const markAsReadHandler = async (item) => {
    try {
      let data = {
        notificationId: item.id,
      };
      // Mark notification as read
      const response = await dispatch(markAsRead(data));
      // Refresh notification list
      getNotificationHandler();
    } catch (err) {
      // Handle errors
    }
  };

  return (
    <>
      <div className={style.table_section}>
        <CommonTable className={style.notification_table}>
          <tbody>
            {notificationList.map((item, index) => (
              <tr
                key={index}
                className={item?.status === "UNREAD" ? "" : "active"}
              >
                <td>
                  <div
                    className="d-flex align-items-center thumb-outer cursor-pointer"
                    onClick={() => notificationNavigation(item)}
                  >
                    <img
                      className="thumb cursor-pointer"
                      src={notificationImage(item)}
                      alt="thumb"
                    />
                    {/* {item.notificationCategory ==
                        NOTIFICATION_CATEGORY.ARTIST_PROFILE && (
                        <h4 title={item.user_name}>{"Hello"}</h4>
                      )} */}
                  </div>
                </td>
                <td
                  onClick={() => notificationNavigation(item)}
                  dangerouslySetInnerHTML={sanitizedContent(item)} // Sanitize HTML content
                ></td>
                <td>
                  {item.notificationCategory ===
                    NOTIFICATION_CATEGORY.PROFILE && (
                    <div
                      className="d-flex align-items-center thumb-outer cursor-pointer"
                      onClick={() => notificationNavigation(item)}
                    >
                      <img
                        className="thumb cursor-pointer"
                        src={notificationExtraImage(item)}
                        alt="thumb"
                      />

                      {/* <div className={style.textSec}>
                        <h6 title={item.art_name}>{item.art_name}</h6>
                        <p>{item.artID}</p>
                      </div> */}
                    </div>
                  )}
                </td>
                <td>
                  {item.status === "UNREAD" && (
                    <div className="d-flex align-items-center justify-content-evenly">
                      <CommonBtn
                        role="btn"
                        title={"Mark as Read"}
                        className={`bordred-black ${style.borderBtn}`}
                        onClick={() => markAsReadHandler(item)}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          {notificationList.length === 0 && (
            <Notfound text={"No Older Notifications."} />
          )}
        </CommonTable>
        {totalPage > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPage}
            setActivePage={setPage}
            maxVisiblePages={5}
          />
        )}
      </div>
    </>
  );
};
