import React, { useEffect, useState } from "react";
import style from "./Wallets.module.scss";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Carddetails from "../Userprofile/Carddetails/Carddetails";
import Wallet_tab from "./Component/Wallet_tab";
import Ledger_tab from "./Component/Ledger_tab";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfile,
  getWalletBalance,
  getWalletLedger,
  setWalletActiveTab,
} from "../../../Features/user/userSlice";
import { WALLET_TAB } from "../../../Constant/userConstant";
import CoverImage from "../../../Assets/images/User-Cover.png";
import { Helmet } from "react-helmet";
import CashoutHistory from "./Component/CashoutHistory/CashoutHistory";
const limitOption = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 },
];

const Wallets = () => {
  const dispatch = useDispatch();
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const activeWalletTab = useSelector((state) => state?.users.walletActiveTab);
  const [walletBalance, setWalletBalance] = useState([]);
  const [walletLedgerData, setWalletLedgerData] = useState([]);
  const [walletLedger, setWalletLedger] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const userDetail = useSelector((state) => state?.users?.profileData);
  const [limit, setLimit] = useState(limitOption[0]);

  useEffect(() => {
    if (activeWalletTab == "wallet") {
      getWalletBalanceHandler();
      getUserDetail();
    } else if (activeWalletTab === "ledger") {
      getWalletLedgerHandler();
    }
  }, [activeWalletTab, page, ArtistId, limit]);

  const getWalletLedgerHandler = async () => {
    let data;
    data = {
      page: page,
      size: limit.value,
    };
    const response = await dispatch(getWalletLedger(data));
    setWalletLedgerData(response?.data?.data?.list);
    setWalletLedger(response?.data?.data);
    setTotalPages(response.data.data.totalPages);
  };

  const getWalletBalanceHandler = async () => {
    const response = await dispatch(getWalletBalance());
    setWalletBalance(response?.data?.data?.user_fiat_account?.balance);
  };

  const getUserDetail = async () => {
    try {
      await dispatch(getUserProfile());
    } catch (err) {}
  };

  return (
    <>
      <section className={style.wallets}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
        </Helmet>
        <CommonHero
          backgroundImg={
            userDetail?.cover_image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail?.cover_image_path}`
              : CoverImage
          }
        />
        <Carddetails classHeading={style.bodernone} />
        <div className={`mt-3 mt-md-5 ${style.wallets_section}`}>
          <Container>
            <div className={style.tablist}>
              <Tab.Container
                id="left-tabs-example"
                activeKey={activeWalletTab}
                onSelect={(e) => {
                  dispatch(setWalletActiveTab(e));
                }}
                className="tabvertical"
              >
                <Row>
                  <Col xl={2} lg={3}>
                    <Nav variant="pills" className="nav_tabslist">
                      <Nav.Item>
                        <Nav.Link eventKey={WALLET_TAB?.MAINTAB.WALLET}>
                          Wallet
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={WALLET_TAB?.MAINTAB.LEDGER}>
                          Ledger
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={WALLET_TAB?.MAINTAB.CASHOUT_HISTORY}
                        >
                          Cash Out - History
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col xl={10} lg={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey={WALLET_TAB?.MAINTAB.WALLET}>
                        {activeWalletTab == WALLET_TAB?.MAINTAB.WALLET && (
                          <Wallet_tab
                            walletBalance={walletBalance}
                            getWalletBalanceHandler={getWalletBalanceHandler}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey={WALLET_TAB?.MAINTAB.LEDGER}>
                        {activeWalletTab == WALLET_TAB?.MAINTAB.LEDGER && (
                          <Ledger_tab
                            walletLedgerData={walletLedgerData}
                            walletLedger={walletLedger}
                            page={page}
                            totalPages={totalPages}
                            setPage={setPage}
                            limit={limit}
                            setLimit={setLimit}
                            limitOption={limitOption}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey={WALLET_TAB?.MAINTAB.CASHOUT_HISTORY}>
                        {activeWalletTab ==
                          WALLET_TAB?.MAINTAB.CASHOUT_HISTORY && (
                          <CashoutHistory />
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Wallets;
