import React from "react";
import Select from "react-select";
import CommonBtn from "../../../../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../../../../Common/Ui/CommonTable/CommonTable";
import CustomPagination from "../../../../../Common/Ui/Pagination/CustomPagination";
import "./CashoutIndex.scss";
import { formatDate, getTime } from "../../../../../../Helper/common";
import { DECIMAL } from "../../../../../../Constant/userConstant";

const Completed = ({
  cashoutHistory,
  page,
  setPage,
  limit,
  setLimit,
  limitOption,
  setViewDetail,
  viewDetail,
  setShowViewModal,
}) => {
  return (
    <>
      <div className="inprocess">
        <div className="table_section">
          <CommonTable className="artowned_table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Transaction ID</th>
                <th>UTR/Ref no.</th>
                <th className="btnthead text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {cashoutHistory?.list?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="dateTime">
                      <p>{getTime(item.created_at)}</p>
                      <h6>{formatDate(item.created_at)}</h6>
                    </div>
                  </td>
                  <td>₹ {Number(item.amount) / DECIMAL}</td>
                  <td>{item.transaction_id ? item.transaction_id : "--"}</td>
                  <td>{item.utr_id}</td>
                  <td>
                    <CommonBtn
                      role="btn"
                      title="View Details"
                      className="btn_size bordred-black"
                      onClick={() => {
                        setViewDetail(item);
                        setShowViewModal(true);
                      }}
                    />
                  </td>
                </tr>
              ))}
              {cashoutHistory?.list?.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <p className="no_recordtable">No records found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </CommonTable>
        </div>
        <div className="filterPagination">
          {cashoutHistory?.totalItems > 10 && (
            <div className="selectShow">
              <label>Show</label>
              <div className="selectShow_inner">
                <Select
                  classNamePrefix="react-select"
                  className="react-select"
                  options={limitOption}
                  isSearchable={false}
                  onChange={(option) => {
                    setLimit(option);
                    setPage(1);
                  }}
                  value={limit}
                />
              </div>
            </div>
          )}
          {cashoutHistory.totalPages > 1 && (
            <CustomPagination
              className="ms-auto"
              activePage={page}
              totalPage={cashoutHistory.totalPages}
              setActivePage={setPage}
              maxVisiblePages={5}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Completed;
