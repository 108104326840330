import React from "react";
import CommonBtn from "../../../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../../../Common/Ui/CommonModal/CommonModal";
import "./CashoutIndex.scss";

const CancelCashoutmodal = ({
  show,
  handleClose,
  cancelCashoutRequestHandler,
}) => {
  return (
    <>
      <CommonModal
        centered
        className="cancel_cashoutmodal"
        show={show}
        onHide={handleClose}
      >
        <div className="main_content">
          <h5>Are you sure you want to cancel the request?</h5>
          <div className="btns_section">
            <CommonBtn
              title="Yes"
              role="btn"
              className="btn-yellow-black btn_sizeing"
              onClick={cancelCashoutRequestHandler}
            />
            <CommonBtn
              title="No"
              role="btn"
              className="bordred-white btn_sizeing"
              onClick={handleClose}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default CancelCashoutmodal;
