import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./CashOutModal.scss";
import CommonModal from "../../../../Common/Ui/CommonModal/CommonModal";
import FormikControls from "../../../../Common/Ui/Formik/FormikControls";
import { Col, Row } from "react-bootstrap";
import { DECIMAL } from "../../../../../Constant/userConstant";

const ViewCashOutModal = ({ show, handleClose, viewDetail }) => {
  // Validation Schema
  // const CashoutSchema = Yup.object({
  //   funds: Yup.string()
  //     .required("*Amount is required")
  //     .test(
  //       "is-greater-than-zero",
  //       "*Amount must be greater than 0",
  //       (value) => Number(value) > 0
  //     )
  //     .test(
  //       "is-less-than-or-equal-to-walletBalance",
  //       `*Amount must be less than or equal to the wallet balance of ${getNumberWithComma(
  //         (Number(walletBalance) / DECIMAL).toFixed(2)
  //       )}`,
  //       (value) => Number(value) <= Number(walletBalance / DECIMAL)
  //     ),
  //   accountHolderName: Yup.string()
  //     .required("*Account holder name is required")
  //     .matches(/^[a-zA-Z ]+$/, "*Only letters are allowed"),
  //   accountNo: Yup.string()
  //     .required("*Account number is required")
  //     .matches(/^[a-zA-Z0-9]*$/, "*Invalid Account Number"),
  //   confirmAccountNo: Yup.string()
  //     .required("*Please confirm your account number")
  //     .oneOf([Yup.ref("accountNo")], "*Account Number must match"),
  //   ifscCode: Yup.string()
  //     .required("*IFSC code is required")
  //     .matches(/^[a-zA-Z0-9]*$/, "*Invalid IFSC code"),
  //   bankName: Yup.string().required("*Bank name is required"),
  // });

  const formik = useFormik({
    // validationSchema: CashoutSchema,
    initialValues: {
      funds: viewDetail?.amount ? viewDetail?.amount / DECIMAL : "",
      accountHolderName: viewDetail?.account_holder_name
        ? viewDetail?.account_holder_name
        : "",
      accountNo: viewDetail?.account_no ? viewDetail?.account_no : "",
      confirmAccountNo: viewDetail?.account_no ? viewDetail?.account_no : "",
      ifscCode: viewDetail?.ifsc ? viewDetail?.ifsc : "",
      bankName: viewDetail?.bank_name ? viewDetail?.bank_name : "",
    },
    enableReinitialize: true,
  });

  return (
    <>
      <CommonModal
        centered
        show={show}
        onHide={() => {
          formik.resetForm();
          handleClose();
        }}
        className="cashout-Modal"
        modalTitle="Cash Out"
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="funds_add">
            <Row>
              <Col lg={6}>
                <div className="fund_inputs">
                  <FormikControls
                    label="Enter amount"
                    control="input"
                    type="text"
                    name="funds"
                    id="funds"
                    placeholder="Enter amount"
                    variant="fund_input"
                    leftIcon="₹ "
                    onBlur={formik.handleBlur}
                    value={formik.values.funds}
                    // onChange={(e) => {
                    //   const value = e.target.value;
                    //   const regex = /^[0-9]*\.?[0-9]{0,2}$/; // Allows only numbers and up to two decimal places
                    //   if (regex.test(value)) {
                    //     formik.setFieldValue("funds", value);
                    //   }
                    // }}
                    formik={formik}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="bankDetails">
            <h3>Enter Bank Details</h3>
            <Row>
              <Col lg={6}>
                <div className="fund_inputs">
                  <FormikControls
                    label="Account Holder Name"
                    control="input"
                    type="text"
                    name="accountHolderName"
                    id="accountHolderName"
                    placeholder="Enter Name"
                    variant="fund_input"
                    onBlur={formik.handleBlur}
                    value={formik.values.accountHolderName}
                    // onChange={formik.handleChange}
                    formik={formik}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="fund_inputs">
                  <FormikControls
                    label="Account No."
                    control="input"
                    type="text"
                    name="accountNo"
                    id="accountNo"
                    placeholder="Enter Account No."
                    variant="fund_input"
                    onBlur={formik.handleBlur}
                    value={formik.values.accountNo}
                    // onChange={formik.handleChange}
                    formik={formik}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="fund_inputs">
                  <FormikControls
                    label="Confirm Account No."
                    control="input"
                    type="text"
                    name="confirmAccountNo"
                    id="confirmAccountNo"
                    placeholder="Confirm Account No."
                    variant="fund_input"
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmAccountNo}
                    // onChange={formik.handleChange}
                    formik={formik}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="fund_inputs">
                  <FormikControls
                    label="IFSC Code"
                    control="input"
                    type="text"
                    name="ifscCode"
                    id="ifscCode"
                    placeholder="Enter IFSC Code"
                    variant="fund_input"
                    onBlur={formik.handleBlur}
                    value={formik.values.ifscCode}
                    // onChange={formik.handleChange}
                    formik={formik}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="fund_inputs">
                  <FormikControls
                    label="Bank Name"
                    control="input"
                    type="text"
                    name="bankName"
                    id="bankName"
                    placeholder="Enter Bank Name"
                    variant="fund_input"
                    onBlur={formik.handleBlur}
                    value={formik.values.bankName}
                    // onChange={formik.handleChange}
                    formik={formik}
                  />
                </div>
              </Col>
            </Row>
            {/* <div className="noteFiled">
              <p>
                <span>Note:-</span> Incorrect information may cause loss of
                funds.
              </p>
            </div>
            <CommonBtn
              role="btn"
              className="btnsubmit"
              title="Submit"
              type="submit"
            /> */}
          </div>
        </form>
      </CommonModal>
    </>
  );
};

export default ViewCashOutModal;
