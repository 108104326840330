import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import style from "./Devicesadd.module.scss";
import Activatedevicemodal from "./Activatedevicemodal";
import { activateDevice } from "../../../../Features/linkedDevice/linkedDeviceSlice";
import { useDispatch } from "react-redux";
import { inputFieldValidation } from "../../../../Constant/formikConstant";

const Devicesadd = ({ tab }) => {
  const [showModal, setShowModal] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const dispatch = useDispatch();
  const initialValue = {
    codedevice: "",
  };

  useEffect(() => {
    if (tab === "linkdevices") {
      formik.resetForm();
    }
  }, [tab]);

  const validationSchema = Yup.object({
    codedevice: Yup.string()
      // .matches(/^\S+$/, "*Space is not allowed")
      // .matches(
      //   /^[a-zA-Z0-9\s]*$/,
      //   "*Only alphanumeric characters and spaces are allowed"
      // )
      .min(
        inputFieldValidation.linkeddevice.minLength,
        `*Must be of ${inputFieldValidation.linkeddevice.minLength} digits`
      )
      .max(
        inputFieldValidation.linkeddevice.maxLength,
        `*Must be of ${inputFieldValidation.linkeddevice.minLength} digits`
      )
      .required(inputFieldValidation.commonMessage.required("Code")),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      activateDeviceHandler(values);
    },
  });

  const activateDeviceHandler = async (values) => {
    try {
      let data = {
        code: values.codedevice,
      };

      const response = await dispatch(activateDevice(data));
      setDeviceId(response.data.data.device_id);
      setShowModal(true);
    } catch (error) {
      setShowModal(false);
    }
  };

  return (
    <>
    <section className={style.devicesadd}>
      <Tabs
        id="uncontrolled-tab-example"
        defaultActiveKey="device"
        className="tablists"
      >
        <Tab eventKey="device" title="Device">
            <div className={style.bitlimitset}>
              <label>Enter code to activate device</label>
              <form onSubmit={formik.handleSubmit}>
                <FormikControls
                  variant="device_add"
                  control="input"
                  type="input"
                  name="codedevice"
                  id="codedevice"
                  placeholder="Enter code"
                  formik={formik}
                  onChange={(e) => {
                    if (/^[a-zA-Z0-9\s]*$/.test(e.target.value)) {
                      formik.setFieldValue("codedevice", e.target.value);
                    }
                  }}
                  value={formik.values.codedevice}
                  onBlur={formik.handleBlur}
                  maxLength={6}
                />
                <CommonBtn
                  title="Activate Device"
                  role="btn"
                  type="sumbit"
                  className={`bordred-black ${style.btn_aactive}`}
                />
              </form>
            </div>
          </Tab>
        </Tabs>
      </section>
      {deviceId && showModal && (
        <Activatedevicemodal
          show={showModal}
          handleClose={() => setShowModal(false)}
          setShowModal={setShowModal}
          deviceId={deviceId}
          formik={formik}
        />
      )}
    </>
  );
};

export default Devicesadd;
