import React, { useState } from "react";
import Select from "react-select";
import CommonBtn from "../../../../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../../../../Common/Ui/CommonTable/CommonTable";
import CustomPagination from "../../../../../Common/Ui/Pagination/CustomPagination";
import "./CashoutIndex.scss";
import RejectModal from "./RejectModal";
import { formatDate, getTime } from "../../../../../../Helper/common";
import { DECIMAL } from "../../../../../../Constant/userConstant";

const CancelledReject = ({
  cashoutHistory,
  page,
  setPage,
  limit,
  setLimit,
  limitOption,
  setViewDetail,
  viewDetail,
}) => {
  const [showReject, setShowReject] = useState(false);
  const getStatus = (item) => {
    if (item.status === "CANCELLED") {
      return "Cancelled";
    } else if (
      item.status === "REJECTED" &&
      item?.rejection_type === "WITHDRAW_REQ_REJECT"
    ) {
      return "Withdraw Request Rejected";
    } else if (
      item.status === "REJECTED" &&
      item?.rejection_type === "CANCEL_REQ_REJECT"
    ) {
      return "Cancellation Request Rejected";
    }
  };

  return (
    <>
      <div className="inprocess">
        <div className="table_section">
          <CommonTable className="artowned_table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Transaction ID</th>
                <th>Status</th>
                <th className="btnthead text-center">Reason</th>
              </tr>
            </thead>
            <tbody>
              {cashoutHistory?.list?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="dateTime">
                      <p>{getTime(item.created_at)}</p>
                      <h6>{formatDate(item.created_at)}</h6>
                    </div>
                  </td>
                  <td>₹ {Number(item.amount) / DECIMAL}</td>
                  <td>{item.transaction_id ? item.transaction_id : "--"}</td>
                  <td>{getStatus(item)}</td>
                  <td>
                    {item?.status !== "CANCELLED" && (
                      <CommonBtn
                        role="btn"
                        title="View Reason"
                        className="btn_size bordred-black"
                        onClick={() => {
                          setShowReject(true);
                          setViewDetail(item);
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
              {cashoutHistory?.list?.length == 0 && (
                <tr>
                  <td colSpan={5}>
                    <p className="no_recordtable">No records found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </CommonTable>
        </div>
        <div className="filterPagination">
          {cashoutHistory?.totalItems > 10 && (
            <div className="selectShow">
              <label>Show</label>
              <div className="selectShow_inner">
                <Select
                  classNamePrefix="react-select"
                  className="react-select"
                  options={limitOption}
                  isSearchable={false}
                  onChange={(option) => {
                    setLimit(option);
                    setPage(1);
                  }}
                  value={limit}
                />
              </div>
            </div>
          )}
          {cashoutHistory.totalPages > 1 && (
            <CustomPagination
              className="ms-auto"
              activePage={page}
              totalPage={cashoutHistory.totalPages}
              setActivePage={setPage}
              maxVisiblePages={5}
            />
          )}
        </div>
      </div>
      {showReject && (
        <RejectModal
          show={showReject}
          handleClose={() => setShowReject(false)}
          setViewDetail={setViewDetail}
          viewDetail={viewDetail}
        />
      )}
    </>
  );
};

export default CancelledReject;
