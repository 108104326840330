import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import style from "./ArtDetails.module.scss";
import {
  getDateFromTimestamp,
  getNumberWithComma,
} from "../../../../../Helper/common";
import {
  DECIMAL,
  DIGITAL_ART_TYPE,
  MARKET_TYPE,
} from "../../../../../Constant/userConstant";
import DOMPurify from "dompurify";

const ArtDetails = ({
  dfaDetail,
  dfaActivity,
  singleAndRankedHighestBidEnded,
}) => {
  const isLastSoldShow = () => {
    if (
      (dfaDetail?.drop_type === DIGITAL_ART_TYPE?.FirstComeFirstBuy ||
        dfaDetail?.drop_type === DIGITAL_ART_TYPE?.OpenEdition) &&
      dfaDetail?.sold_no_of_editions > 0
    ) {
      return true;
    } else if (
      (dfaDetail?.drop_type === DIGITAL_ART_TYPE?.Auction ||
        dfaDetail?.drop_type === DIGITAL_ART_TYPE?.RankedAuction) &&
      dfaDetail?.market_type === MARKET_TYPE?.SECONDARY
    ) {
      return true;
    } else if (
      dfaDetail?.drop_type === DIGITAL_ART_TYPE?.Raffle &&
      dfaDetail?.market_type === MARKET_TYPE?.SECONDARY
    ) {
      return true;
    } else {
      return false;
    }
  };

  const artType = {
    4: {
      labelinfo: "Open Edition: ",
      dropId: 4,
      subinfo:
        "Available in unlimited quantities for a specific timeframe allow collectors to purchase DFAs without any fear of DFAs getting sold out. The edition number is determined by the number of DFAs that are sold during this period. Collectors can buy available copies from the marketplace or give an offer to other collectors to acquire the artwork after the drop closes.",
    },
    3: {
      labelinfo: "First Come First Buy Limited Edition: ",
      dropId: 3,
      subinfo:
        "Limited in quantity editions of the DFAs are offered for purchase to collectors on a first-come, first-served basis. Once the edition is sold out, no more copies are available in the drop, adding exclusivity and urgency to the buying process. Collectors can buy available copies of the DFA from the marketplace or give an offer to other collectors to acquire the DFA after the drop closes.",
    },
    1: {
      labelinfo: "Auction: ",
      dropId: 1,
      subinfo: `
          DFAs with single edition sold through an auction with a set minimum price and bid increment. Collectors bid against each other to obtain
          the DFA and compete by placing increasingly higher bids until the auction concludes. The highest bidder at the end of the auction
          secures ownership of the artwork. The end timer resets to 5 minutes every time a bid is placed in the last 5 minutes of the Auction.
          Collectors can buy DFA from the marketplace, if it’s available or give an offer to other collectors to acquire the artwork after the auctionconcludes.
        `,
    },
    2: {
      labelinfo: "Ranked Auction: ",
      dropId: 2,
      subinfo:
        "DFAs with limited number of editions sold through a ranked auction with a set minimum price and bid increment. Collectors Bidders are guided on the minimum eligible bid to secure an artwork and become the highest bidder. The top bids at the end of the auction secures ownership of the artwork, for example if there are 10 editions of the DFA, the top 10 bids will secure the ownership of the DFA. The end timer resets to 5 minutes every time a bid is placed in the last 5 minutes of the Auction.",
    },
    5: {
      labelinfo: "Raffle: ",
      dropId: 5,
      subinfo:
        "Raffle drops offer collectors the chance to acquire the DFA through a randomized selection process at a fraction of the cost. Participants purchase tickets for a chance to own the DFA within a specified timeframe. Once the raffle concludes, winners are randomly selected from the pool of ticket holders, providing an element of luck in acquiring sought-after pieces.",
    },
    6: {
      labelinfo: "Drawing: ",

      subinfo:
        "Drawing drops involve a selection process where collectors submit their interest to acquire the DFA. Winners are then chosen through a random drawing, adding an element of excitement and fairness to the selection process.",
    },
  };
  return (
    <>
      <div className={style.artDetails}>
        <div className={style.artDetailsLeftside}>
          <ul className={style.artList}>
            {dfaDetail?.drop_type !== DIGITAL_ART_TYPE?.OpenEdition && (
              <li>
                <label>Edition </label>
                <p> : Edition of {dfaDetail?.no_of_editions}</p>
              </li>
            )}
            {dfaDetail?.drop_type === DIGITAL_ART_TYPE?.OpenEdition && (
              <li>
                <label>Edition </label>
                <p>
                  :&nbsp;
                  {dfaDetail?.sold_no_of_editions > 0
                    ? `Edition of ${dfaDetail?.sold_no_of_editions}`
                    : `Open Edition`}
                </p>
              </li>
            )}
            <li>
              <label>Type </label>
              <p> : {dfaDetail?.art_type}</p>
            </li>
            <li>
              <label>Release Date </label>
              <p> : {getDateFromTimestamp(Number(dfaDetail?.start_time))}</p>
            </li>
            {(dfaDetail?.drop_type === DIGITAL_ART_TYPE?.FirstComeFirstBuy ||
              dfaDetail?.drop_type === DIGITAL_ART_TYPE?.OpenEdition) && (
                <li>
                  <label>Release Price </label>
                  <p> : ₹ {getNumberWithComma(dfaDetail?.price / DECIMAL)}</p>
                </li>
              )}

            {(dfaDetail?.drop_type === DIGITAL_ART_TYPE?.Auction ||
              dfaDetail?.drop_type === DIGITAL_ART_TYPE?.RankedAuction) && (
                <>
                  <li>
                    <label>Floor Price </label>
                    <p>
                      : ₹ {getNumberWithComma(dfaDetail?.floor_price / DECIMAL)}
                    </p>
                  </li>
                  <li>
                    <label>Highest Bid </label>
                    <p>
                      :{" "}
                      {dfaDetail?.market_type === MARKET_TYPE?.SECONDARY &&
                        singleAndRankedHighestBidEnded !== 0
                        ? ` ₹ ${singleAndRankedHighestBidEnded}`
                        : dfaActivity?.user_activity?.length > 0 &&
                          dfaDetail?.market_type === MARKET_TYPE?.PRIMARY
                          ? `₹ ${getNumberWithComma(
                            dfaActivity?.user_activity[0]?.bid_amount / DECIMAL
                          )}`
                          : "--"}
                    </p>
                  </li>
                </>
              )}
            {(dfaDetail?.drop_type === DIGITAL_ART_TYPE?.Auction ||
              dfaDetail?.drop_type === DIGITAL_ART_TYPE?.RankedAuction) &&
              dfaDetail?.market_type === MARKET_TYPE?.PRIMARY && (
                <li>
                  <label>Min. Increment </label>
                  <p>
                    {" "}
                    : ₹ {getNumberWithComma(dfaDetail?.increment / DECIMAL)}
                  </p>
                </li>
              )}
            {isLastSoldShow() && (
              <li>
                <label>Last Sold Price </label>
                <p>
                  : ₹ {getNumberWithComma(dfaDetail?.last_sell_price / DECIMAL)}
                </p>
              </li>
            )}
            {dfaDetail?.drop_type === DIGITAL_ART_TYPE?.Raffle && (
              <>
                <li>
                  <label>Estimated Price </label>
                  <p>
                    : ₹{" "}
                    {getNumberWithComma(dfaDetail?.estimated_price / DECIMAL)}
                  </p>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className={style.artDetailsRightside}>
          <Tabs
            defaultActiveKey="aboutart"
            id=""
            className={`tablists ${style.art_tabcont}`}
          >
            <Tab eventKey="aboutart" title="About Art">
              <div
                className={style.tabArt}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(dfaDetail?.bio),
                }}
              ></div>
            </Tab>
            <Tab eventKey="aboutartist" title="About Artist">
              <div
                className={style.tabArt}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(dfaDetail?.artist_bio),
                }}
              ></div>
            </Tab>
            <Tab eventKey="howbuy" title="How to Buy">
              <div className={style.tabArt}>
                <p>{artType[dfaDetail.drop_type]?.subinfo}</p>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ArtDetails;
