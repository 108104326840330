import React, { useEffect, useState } from "react";
import style from "./Activedigitalart.module.scss";
import { Col, Row } from "react-bootstrap";
import CommonCard from "../../../Common/Ui/CommonCard/CommonCard";
import { useDispatch, useSelector } from "react-redux";
import { getArts } from "../../../../Features/drop/dropSlice";
import {
  COMPARISION_CONSTANT_NAMES,
  DECIMAL,
  PER_PAGE_RECORD_LIMIT,
} from "../../../../Constant/userConstant";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";

import { useDebounce } from "../../../../customHooks/useDebounce";
import Notfound from "../../../Common/NotFound/Notfound";
import { checkFileType, getNumberWithComma } from "../../../../Helper/common";
import { useNavigate } from "react-router-dom";
import HoverVideoPlayer from "react-hover-video-player";
import LoadingOverlay from "../../../VideoGif/LoadingOverlay";
import Select from "react-select";
import styles from "../../Drop/drop.module.scss";
import { setPageOtherUserProfile } from "../../../../Features/user/userSlice";

const ArtistDigitalArtOtherProfile = (props) => {
  let { setLimit, limit, totalItems, limitOption, setTotalItems } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.users.profileDataOther);
  // const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [art, setArt] = useState([]);
  const debouncedSearchTerm = useDebounce(props.search, 500);

  const pageOtherUserProfile = useSelector(
    (state) => state.users.pageOtherUserProfile
  );

  useEffect(() => {
    if (
      debouncedSearchTerm.length == COMPARISION_CONSTANT_NAMES.ZERO ||
      debouncedSearchTerm.length >= COMPARISION_CONSTANT_NAMES.THREE
    ) {
      getUserCreatedArts();
    }
  }, [props.id, pageOtherUserProfile, props.debouncedSearchTerm, limit]);

  const getUserCreatedArts = async () => {
    try {
      let params = {
        id: props.id,
        size: limit.value,
        page: props.search !== "" ? 1 : pageOtherUserProfile,
        search: debouncedSearchTerm.trim(),
      };

      if (debouncedSearchTerm.length < 3) {
        delete params.search;
      }

      const response = await dispatch(getArts(params));
      setArt(response.data.data.arts);
      setTotalPages(response.data.data.totalPages);
      setTotalItems(response?.data?.data?.totalItems);
    } catch (err) { }
  };

  const redirectToSecArtDetail = (item) => {
    navigate(`/digital-fine-arts/${item.id}`);
  };

  const getImageSection = (item) => {
    let type = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    if (type == "MP4" || type == "OGG") {
      return (
        <HoverVideoPlayer
          loop={true}
          restartOnPaused
          unloadVideoOnPaused={true}
          disableRemotePlayback={false}
          paused={true}
          muted={false}
          onClick={() => redirectToSecArtDetail(item)}
          className={`video_hover_sec ${styles.image_sec}`}
          videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          pausedOverlay={
            <CommonCard
              type={"video"}
              title={item.art_name}
              subtitle={`@${profileData?.username}`}
              bottomTitle={`Price: ₹ ${getNumberWithComma(
                Number(item.price) / DECIMAL
              )}`}
              bottomSubTitle={
                item.drop_type != 4
                  ? `${item.no_of_editions - item.sold_no_of_editions} / ${item.no_of_editions
                  }`
                  : ""
              }
              item={{ ...item, artist_id: profileData?.id }}
              imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
            />
          }
          loadingOverlay={<LoadingOverlay />}
        />
      );
    } else {
      return (
        <CommonCard
          imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
          featureUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          title={item.art_name}
          subtitle={`@${profileData?.username}`}
          bottomTitle={`Price: ₹ ${getNumberWithComma(
            Number(item.price) / DECIMAL
          )}`}
          bottomSubTitle={
            item.drop_type != 4
              ? `${item.no_of_editions - item.sold_no_of_editions} / ${item.no_of_editions
              }`
              : ""
          }
          item={{ ...item, artist_id: profileData?.id }}
          onClick={() => redirectToSecArtDetail(item)}
        />
      );
    }
  };

  return (
    <>
      <section className={style.active_digitalart}>
        {/* <div className={style.searchbar}>
          <>
            <div className="search_input">
              <div className="search_input_inner">
                <button type="submit" className="search_input_icon">
                  <SearchIcon />
                </button>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Art"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </>
        </div> */}
        <div className={`${style.card_colloection} ${style.card_colloectionOther}`}>
          <div className={style.cardRow}>
            {art.map((item, index) => (
              <div className={style.cardBox} key={index}>
                {getImageSection(item)}
                {/* <CommonCard
                  key={index}
                  imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.art_image}`}
                  title={item.art_name}
                  subtitle={`@${profileData?.username}`}
                  bottomTitle={`Price: ₹${getNumberWithComma(
                    Number(item.price) / DECIMAL
                  )}`}
                  bottomSubTitle={
                    item.drop_type != 4
                      ? `${item.no_of_editions - item.sold_no_of_editions} / ${
                          item.no_of_editions
                        }`
                      : ""
                  }
                  item={{ ...item, artist_id: profileData?.id }}
                  onClick={() => redirectToSecArtDetail(item)}
                /> */}
              </div>
            ))}
          </div>
          {art.length == 0 && (
            <Col xs={12}>
              <Notfound />
            </Col>
          )}
        </div>
        {/* {art.length == 0 && (
          <tr>
            <td colSpan={6}>
              <p className="no_recordtable">No records found</p>
            </td>
          </tr>
        )} */}
        <div className="filterPagination">
          {totalItems > 10 && (
            <div className="selectShow">
              <label>Show</label>
              <div className="selectShow_inner">
                <Select
                  classNamePrefix="react-select"
                  className="react-select"
                  options={limitOption}
                  isSearchable={false}
                  onChange={(option) => {
                    setLimit(option);
                    dispatch(setPageOtherUserProfile(1));
                  }}
                  value={limit}
                />
              </div>
            </div>
          )}
          {totalPages > 1 && (
            <CustomPagination
              // className="ms-auto"
              activePage={pageOtherUserProfile}
              totalPage={totalPages}
              // setActivePage={setPage}
              dispatch={dispatch}
              maxVisiblePages={5}
              componentTypes={"OtherUserProfile"}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default ArtistDigitalArtOtherProfile;
